#eav_canvas {
    height: var(--fullscreenheight)
}

#eav_auditbtn {
    width: 180px;
    height: 40px;
    background: linear-gradient(40deg, rgb(103, 8, 6) 40%, rgb(91, 34, 114) 80%);
    color: white;
    border: 2px solid blue;
    border-radius: 15px;
    font-size: 19px;
    letter-spacing: .1em;
}

#eav_main {
    display: grid;
    grid-template-columns: 50% 50%;
    column-gap: 20px;
    grid-template-rows: 800px;
}

.eav_auditrow {
    display: flex;
}

.eav_audititm {
    overflow: hidden;
}