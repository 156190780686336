#schemacanvas {
    background-color: black;
    min-height: var(--fullscreenheight);
    min-height: 900px;
    width: 100%;
    display: grid;
    grid-template-columns: 15% 35% 35% 15%;
    grid-column-gap: 10px;
    grid-template-rows: 50px 150px 175px 600px;
    z-index: -2;
    font-family: Arial, Helvetica, sans-serif;
    height: 1000px;
}

#schema_header {
    grid-column: 2/4;
    grid-row: 1/1;
    font-size: 24px;
    color: wheat;
    margin: auto;
}

#schema_newbox {
    display: flex;
    grid-column: 2/2;
    grid-row: 2/2;
}

#schema_inputtxt {
    height: 40px;
}

#schema_infocountbox {
    grid-column: 3/3;
    grid-row: 3/3;
}

#schema_selectbox {
    grid-column: 3/3;
    grid-row: 2/2;
}

#schema_ddown {
    height: 40px;
}

#schema_btnsel {
    height: 40px;
}

.schematool {
    border-radius: 7px 3px 7px 3px;
    padding: 2px;
    background-color: rgb(233, 223, 208)
}

.schemabutton {
    height: 40px;
    background-color: rgb(190, 156, 105);
    border-radius: 3px 7px 3px 7px;
    overflow: hidden;
}


#schema_modeldetailsheader {
    color: white;
    font-size: 20px;
}

#schema_modeldetails {
    grid-column: 3/4;
    grid-row: 4/4;
}

#schema_modeldetailbox {
    padding: 5px;
    border: 1px solid rgb(40, 33, 7);
    overflow-y: scroll;
    height: 260px;
}

.schema_modelentry {
    color: white;
    padding: 3px;
    border-radius: 3px;
    margin: 2px;
}

.schema_modelentry:hover {
    transition: .2s ease-out;
    transition-delay: .08s;
    border: 1px solid blue;
    scale: 1.01;
    box-shadow:
        1px 1px 3px rgb(117, 209, 206),
        -1px -1px 3px rgb(117, 209, 206),
        1px 1px 3px rgb(117, 209, 206),
        -1px -1px 3px rgb(117, 209, 206);
}

.schema_wsitem {
    width: 250px;
    border: 1px solid white;
    height: 35px;
    color: white;
    border-radius: 10px;
    padding: 4px;
    cursor: pointer;
}

.schema_wsitem:hover {
    background-color: rgb(2, 2, 128);
    border: 2px solid rgb(117, 209, 206);
}

.schmgr_btn {
    font-size: 16px;
    height: 70px;
    width: 130px;
    overflow: hidden;
}