
.datatbl_header{
    border:2px solid black;
}


#datatbl{
    border:2px solid black;  
}


.datatbl_td {
    border: 1px solid black;
}


#datatbl_pagination{
    display:flex;
    padding: 2px;
}

.datatbl_paginatebtn{
    margin: 5px;
}

#datatbl_paginatePg{
    margin: 5px;
}