#empdatacontainer1 {
    display: grid;
    grid-template-columns: 400px 70%;
    grid-template-rows: 700px 400px;
    padding-top: 20px;
    grid-column-gap: 30px;
    grid-row-gap: 10px;
    background: rgb(31, 8, 47);
    background: linear-gradient(27deg, rgba(31, 8, 47, 1) 0%, rgba(0, 0, 0, 1) 70%, rgba(166, 57, 97, 0.833) 100%);
}

#empimportbar {
    grid-column: 1/2;
    grid-row: 1/2;
    margin: 10px 0px 0px 10px;
    background: linear-gradient(-45deg, #20041d, #260955 90%);
    border-radius: 5px;
    overflow: hidden;
    height: 700px;
    box-shadow:
        7px 6px 10px #263c52;
}

#empimportbar_container {
    display: grid;
    grid-template-columns: 50px;
    grid-template-rows: 150px 100px 200px;
}

#empselector {
    background-color: rgb(41, 16, 40);
    grid-column: 2/2;
}

#fieldselector {
    grid-column: 2/2;
}

#empimportbar_header {
    font-size: 18px;
    color: white;
    margin-left: 60px;
}

.empradiobtn {
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.795);
    width: 20px;
    height: 20px;
    transition: .5s ease-in-out;
}

.borderplus {
    border-radius: 12px;
    background: linear-gradient(to right, rgb(214, 233, 47), rgb(204, 77, 221));
    width: 26px;
    height: 26px;
    padding-top: 3px;
    padding-left: 3px;
    margin: auto;
    cursor: pointer;
}

#radio_field {
    grid-row: 2/2;
    grid-column: 1/1;
}

#radio_field2 {
    grid-row: 3/3;
    grid-column: 1/1;
}

.empimportelem {
    margin: 10px 10px 10px 10px;
    border-radius: 5px;
    padding: 10px;
    box-shadow:
        inset 4px 4px 8px #4d3851,
        inset -1px -2px 12px #611d69;
    text-align: center;
    transition: .5s;
}


.empheader {
    font-family: Cambria;
    text-align: center;
    font-size: 20px;
    color: white
}


.empSpace {
    grid-column: 2/2;
    grid-row: 1/2;

    margin: 10px;
    height: 800px;

}

.empsel_ddown {
    width: 250px
}