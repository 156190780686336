
.lo_cascadecontainer {
	perspective: 5px;
	z-index: -1;
	height: 0px;
}

.lo_frame {
	top: 100px;
	right: 150px;
	width: 0px;
	height: 0px;
	position: fixed;
	border-radius: 80px;
	border-top: 200px solid transparent;
	border-left: 200px solid transparent;
	box-shadow:
		0px 0px 15px #ce3fcf,
		inset 0px 0px 15px #f4f212,
		0px 0px 50px #f4f212,
		inset 0px 0px 50px #f4f212;
}

.lo_frame:nth-child(1) {
	transform: rotate(20deg) translateY(0px) translateZ(0px);
	opacity: 1.00;
	animation-delay: .1s
}
.lo_frame:nth-child(2) {
	transform: rotate(40deg) translateY(20px) translateZ(-5px);
	opacity: 1.00;
	animation-delay: .3s
}
.lo_frame:nth-child(3) {
	transform: rotate(60deg) translateY(40px) translateZ(-10px);
	opacity: 1.00;
	animation-delay: .5s
}