.empdet_histitm {
    background-color: rgb(54, 30, 62);
    margin-top: 5px;
    padding: 2px;
    border-radius: 5px;
    border: 1px solid rgb(79, 79, 79);
}

.empdet_histitm:hover {
    background-color: rgb(104, 41, 125);
    box-shadow: 0px 0px 5px blue;
}

.empdet_currcatitm,
.empdet_simjobitm {
    padding: 1px;
}

.empdet_currcatitm:hover {
    background-color: rgb(104, 41, 125);
}

.empdet_simjobitm:hover {
    background-color: rgb(104, 41, 125);
}

.empdet_skillitm {
    border: 1px solid grey;
    margin: 2px;
    padding: 5px;
    height: 60px;
    overflow: hidden;
    background-color: rgb(29, 7, 49);
    font-size: 14px;
}

.empdet_card {
    background-color: rgb(31, 26, 55);
    padding: 6px;
}