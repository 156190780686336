.sts_itm {
    display: flex;
    width: 250px;
    border: 1px solid grey;
    border-radius: 5px;
    height: 36px;
    padding: 6px;
    margin: 4px;
    cursor: pointer;
}

.sts_itm:hover {
    transform: translate(3px, 1px);
}

.stschk_mdlitm {
    font-size: 14px;
    margin: 2px;
    padding: 2px;
    border: 1px solid grey;
    border-radius: 5px;
    background-color: rgb(30, 18, 18);
}

.sts_statusbar {
    width: 100%;
    background: linear-gradient(90deg, rgb(139, 160, 185) 10%, rgb(156, 217, 221) 100%);
    border: 1px solid white;
    border-radius: 25px;
    overflow: hidden;
    height: 30px;
    margin-top: 5px;
}

#sts_progressbar {
    height: 100%;
    background: rgb(40, 40, 195);
    border-radius: 25px;
    transition: width 1.3s ease;
}

.sts_activeitm {
    animation: stsflash 2s infinite;
}

@keyframes stsflash {
    0% {
        background-color: rgb(30, 18, 18);
        box-shadow: 0px 0px 5px white;
    }

    50% {
        background-color: rgb(94, 94, 94);
    }

    100% {
        background-color: rgb(30, 18, 18);
        box-shadow: 0px 0px 5px white;
    }
}