#jdng_main {
    display: grid;
    grid-template-columns: 900px 50%;
}

.jdng_lkitm {
    display: grid;
    grid-template-columns: 25% 15% 60%;
    border: 1px solid black;
    border-radius: 4px;
    margin: 4px;
    padding: 3px;
    background-color: rgb(216, 236, 234);
}

.jdng_lksubitm {
    padding-left: 10px;
    border-left: 1px solid grey;
    min-width: 120px;
}

.jdng_lkitm_cat {
    margin-left: 5px;
}

.jdng_lkitm:hover {
    background-color: aqua;
}

#jdng_legend {
    display: grid;
    grid-template-columns: 30% 30% 30%;
    grid-column-gap: 15px;
    grid-template-rows: repeat(30px);
    margin-top: 5px;
}

.jdng_skillitm {
    border: 1px solid grey;
    border-radius: 5px;
    padding: 3px;
    margin: 2px;
    display: flex;
}


.jdng_pathsubitm {
    margin-left: 5px;
}


.jdng_mdlcat {
    background-color: #152946;
    border: 1px solid grey;
    padding: 3px;
}