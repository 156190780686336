.logoutContainer {
	display: grid;
	grid-template-columns: 20% 50% 20%;
	grid-column-gap: 20px;
	grid-template-rows: 800px;
	background: linear-gradient(to top, #262d64, #FFFFFF);
	font-family: Helvetica;
	height: var(--fullscreenheight)
}


.logout-button {
	margin: 3px;
	border-radius: 20px;
	border: none;
	background-color: #8770e9;
	color: #FFFFFF;
	font-size: 12px;
	font-weight: bold;
	padding: 12px 45px;
	letter-spacing: 1px;
	text-transform: uppercase;
}

#logoutbox {
	grid-column: 2/3;
	grid-row: 1/1;
	margin-top: 200px;
	margin-bottom: 200px;
	background: linear-gradient(-25deg, #110b1a 0%, #34409a 100%);
	color: white;
	overflow: hidden;
	border: 1px solid rgb(146, 146, 146);
	border-radius: 10px;
	box-shadow: inset 0px 0px 30px rgb(149, 144, 160), 10px 20px 20px rgba(0, 0, 0, 0.25),
		0 6px 15px rgba(0, 0, 0, 0.22);
	text-align: center;
	z-index: 1;
}