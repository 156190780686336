:root {
    --fullscreenheight: 95vh;

    --colorprimary: rgb(19, 9, 36);
    --colorsecondary: rgb(100, 215, 225);
    --coloraltprimary: rgb(36, 28, 47);
    --coloraltprimarysub: rgb(22, 13, 29);
    --colorhighlight: rgb(252, 252, 166);
    --coloroffwhite: rgb(236, 249, 254);
    --colorprimarybright: rgb(87, 42, 170);
    --colorfade1: rgba(2, 0, 36, .7);
    --colorfade2: rgba(69, 69, 123, 0.292);
    --coloraccent: rgba(4, 255, 41, 1)
}



.react-date-picker__calendar--open {
    position: absolute;
}

.react-date-picker__calendar--closed {
    position: absolute;
}