#tblEnum1 {
    padding: 5px;
}

.rowform_group {
    display: flex;
    margin: 3px;
    background-color: var(--coloroffwhite);
    border: 1px solid black;
    padding-left: 4px;
}

.rowform_name {
    width: 200px;
}

.selRowFormElement {
    width: 275px;
}

.submodelsel {
    width: 200px;
}

.enum_typeddown {
    margin-left: 10px;
    min-width: 120px;
}

#enum_rmvbtn {
    width: 50%;
    margin-left: 20%;
    margin-top: 10px;
    background-color: rgb(242, 251, 225);
}

#enumchange_div {
    height: 30px;
}