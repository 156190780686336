#tmgr_canvas {
    display: grid;
    grid-template-columns: 300px 80%;
    grid-template-rows: 270px 650px;
    row-gap: 20px;
    column-gap: 20px;
}

#tmgr_sidebar {
    grid-row: 1/3;
    grid-column: 1/1;
}


#tmgr_tableddown {
    font-size: 20px;
    width: 250px;
    border: 1px solid blue;
    border-radius: 4px;
    padding: 2px;
}

.dt_head {
    min-width: 160px;
}

.dt_head {
    min-width: 950px;
}

.dt_field {
    min-width: 950px;
}

.dt_fielditem {
    min-width: 160px;
}

#tmgr_hdrbtn {
    width: 200px;
    margin-top: 5px;
}

.tmgr_uplitm {
    width: 200px;
    border: 1px solid #959595;
    padding: 4px;
    border-radius: 2px;
    cursor: pointer;
}

.tmgr_sidebtn {
    height: 60px;
    color: rgb(254, 254, 192);
    margin-top: 15px;
    cursor: pointer;
    margin-left: 10px;
}

#tmgr_across {
    grid-column: 2/2;
}

#tmgr_uplhistgrid {
    display: grid;
    grid-template-columns: 30% 60%;
}

.tmgr_keyflditem {
    display: flex;
    width: 80%;
    min-width: 250px;
    border: 1px solid rgb(126, 126, 126);
    margin: 3px;
    padding: 3px;
    background-color: rgb(19, 4, 34);
}