#livedashboard {
    display: flex;
    width: 100%;
    height: 100%;
    background: linear-gradient(140deg, #fcfcfc 0%, #f3f3f3 100%);
}

#dashheader {
    display: flex;
    width: 100%;
    height: 80px;
    background-color: #111111;
    color: white;
}

#dv_asofdate_ddown {
    margin-left: 15px;
    margin-top: 5px;
}

.livegraph_itm {
    display: block;
    position: absolute;
    padding: 5px;
}



.dashddownflt {
    width: 200px;
}

#dashsidebar {
    height: 100%;
    width: 10px;
    background-color: black;
    color: white;
    transition: .5s;
    overflow: hidden;
    display: flex;
}

.dash_sdbr_kpiitem {
    border: 1px solid white;
    border-radius: 5px;
    margin: 4px;
    padding: 3px;
    padding-left: 5px;
    cursor: pointer;
}

.dash_sdbr_kpiitem:hover {
    background-color: #646464;
}

.dsh_pg {
    height: 35px;
    width: 120px;
    overflow-x: hidden;
    color: white;
    background-color: grey;
    margin: 3px;
    border-radius: 5px;
}

.dv_modalwide {
    min-width: 800px;
}

.dv_sidebtn {
    border-radius: 5px;
    border: 1px solid grey;
    min-width: 100px;
}

#dvdivtbl>tr>td {
    padding: 2px;
}