.wrapper {
	display: grid;
	grid-template-columns: 15% 90% 5%;
	grid-column-gap: 20px;
	grid-template-rows: 35% 300px 300px;
	grid-row-gap: 10px;
	font-family: Helvetica;
}

#box_home {
	grid-column: 2/3;
	grid-row: 2/2;
	min-width: 700px;
	width: 60%;
	height: 300px;
	background-color: var(--colorprimary);
	color: white;
	border-radius: 10px;
	box-shadow:
		0px 50px 70px rgba(0, 0, 0, .3),
		0px 10px 10px rgba(0, 0, 0, .1);
	overflow: hidden;
	padding: 5px;
	cursor: pointer;
}

#box_otherside {
	grid-column: 2/3;
	grid-row: 2/2;
	min-width: 700px;
	width: 60%;
	height: 300px;
	background-color: var(--coloraltprimary);
	color: white;
	border-radius: 10px;
	box-shadow:
		0px 50px 70px rgba(0, 0, 0, .3),
		0px 10px 10px rgba(0, 0, 0, .1);
	overflow: hidden;
	padding: 5px;
	cursor: pointer;
}


.box-buffer {
	margin: 10px;
}

.home-tile:hover {
	-webkit-transform: scale(1.02);
	-ms-transform: scale(1.02);
	transform: scale(1.02);
	transition: .4s ease;
}

.reflection {
	grid-row: 3/3;
	grid-column: 2/3;
	min-width: 700px;
	width: 60%;
	background: linear-gradient(to bottom, var(--colorprimary), white 30%);
	height: 300px;
	border-radius: 10px;
	opacity: 70%;
	-webkit-transform: skew(40deg);
	-ms-transform: skew(40deg);
	transform: skew(40deg);
	margin-left: 125px;
}