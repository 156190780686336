.stlcollapseHighlight {
    animation: stlCycleFlash 3s linear infinite;
}

.stltoggleLabel {
    position: absolute;
    /* Position button outside the div */
    top: 50%;
    /* Center the button vertically */
    transform: translateY(-50%);
    background: rgb(35, 24, 120);
    color: white;
    border-radius: 16px 0px 0px 16px;
    padding: 15px 10px;
    cursor: pointer;
    font-family: Arial, sans-serif;
    font-size: 14px;
    text-align: center;
    line-height: 20px;

    border: 1px solid grey;

}

@keyframes stlCycleFlash {
    0% {
        background-color: rgb(255, 255, 255);
        box-shadow: 0px 0px 10px white;
    }

    50% {
        background-color: rgb(20, 20, 73);
    }

    100% {
        background-color: rgb(177, 177, 177);
        box-shadow: 0px 0px 10px white;
    }
}