#qm_modelviewcanvas {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 500px 70%;
    column-gap: 10px;
}

#qmvr_qryrdbox {
    height: 80%;
    min-height: 400px;
    width: 95%;
    overflow: hidden;
}

.qmvr_qryrunsel {
    margin: 5px;
    padding: 2px;
    padding-left: 5px;
    border-radius: 6px;
    border: 1px solid rgb(37, 47, 125);
    width: 90%;
    height: 30px;
    background-color: rgb(7, 3, 31);
    cursor: pointer;
}

.qmvr_qryrunsel:hover {
    background-color: rgb(20, 16, 67);
    box-shadow: 0px 0px 5px rgb(25, 199, 225);
}

#qmvr_leftbox {
    grid-column: 1/1;
}


#qmvr_qrydetailbox {
    height: 200px;
    width: 95%;
    overflow-x: hidden;
    overflow-y: scroll;
}

.qmvr_visitem {
    height: 30px;
    width: 40%;
    min-width: 120px;
    max-width: 400px;
    margin: 4px;
    padding: 4px;
    border-radius: 5px;
    border: 1px solid rgb(92, 88, 98);
    cursor: pointer;
    background-color: rgb(39, 14, 52);
}

.qmvr_visitem:hover {
    background-color: rgb(57, 19, 86);
    box-shadow: 0px 0px 7px rgb(138, 84, 223);
}

#qmvr_multibtn {
    font-size: 15px;
    height: 30px;
    margin-left: 10px;
}