#aim_selblockBorder {
    padding: 2px;
    background: linear-gradient(140deg, rgb(4, 255, 41) 60%, rgb(8, 165, 170) 90%);
    border-radius: 100px;
    animation: selboxglowPlusX 8s;
    animation-iteration-count: 15;
}

#aim_selblock {
    background: linear-gradient(140deg, rgb(42, 25, 52) 60%, rgb(71, 7, 65) 90%);
    height: 300px;
    width: 450px;
    padding: 50px;
    border-radius: 100px;
    animation: selboxglow 8s;
    animation-iteration-count: 15;
}

.aim_classasst {
    background-color: black;
    border: 1px solid grey;
    border-radius: 5px;
}

.aim_classuser {
    background-color: rgb(34, 8, 8);
    border: 1px solid grey;
    border-radius: 5px;
}

#aim_modelddown {
    width: 200px;
}

#aim_chatddown {
    margin: 4px;
}

#aim_modelheader {
    width: 900px;
    background: linear-gradient(40deg, rgb(20, 7, 31) 70%, rgb(0, 0, 0) 100%);
    border: 2px solid rgb(59, 59, 59);
    border-radius: 2px 5px 5px 2px;
    padding: 3px;
}

.aim_branchitm {
    width: 140px;
    height: 35px;
    border: 1px solid white;
    border-radius: 5px;
    padding-top: 4px;
    background-color: rgb(20, 12, 28);
    margin-left: 5px;
    cursor: pointer;
}


.aim_chat {
    border: 1px solid grey;
    border-radius: 5px;
    padding: 4px;

}

.aim_textuser {
    text-align: right;
    margin-top: 15px;
    background: linear-gradient(110deg, rgb(72, 72, 136, .9) 20%, rgb(12, 12, 36) 100%);
}

.aim_textresp {
    margin-top: 5px;
    background: linear-gradient(110deg, rgb(13, 6, 24) 50%, rgb(27, 17, 38) 100%);
}

@keyframes selboxglow {
    0% {
        border-radius: 100px;
        box-shadow: 0 0 5px rgba(76, 20, 97, 1);
    }

    50% {
        border-radius: 30px;
        box-shadow: 0 0 50px rgb(92, 12, 84);
    }

    75% {
        border-radius: 100px;
        box-shadow: 0 0 5px rgba(76, 20, 97, 1);
    }

    100% {
        border-radius: 100px;
        box-shadow: 0 0 5px rgba(76, 20, 97, 1);
    }
}

@keyframes selboxglowPlusX {
    0% {
        border-radius: 100px;
        background: linear-gradient(140deg, rgb(4, 255, 41) 60%, rgb(8, 165, 170) 90%);
    }

    50% {
        border-radius: 30px;
        background: linear-gradient(140deg, rgb(4, 255, 41) 60%, rgb(8, 165, 170) 90%);
    }

    75% {
        border-radius: 100px;
        background: linear-gradient(140deg, rgb(4, 255, 41) 60%, rgb(8, 165, 170) 90%);
    }

    100% {
        border-radius: 100px;
        background: linear-gradient(140deg, rgb(4, 255, 41) 60%, rgb(8, 165, 170) 90%);
    }
}