#ndm_mdl_main {
    display: grid;
    grid-template-columns: 50% 50%;
    height: 400px;
}

.ndm_mdlbox {
    overflow-y: scroll;
    font-size: 17px;
}

.ndm_attrflditm {
    border: 1px solid white;
    border-radius: 6px;
    padding: 3px;
    padding-left: 5px;
    margin: 3px;
    min-width: 200px;
    width: 30%;
    cursor: pointer;

}