.ndt_stlmaininput {
    position: absolute;
    right: 0;
    top: 350px;
}


#stlcollapsible-divI {
    width: 0px;
    height: 450px;
    transition: width 0.3s ease;
    overflow: hidden;
}

#collapsible-mid:checked~#stlcollapsible-divI {
    width: 550px;
}

.ndt_sideYborder {
    background: grey;
    background-size: 400% 400%;
    width: 100%;
    height: 450px;
    padding: 1px;
}

.ndt_sideYinner {
    overflow: hidden;
    background: linear-gradient(90deg, var(--colorprimary) 0%, black 100%);
    padding-left: 10px;
    padding-top: 7px;
    padding-right: 5px;
    width: 100%;
    height: 448px;

    /* Manually adjusted to account for border width */
}

.stlLabelI {
    left: -20px;
    height: 450px;
    border-radius: 166px 0px 0px 166px;
    padding: 15px 10px;
    width: 20px;
}