#modal_RuleSel{
    height: 400px;
    width: 300px;
    margin: auto;
}

#explorelink{
    width: 300px;
    background-color: rgb(102, 93, 117);
    height: 50px;
    padding: 10px;
    text-align: center;
    margin-top: 5px;
    font-size: 18px;
    font-family:serif;
    border-radius: 10px;
    color: white;
    text-decoration: none;
}
#explorelink:hover{
    transition: .45s;
    background-color: rgb(66, 69, 255);
}

#modalrule_option1{
    background-color: rgb(231, 225, 230);
    padding: 5px;
    margin-top: 10px;
    width: 300px;
    text-align: center;
}

#selectedrulemodel_div{
    height: 200px;
    background-color: rgb(191, 194, 206);
    padding: 5px;
    border-radius: 4px;
    margin-top: 3px;
    font-family: monospace;
}