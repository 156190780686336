.cascadecontainer {
	perspective: 150px;
	z-index: -1;
}

.frame {
	width: 70%;
	height: 900px;
	position: fixed;
	border-radius: 50px;
	border: 5px #6c7bed solid;
	box-shadow:
		0px 0px 15px #5bdada,
		inset 0px 0px 15px #5bdada,
		0px 0px 50px #5bdada,
		inset 0px 0px 50px #5bdada;
}

.frame:nth-child(1) {
	transform: rotate(10deg) translateY(0px) translateZ(0px);
	opacity: 1.00;
	animation-delay: .1s
}

.frame:nth-child(2) {
	transform: rotate(20deg) translateY(20px) translateZ(-5px);
	opacity: 1.00;
	animation-delay: .3s
}

.frame:nth-child(3) {
	transform: rotate(30deg) translateY(40px) translateZ(-10px);
	opacity: 1.00;
	animation-delay: .5s
}