#qmvs_canvas {
    display: grid;
    grid-template-columns: 50% 50%;
}

#qmvs_scheddatesbox {
    width: 80%;
    height: 500px;
    overflow-y: scroll;
}

.qmvs_scheditm {
    margin-top: 3px;
    border: 1px solid white;
    border-radius: 10px;
    padding: 5px;
    box-shadow: inset 0px 0px 15px rgb(0, 0, 0);
    background: linear-gradient(70deg, var(--coloraltprimary) 30%, rgb(57, 8, 8) 100%);
}

.qmvs_scheddate {
    display: flex;
    cursor: pointer;
    min-width: 400px;
    width: 90%;
}