#graphcreate_canvas {
    display: grid;
    grid-template-columns: 50% 48%;
    grid-template-rows: 200px 750px;
    row-gap: 15px;
    column-gap: 20px;
    height: var(--fullscreenheight);
    min-height: 850px;
}

#gcc_header {
    grid-row: 1/1;
    grid-column: 1/3;
}

#graphcreate_topline {
    width: 100%;
    height: 60px;
    display: flex;
    margin-top: 10px;

}

#gc_save {
    background-color: black;
    border: 1px solid red;
}

.gc_save:hover {
    box-shadow: -1px 1px 1px red,
        1px 1px 1px red,
        -1px -1px 1px red,
        1px 1px 1px red;
}

.gc_minibutton {
    background: rgb(72, 158, 244);
    background: linear-gradient(228deg, rgba(72, 158, 244, 0.667) 0%, rgba(50, 61, 198, 0.612) 100%);
    padding-top: 8px;
    height: 45px;
    padding-bottom: 5px;
    padding-left: 20px;
    margin-left: 20px;
    color: white;
    font-size: 17px;
    width: 170px;
    border-radius: 20px;
    border: 1px solid yellow;
    cursor: pointer;
}


.gc_minibutton:hover {
    transition: .2s ease-out;
    transform: translate(-2px, -2px);
    box-shadow: -2px 2px 3px var(--colorsecondary),
        2px 2px 3px var(--colorsecondary),
        -2px -2px 3px var(--colorsecondary),
        2px 2px 3px var(--colorsecondary);
}

#graphcreate_workspace {
    height: 800px;
    display: grid;
    grid-template-columns: 40% 60%;
}

#graphcreate_buckets {
    height: 500px;
}

.gc_bucket {
    margin: 10px;
    margin-top: 20px;
    overflow: hidden;
    border-radius: 15px;
    padding: 10px;
    font-size: 18px;
    box-shadow: -1px 1px 4px #9592bf,
        1px 1px 4px #65655f,
        -1px -1px 4px #65655f,
        1px 1px 4px #65655f;
    cursor: pointer;
}

.gcbucket1 {
    height: 180px;
    width: 250px;
}

.gcdate1 {
    height: 150px;
    width: 300px;
}

.gc_bucket:hover {
    transition: .2s ease-out;
    transform: translate(-2px, -2px);
}

.graphmodal {
    height: 500px;
}

#gc_bucketelem5 {
    height: 100px;
}

#gclabel {
    font-size: 20px;
    margin: 5px;
}

#gclabel_chg {
    border: 1px solid blue;
    border-radius: 2px;
    padding: 2px;
}

.gc_mtblmap {
    display: flex;
    padding: 2px;
    margin: 2px;
    border: 1px solid lightgray;
}

.gc_mtblddown {
    width: 120px;
}

.gc_mtblhead {
    width: 100px;
}

.gc_mtblname {
    padding: 2px;
    color: #65655f
}

.gc_gen_ddown {
    width: 200px;
    height: 30px;
    background-color: black;
    color: white;
    padding: 2px;
    border: 1px solid rgb(246, 247, 201);
}

.gc_modalbackground {
    background-color: black;
    color: white;
    width: 100%;
}

#gc_colorpickmdl {
    height: 300px;
    width: 100%;
    display: grid;
    grid-template-columns: 25% 75%;
    padding: 5px;
}

#gc_coloroptnseldiv {
    width: 300px;
}

#gc_datepickprops {
    color: black;
    background-color: white;
    width: 155px;
}

#gc_colorselbody {
    overflow-y: scroll;
    overflow-x: hidden;
    width: 200px;
}

#gc_swatches {
    margin-left: 60px;
}

.gc_colorsel {
    width: 30px;
}

#gc_samplegraph {
    margin-top: 30px;
    background-color: white;
    width: 500px;
    height: 500px;
    border: 2px solid black;
    box-shadow:
        -2px -2px 4px rgb(196, 181, 228),
        2px 2px 4px rgb(196, 181, 228),
        2px -2px 4px rgb(196, 181, 228),
        -2px 2px 4px rgb(196, 181, 228);
    grid-column: 2/2;
    grid-row: 2/2;
    margin-left: 7%;
}

.gc_df_itm {
    display: flex;
}

#gc_bucket1canvas {
    display: grid;
    grid-template-columns: 50% 50%;
}

#gc_savebtn {
    margin: 10px;
    margin-left: 50px;
    color: white;
    border: 1px solid rgb(46, 24, 117);
    border-radius: 5px;
    box-shadow: inset 0px 0px 8px white, 0px 0px 10px rgb(104, 12, 147);
    width: 150px;
    height: 35px;
    background: linear-gradient(45deg, rgb(20, 9, 150) 40%, rgb(39, 11, 132) 100%);
}

#gc_savebtn:hover {
    transition: .4s;
    background: linear-gradient(45deg, rgb(14, 8, 79) 20%, rgb(67, 8, 138) 80%);
}

.gc_smfltitm {
    border: 1px solid white;
    margin: 3px;
    padding: 3px;
    border-radius: 5px;
    max-width: 250px;
}

.gc_smfltitm:hover {
    background-color: rgb(10, 10, 77);
}

.gc_modelinfobox {
    border: 1px solid rgb(190, 190, 190);
    border-radius: 5px;
    padding: 4px;
    margin: 3px;
    margin-bottom: 6px;
    background-color: #0c0720;
}