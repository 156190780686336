/* #pm_canvas {
    background: radial-gradient(18% 28% at 18% 71%, #FFFFFF59 6%, #073AFF00 100%),
        radial-gradient(70% 53% at 36% 76%, #73F2FFFF 0%, #073AFF00 100%),
        radial-gradient(42% 53% at 15% 94%, #FFFFFFFF 7%, #073AFF00 100%),
        radial-gradient(31% 43% at 7% 98%, #FFFFFFFF 24%, #073AFF00 100%),
        radial-gradient(21% 37% at 12% 21%, #CBDB9F9C 5%, #073AFF00 100%),
        radial-gradient(35% 56% at 91% 74%, #8A4FFFF5 9%, #073AFF00 100%),
        radial-gradient(74% 86% at 67% 38%, #AC264EF5 9%, #073AFF00 100%),
        linear-gradient(125deg, #4EB5FFFF 1%, #4C00FCFF 100%);
    height: var(--fullscreenheight)
} */

#pm_top {
    height: 200px;
    width: 50%;
    margin-left: 20px;
    border: 1px solid white;
    padding: 5px;
    margin-bottom: 30px;
    background: linear-gradient(170deg, rgb(82, 72, 72) 60%, rgb(51, 51, 51) 100%);
    ;
}

#pm_topboxheader {
    margin-bottom: 15px;
    margin-left: 15px;
}

#pm_topboxheader_sub {
    font-size: 22px;
    font-weight: bold;
    margin-left: 15px;
}

#pm_mdltypddown {
    height: 35px;
    width: 150px;
    border: 1px solid black;
    font-size: 20px;
    margin-left: 15px;
}

#pm_main {
    display: grid;
    width: 100%;
    grid-template-columns: 35% 65%;
    grid-template-rows: 800px;
    column-gap: 20px;
    background-size: 100% 100%;
    padding-right: 20px;


}


#pm_blockusr {
    display: block;
    padding: 15px;
    margin-left: 30px;
    margin-top: 5px;
    margin-bottom: 20px;
    background-size: 100% 100%;
    background-position: 0px 0px;
    border-radius: 5px;
    box-shadow:
        3px 3px 8px #c2c9d2,
        -3px -3px 8px #c2c9d2,
        3px 3px 8px #dee2e7,
        inset 0px 0px 12px rgb(16, 16, 16);

    background: linear-gradient(30deg, rgb(82, 72, 72) 60%, rgb(51, 51, 51) 100%);
    overflow: hidden;
}


.pm_userselect:hover {
    transition: .2s;
    background-color: rgb(246, 249, 186);
}


#pm_lstusers {
    grid-column: 1/1;
    padding: 8px;
    margin-top: 35px;
    border-radius: 5px;
    height: 80%;
    width: 90%;
}


#dpm_ddown {
    width: 310px;
    height: 40px;
    border-radius: 3px;
}

.pm_btn {
    width: 130px;
    height: 40px;

}

#pm_sel1 {
    width: 200px;
    height: 30px;
    border-radius: 5px;
    padding: 3px;
    margin-right: 5px;
    margin-left: 5px;
}

.pm_btn {
    background-color: rgb(251, 229, 173);
    border-radius: 15px;
}

.pm_btn:hover {
    transition: .3s ease-in;
    background-color: rgb(255, 211, 99);
}

#pm_newperm {
    display: block;
}

.pm_newpermitm {
    height: 30px;
    overflow: hidden;
    display: flex;
}


#pm_currentperms {
    grid-column: 1/1;
}

#pm_permbubble {
    grid-column: 2/2;
    display: flex;
}


#pm_blockmgperms {
    width: 60%;
    height: 400px;
    display: grid;
    grid-template-rows: 20% 80%;
    margin-left: 60px;
    margin-top: 10px;
    padding: 10px;
    padding-left: 30px;
    border-radius: 10px;
    box-shadow:
        3px 3px 8px #c2c9d2,
        -3px -3px 8px #c2c9d2,
        3px 3px 8px #dee2e7,
        inset 0px 0px 12px rgb(16, 16, 16);
    background: linear-gradient(-30deg, rgb(82, 72, 72) 60%, rgb(51, 51, 51) 100%);
    overflow: hidden;
}

#pm_setperm {
    width: 90%;
    height: 90%;
    display: block;
    grid-row: 2/2;
    border-radius: 10px 15px 10px 15px;
    padding: 10px;
    font-size: 19px;
}


.pm_userselect {
    width: 95%;
    height: 50px;
    border: 1px solid black;
    border-radius: 10px;
    margin-top: 4px;
    padding: 5px;
    overflow: hidden;
}


.pm_permexisting {
    height: 40px;
    width: 90%;
    overflow: hidden;
    border: 1px solid black;
    margin: 3px;
    padding: 2px;
    border-radius: 5px;
    color: white;
    cursor: pointer;
    font-size: 20px;
}

.pm_permexisting:hover {
    filter: brightness(130%)
}

.pm_existprmitm {
    height: 28px;
}