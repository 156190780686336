.jdmitm {
    display: grid;
    border: 1px solid rgb(151, 151, 151);
    border-radius: 5px;
    margin: 3px;
    background-color: rgb(24, 4, 35);
    grid-template-columns: 20% 60% 20%;
}

.jdmitmhead {
    display: grid;
    border: 1px solid rgb(151, 151, 151);
    border-radius: 5px;
    margin: 3px;
    background-color: rgb(60, 50, 66);
    grid-template-columns: 20% 60% 20%;
}

.jdmsubitm {
    margin-left: 10px;
    border: 1px solid rgb(48, 48, 48);
}

#jdm_termsbox {
    background-color: rgb(8, 8, 60);
    padding: 3px;
    padding-right: 6px;
    border: 1px solid grey;
    margin: 3px;
    margin-left: 10px;
}