#empfieldbox {
    background-color: rgb(61, 37, 82);
    color: white;
    padding: 8px;
    height: 200px;
    width: 720px;
    border-radius: 10px;
    margin: 10px;
    margin-left: 20px;
    box-shadow:
        inset -2px -2px 8px #1f3449,
        inset -2px -2px 4px #38155b;
    transition: 1s;
}

#empfield_subbox {
    display: grid;
    grid-template-rows: 30px 30px 30px 30px;
    grid-template-columns: 300px 300px;
    grid-auto-flow: column;
}

.fieldsub {
    display: grid;
    width: 350px;
    grid-template-columns: 200px 150px;
    background-color: rgb(79, 73, 85);
    color: white;
    margin: 2px;
    margin-left: 5px;
    padding-left: 3px;
    border: 1px solid rgb(121, 121, 121);
    border-radius: 4px;
}