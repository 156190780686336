.pbody {
    display: absolute;
    grid-row: 3/3;
    grid-column: 2/2;
    justify-content: center;
    align-items: center;
    min-height: 50vh;
}

.ppyramid {
    position: relative;
    width: 200px;
    height: 200px;
    transform-style: preserve-3d;
    transform: rotateY(326deg) rotateX(2deg) rotateZ(359deg);
    animation: rotate 10s linear infinite;
}

.pside {
    width: 0;
    height: 0;
    position: absolute;
    opacity: 0.7;
    border: 100px solid transparent;
    border-bottom: 200px solid rgba(230, 226, 226, 0.608);
    border-top: 0px;
    animation: colrchange 60s linear infinite;
}

.pbase {
    position: absolute;
    width: 100%;
    height: 100%;
    transform: translateY(73px) rotateX(90deg);
    border: 0;
    background: blue;

}

.pone {
    transform: rotateX(30deg);
    transform-origin: 0 0;
}

.ptwo {
    transform-origin: 100px 0;
    transform: rotateY(90deg) rotateX(-30deg);
    border-bottom-color: rgb(133, 133, 133);
}

.pthree {
    transform-origin: 100px 0;
    transform: rotateY(270deg) rotateX(-30deg);
    border-bottom-color: rgb(227, 222, 225);
}

.pfour {
    transform-origin: 100px 0;
    transform: rotateY(0) rotateX(-30deg);
    border-bottom-color: rgb(251, 251, 246);
}


@keyframes rotate {
    from {
        transform: rotateY(0) rotateX(0deg) rotateZ(10deg);
    }

    to {
        transform: rotateY(360deg) rotateX(00deg) rotateZ(10deg);
    }
}

@keyframes colrchange {
    0% {
        border: 100px solid transparent;
        border-bottom: 200px solid rgba(230, 226, 226, 0.608);
        border-top: 0px;
    }

    10% {
        border-bottom: 200px solid rgba(45, 204, 210, 0.61);
        border-top: 0px solid transparent;
    }

    20% {
        border-bottom: 200px solid rgba(230, 226, 226, 0.608);
        border-top: 0px solid transparent;
    }

    30% {
        border-bottom: 200px solid rgba(212, 20, 20, 0.649);
        border-top: 0px solid transparent;
    }

    40% {
        border-bottom: 200px solid rgba(230, 226, 226, 0.608);
        border-top: 0px solid transparent;
    }

    50% {
        border-bottom: 200px solid rgba(57, 215, 57, 0.363);
        border-top: 0px solid transparent;
    }

    60% {
        border-bottom: 200px solid rgba(230, 226, 226, 0.608);
        border-top: 0px solid transparent;
    }

    70% {
        border-bottom: 200px solid rgba(123, 186, 215, 0.363);
        border-top: 0px solid transparent;
    }

    80% {
        border: 100px solid transparent;
        border-bottom: 200px solid rgba(230, 226, 226, 0.608);
        border-top: 0px solid transparent;
    }

    90% {
        border: 100px solid transparent;
        border-bottom: 200px solid rgba(221, 207, 58, 0.595);
        border-top: 60px solid transparent;
    }

    100% {
        border: 100px solid transparent;
        border-bottom: 200px solid rgba(230, 226, 226, 0.608);
        border-top: 20px solid transparent;
    }
}