#empsim_main {
    display: grid;
    grid-template-columns: 50% 50%;
    min-width: 800px;
}

.empsim_parambox {
    width: 400px;
    display: flex;
}

.empsim_paramtitle {
    width: 200px;
}

.empsim_fldbtn {
    border: 1px solid grey;
    border-radius: 5px;
    margin: 3px;
    width: 170px;
    overflow: hidden;
    padding: 2px;
    padding-left: 5px;
}

.empsim_fldbtn:hover {
    background-color: var(--colorprimarybright);
}

.empsim_date {
    background-color: black;
    color: white;
    padding: 5px;
    border-radius: 4px;
    width: 200px;
    height: 70px;
}

.sim_datarow {
    display: flex;
}

.sim_dataitm {
    width: 200px;
}