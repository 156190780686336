#titlepg_canvas {
    display: grid;
    grid-template-columns: 35% 500px 25%;
    padding-top: 200px;
    min-height: var(--fullscreenheight);
    height: 950px;
}

#titlepg_logo {
    margin: auto;
}

.titlepg_glowpoint {
    width: 50px;
    height: 50px;
    position: absolute;
    animation: boxglow 2s;
    animation-iteration-count: 5;
}

#titlepg_title {
    animation: titleglow 8s;
    cursor: pointer;
}

#titlepg_subtitle {
    cursor: pointer;
    animation: subtitleglow 8s;
    margin: auto;
    text-align: center;
}


@keyframes boxglow {
    0% {
        box-shadow: 0 0 5px rgba(0, 0, 255, 0.7);
    }

    50% {
        box-shadow: 0 0 50px rgba(188, 248, 10, 0.7);
    }

    100% {
        box-shadow: 0 0 5px rgba(0, 0, 255, 0.7);
    }
}

@keyframes titleglow {
    0% {
        color: rgb(0, 0, 0);
    }

    40% {
        color: rgb(0, 0, 0);
    }

    100% {
        color: rgb(10, 245, 10);
    }
}

@keyframes subtitleglow {
    0% {
        color: rgb(0, 0, 0);
    }

    95% {
        color: rgb(0, 0, 0);
    }

    100% {
        color: rgb(255, 255, 255);
    }
}