#dmgrtable_canvas {
    display: grid;
    grid-template-columns: 20% 80%;
    grid-template-rows: 250px 750px;
    row-gap: 20px;
    column-gap: 20px;
}


#dmgrtbl_sidebar {
    grid-row: 1/3;
    grid-column: 1/1;
}

.dmgrtbl_sidebtn {
    height: 60px;
    color: rgb(254, 254, 192);
    margin-top: 10px;
    cursor: pointer;
}

#dmgrtbl_across {
    grid-column: 2/2;
}

.dt_uploadline {
    display: flex;
    margin-top: 10px;
}

#dmgrsubmitbtn {
    margin-top: 20px;
}

#dptupload_form {
    width: 900px;
    display: grid;
    grid-column: 600px 300px;
}

#div_dmgrreturn {
    height: 30px;
}

.dt_field {
    display: flex;
    width: 600px;
    padding: 5px;
    border: 1px solid rgb(141, 141, 141);
}

.dt_head {
    display: flex;
    width: 600px;
    padding: 5px;
    border: 1px solid rgb(141, 141, 141);
}

.dt_fielditem {
    width: 165px;

}

.dt_headitem {
    width: 165px;
}

#dmgr_botbtn {
    color: white;
}

#dmgr_uplhistmain {
    display: grid;
    grid-template-columns: 30% 70%;
}

.dmgr_newvalitm {
    border: 1px solid #777777;
    padding: 3px;
    width: 50%;
    min-width: 300px;
    border-radius: 3px;
    margin: 2px;
    background-color: #200333;
}