#mgf_grpbox {
    width: 40%;
}

#mgf_fldbox {
    height: 500px;
    width: 40%;
}

.mgf_grpitm {
    font-size: 16px;
    border: 1px solid black;
    overflow: hidden;
    height: 80px;
    background-color: rgb(25, 1, 39);
    padding: 5px;
    border: 1px solid grey;
    border-radius: 5px;
    margin-top: 4px;
}

.mgf_flditm {
    border: 1px dotted rgb(56, 56, 56);
    margin: 3px;
    padding-left: 4px;
    display: flex;
    cursor: pointer;
}

.gsm_numinput {
    width: 100px;
}

#gsnm_rulebox {
    overflow-y: scroll;
    height: 400px;
}

#gsm_fvalbox {
    height: 400px;
    overflow-y: scroll;
}