#rsm_existblock {
    display: grid;
    grid-template-columns: 50% 50%;
    min-width: 900px;
    overflow: hidden;
    height: 850px;
}

.rmn_valitem {
    width: 60%;
    border: 1px solid rgb(81, 81, 81);
    display: flex;
    padding: 2px;
    padding-left: 8px;
}

.rmn_subitm {
    width: 50%
}

#rmn_fieldbox {
    height: 420px;
    margin-top: 10px;
    overflow-y: scroll;
}