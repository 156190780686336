#bargraph_elempopup {
    background-color: rgb(232, 230, 228);
    padding: 4px;
    border-radius: 4px;
    border: 1px solid black;
}


.tooltipelem_btnclose {
    border: 1px solid blue;
    width: 90%;
    background-color: darkblue;
    color: white;
    margin: 5%
}

.tooltipelem_btnoptn:hover {
    background-color: rgb(178, 137, 217);
}