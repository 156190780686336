#tp_header {
    display: block;
    height: 150px;
    padding: 10px;
}

#tp_headeroptns {
    height: 35px;
    display: flex;
}

#tp_ddown1 {
    margin-left: 10px;
    margin-right: 10px;
}

.tp_datarow {
    display: flex;
}


.tp_tblcell {
    border: 1px solid grey;
    width: 200px;
    overflow: hidden;
    height: 40px;
    padding: 1px;
}

.tp_headercell {
    border: 3px solid black;
    width: 200px;
    overflow: hidden;
    height: 40px;
    padding: 5px;
    font-weight: bold;
}