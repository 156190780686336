#floatdept {
    margin: 1px;
    width: 100vw;
    height: var(--fullscreenheight);
    background: linear-gradient(90deg, rgba(2, 0, 36, .7) 0%, rgba(69, 69, 123, 0.292) 20%);
}

#deptviewheaderbox {
    padding-left: 3px;
    padding-right: 3px;
    padding-top: 2px;
    padding-bottom: 2px;
    background: linear-gradient(90deg, rgba(2, 0, 36, .7) 0%, rgba(69, 69, 123, 0.292) 20%);
    background-blend-mode: darken;
    border: 4px solid black;
}

#deptviewheader {
    color: white;
    height: 70px;
    font-size: 24px;
    letter-spacing: .15em;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    padding: 5px;
}

#dvheading {
    height: 30px;
    font-size: 16px;
    display: flex;
}



.link {
    fill: none;
    stroke: #ccc;
    stroke-width: 1px;
}

#dv_date {
    margin-left: 15px;
}