#qmvv_main {
    display: grid;
    grid-template-columns: 40% 50%;
    grid-template-rows: 250px 500px;
    row-gap: 20px;
    column-gap: 40px;
    min-height: 850px;
}


#qmvv_mainleft {
    grid-row: 1/3;
}

#qmvv_header {
    display: grid;
    grid-template-columns: 70% 30%;
}

.qmvv_qryitem {
    width: 300px;
    border: 1px solid rgb(111, 111, 111);
    cursor: pointer;
    margin-top: 8px;
    padding: 3px;
    background-color: var(--colorprimary);
    margin-left: 5px;

}

.qmvv_mainitem {
    font-size: 20px;
    border: 1px solid rgb(61, 22, 138);
    margin: 5px;
    padding: 3px;
    box-shadow: 0px 0px 3px white;
    cursor: pointer;

}

.qmvv_mainitem:hover {
    box-shadow: 0px 0px 7px white;
}

.qmvv_list {
    height: 350px;
    padding: 10px;
}

.qmvv_subheader {
    font-size: "22px";
    letter-spacing: ".1em";
}

.qmvv_chartddown {
    font-size: 20px;
    width: 200px;
    height: 35px;
    border-radius: 3px;
    padding: 2px;
}

#qmvv_chartbox {
    margin: auto;
    border: 5px solid black;
    border-radius: 10px;
    background-color: white;
    grid-column: 2/2;
    grid-row: 2/2;
}

.qmvv_coloritm {
    display: flex;
    color: white;
}

#qmvv_colorbox {
    height: 180px;
    overflow-y: scroll;
}

.qmvv_item {
    border: 1px solid grey;
    padding: 3px;
    width: 50%;
    min-width: 120px;
    margin: 5px;
    background-color: rgb(30, 30, 30);
}