.wlk_stepbox {
    font-size: 18px;
    height: 700px;
}

.wlk_samplefld {
    margin: 3px;
    padding: 3px;
    border: 1px solid grey;
    border-radius: 5px;
    display: flex;
    background-color: rgb(23, 8, 8);
    max-width: 600px;
}

.wlk_fldddown {
    width: 250px;
}

.wlk_keyitm {
    margin: 4px;
    border: 1px solid grey;
    border-radius: 5px;
    width: 120px;
    padding: 3px;
    padding-left: 10px;
    cursor: pointer;
}

.wlk_dateitm {
    border: 1px solid grey;
    border-radius: 5px;
    padding: 4px;
    cursor: pointer;
    background-color: rgb(20, 8, 31);
}

.wlk_dateitm:hover {
    background-color: rgb(60, 11, 69);
    box-shadow: 0px 0px 7px rgb(14, 35, 65);
}

#wlk_kpigraph {
    background-color: rgb(183, 184, 173);
}

.wlk_stepbtn {
    display: flex;
}

.walk_kpivalitm {
    border: 1px solid grey;
    border-radius: 5px;
    padding: 3px;
    margin: 2px;
    background-color: rgb(13, 13, 61);
    display: flex;
    font-size: 17px;
}