.ldvw_box {
    background-color: rgb(245, 243, 240);
    padding: 5px;
    border: 1px solid rgb(239, 231, 212);
    margin: 5px;
    border-radius: 4px;
    min-width: 150px;
}

.ldvw_valitm {
    border: 1px solid black;
    padding: 3px;
    margin: 4px;
    border-radius: 8px;
    min-width: 120px;
    width: 90%;
    background-color: white;
}

.ldvw_valitm:hover {
    background-color: rgb(171, 221, 221);
}

#ldvw_grid {
    display: grid;
    grid-template-columns: 40% 60%;
    min-width: 800px;
}

.ldvw_valsubitm {
    margin-left: 5px;
}

.ldvw_right {
    float: right;
    text-align: right;
}

.ldvw_optbtn {
    width: 120px;
    border: 1px solid black;
    border-radius: 8px;
    height: 35px;
    margin: 5px;
    margin-top: 15px;
}

#lv_divchart {
    background: white;
}

#ldvw_chartwrap {
    background: linear-gradient(to right, rgb(243, 247, 205), rgb(255, 181, 165));
    padding: 3px;
    border-radius: 8px;
}