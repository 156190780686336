#dashsel_main {
    display: grid;
    grid-template-columns: 60% 40%;
    min-width: 850px;
}

.dashsel_dbobj {
    height: 100px;
    width: 400px;
    margin: 5px;
    border: 2px solid rgb(192, 192, 192);
    border-radius: 5px;

    letter-spacing: .1em;
    padding: 5px;
    color: black;
    cursor: pointer;
}

.dashsel_dbobj:hover {
    transform: translate(3px, 3px);
    box-shadow: -3px -3px 5px black;
}