.fv_mcddown {
    min-width: 250px;
    border-radius: 4px;
    padding: 2px;
}

#fv_newfldform {
    width: 80%;
    min-width: 1300px;
    display: grid;
    grid-template-columns: 20% 20% 15% 25% 20%;
}