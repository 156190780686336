.custtable {
    background-color: rgb(222, 222, 222);
    color: black;
}


.paginatebtn {
    color: rgb(240, 78, 78);
    display: flex;
    margin-left: 10px;
    margin-right: 10px;
    cursor: pointer;
}

.paginationdiv {
    padding: 5px;
    color: rgb(242, 221, 254);
    display: flex;
    margin: auto;
    margin-top: 15px;
    height: 40px;
    width: 320px;
    margin-left: auto;

}

.custtbl_cell {
    border: 1px solid black;
    padding: 3px;
    font-size: 16px;
    overflow: hidden;
    min-width: 50px;
}

.custtbl_headercell {
    border: 1px solid black;
    padding: 5px;
}

.celledit {
    cursor: pointer;
    color: rgb(25, 0, 255)
}

#modal_enumName {
    font-size: 20px;
    padding-left: 30px;
}