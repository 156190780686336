.upg_box {
    background-color: var(--coloroffwhite);

}

#upg_userstat {
    height: 400px;
    width: 400px;
    margin: 10px;
    border-radius: 10px;
    padding: 10px;
    background-color: rgba(240, 248, 255, 0.844);
    border: 5px solid rgb(112, 13, 13);
    box-shadow:
        1px 1px 3px black;
}

.usrpg_itm {
    font-size: 18px;
    font-weight: 400;
}

.upg_userrep {
    cursor: pointer;
    border: 1px solid rgb(130, 130, 130);
    border-radius: 3px;
    padding: 2px;
}

@import url('https://fonts.googleapis.com/css?family=Source+Code+Pro:200');

#upg_body {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: 50% 50%;

}

#upg_canvas {
    height: var(--fullscreenheight);
    background-image: url('https://static.pexels.com/photos/414171/pexels-photo-414171.jpeg');
    background-size: cover;
    -webkit-animation: slidein 100s;
    animation: slidein 100s;

    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;

    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;

    -webkit-animation-direction: alternate;
    animation-direction: alternate;
}

@-webkit-keyframes slidein {
    from {
        background-position: top;
        background-size: 3000px;
    }

    to {
        background-position: -100px 0px;
        background-size: 2750px;
    }
}

@keyframes slidein {
    from {
        background-position: top;
        background-size: 3000px;
    }

    to {
        background-position: -100px 0px;
        background-size: 2750px;
    }

}