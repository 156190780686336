.jpv_requireitm {
    display: flex;
    width: 900px;
    border: 1px solid grey;
    height: 60px;
    border-radius: 5px;
    padding: 3px;
    padding-left: 6px;
    background-color: rgb(26, 5, 5);
    margin: 3px;
    cursor: pointer;
}

.jpv_requireitm:hover {
    background-color: rgb(55, 32, 78);
    box-shadow: inset 0px 0px 10px white,
        0px 0px 9px rgb(59, 176, 185);
}

.jpv_aplitm {
    display: flex;
    border: 1px solid grey;
    height: 30px;
    border-radius: 5px;
    padding: 3px;
    padding-left: 6px;
    background-color: rgb(6, 5, 26);
    margin-top: 2px;
    cursor: pointer;
}

.jpv_aplskl {
    border: 1px solid rgb(201, 209, 165);
    height: 60px;
    border-radius: 5px;
    padding: 3px;
    padding-left: 6px;
    background-color: rgb(12, 17, 2);
}

#jpv_postcard {
    margin-top: 5px;
    background-color: rgb(27, 21, 53);
    border: 1px solid white;
    border-radius: 5px;
    padding: 4px;
    box-shadow: 0px 0px 13px rgb(61, 40, 224), inset 0px 0px 5px white;
}

#jpv_importgrid {
    display: grid;
    grid-template-columns: 45% 45%;
}

#jpv_viewgrid {
    display: grid;
    grid-template-columns: 65% 35%;
}

#jpv_skillsmain {
    background-color: rgb(11, 11, 52);
    border: 1px solid grey;
    box-shadow: inset 0px 1px 5px white;
    padding: 10px;
    height: 480px;
}

#jpv_skillsbox {
    display: grid;
    grid-template-columns: 40% 50%;
}

#jpv_skillssub {
    display: grid;
    grid-template-columns: 30% 40% 30%;
}

.jpvoptbtn {
    width: 140px;
}