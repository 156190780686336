.signupContainer {
	display: grid;
	grid-template-columns: 20% 50% 20%;
	grid-column-gap: 20px;
	grid-template-rows: 20% 700px;
	background: linear-gradient(to top, #25afaf, #d4dd56);
	font-family: Helvetica;
	height: var(--fullscreenheight)
}

#signupbox {
	grid-column: 2/3;
	grid-row: 2/2;
	margin-bottom: 200px;
	background: linear-gradient(110deg, #b9c1bf 0%, #b3c996 100%);
	overflow: hidden;
	border-radius: 10px;
	box-shadow: 0 10px 20px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
	text-align: center;
	z-index: 1;
}

#signformbox {
	margin: 0 auto;
	margin-top: 70px;
	letter-spacing: 0.5px;
	line-height: 20px;
	border: 1px solid #657e80;
	background: #494949;
	text-align: center;
	width: 50%;
	justify-content: center;
	border-radius: 10px;
}

#signuptitle {
	letter-spacing: .40rem;
	font-size: 38px;
	margin-top: 50px;
	font-family: tahoma;
}

.sign-input {
	background-color: rgb(255, 255, 255);
	border: none;
	padding: 12px 15px;
	margin: 8px 0;
	width: 100%;
}

.signup-button {
	margin: 3px;
	border-radius: 20px;
	border: black solid 1px;
	background-color: #3ddac5;
	color: #000000;
	font-size: 12px;
	font-weight: bold;
	padding: 12px 45px;
	letter-spacing: 1px;
	text-transform: uppercase;
}

.login-button:hover {
	filter: brightness(1.5);
	transition: .6s
}

#signuplinkbox {
	font-size: 10px;
	text-align: left;
}