#dxb_canvas1 {
    background-color: var(--coloroffwhite);
    height: var(--fullscreenheight);
    color: black;
}

#dxb_header {
    height: 100%;
    border: 1px solid black;
    display: flex;
    background-color: var(--colorprimary);
}


#dxb_mainheader {
    font-size: 32px;
    color: white;
}

#dxb_resize {
    width: 300px;
    color: white;
    display: flex;
    margin-left: 20px;
}

.dxb_resize_form {
    display: flex;
    margin-left: 20px;
}

.dxb_res_tag {
    margin-left: 10px;
    margin-right: 10px;
    letter-spacing: .1em;
}


#dxb_circle {
    display: absolute;
    background: linear-gradient(55deg, rgb(66, 31, 92) 0%, rgb(20, 11, 36) 100%);
    border-radius: 50%;
    margin-left: 30%;
    margin-top: 30px;
    height: 500px;
    width: 500px;
    top: 100px;
    z-index: -1;
    box-shadow: 3px 3px 10px rgb(38, 12, 40);
    animation: boxglow 5s;
    animation-iteration-count: 15;
}

@keyframes boxglow {
    0% {
        box-shadow: 3px 3px 10px rgb(38, 12, 40);
    }

    10% {
        box-shadow: 3px 3px 10px, -3px -3px 20px rgb(158, 39, 174);
    }

    40% {
        box-shadow: 3px 3px 10px, -3px -3px 10px rgb(80, 22, 88);
    }

    75% {
        box-shadow: 3px 3px 30px, -3px -3px 10px rgb(138, 63, 148),
            -3px 1px 10px, -3px 1px 10px rgb(102, 53, 108);
    }

    85% {
        box-shadow: 3px 3px 30px, 1px -3px 10px rgb(92, 35, 100);
    }

}


#dxb_graphsparent {
    background: linear-gradient(145deg, var(--colorprimary) 50%, var(--colorfade1) 100%);
    display: grid;
    overflow: hidden;
    grid-template-columns: 250px 1200px 200px;
    row-gap: 20px;
    color: white
}

.dxb_sidepane {
    background: linear-gradient(-55deg, #110b1a 0%, #152946 100%);
    width: 350px;
    padding: 10px;
    border: 2px solid rgb(180, 180, 180);
    border-radius: 5px;
}


#dxb_managebtndiv {
    background-color: var(--colorprimary);
    color: var(--coloroffwhite);
    padding: 5px;
    width: 220px;
    margin-left: 150px;
}

#dxb_savediv {
    margin-right: 10%;
    height: 40px;
    color: white;
}

.dxb_controlbtn {
    border: 1px solid aqua;
    border-radius: 10px;
    padding: 3px;
    background-color: var(--colorprimarybright);
    color: aqua;
    width: 100px;
}

.dxb_savebtn {
    border-radius: 10px;
    height: 35px;
    padding: 3px;
    background-color: var(--colorprimarybright);
    color: aqua;
    border: 1px solid rgb(0, 255, 179);
    width: 200px;
    font-size: 18px;
}

.dxb_savebtn:hover {
    transition-delay: .1s;
    transition: .3s ease-out;
    box-shadow: 0px 0px 15px rgb(0, 255, 179);
}

#dxb_inc {
    width: 50px;
    margin: auto;
    margin-left: 20px;
}

/* move buttons */
#dxb_movebtndiv {
    display: block;
    width: 200px;
}

.dxb_movebtnsub {
    margin: auto;
    display: flex;
}

.dxb_movebtn {
    height: 30px;
    width: 110px;
    margin: auto;
}

#dxb_rowsXcols {
    display: flex;
    padding: 3px;
}

/* mini queries */
#dxb_miniquery_container {
    display: block;
    margin-top: 5px;
    margin-left: 5px;
    height: 700px;
    overflow-y: scroll;
}

.dxb_miniquery {
    color: white;
    width: 120px;
    height: 100px;
    border: 1px solid white;
    padding: 5px;
    cursor: pointer;
    overflow: hidden;
}

.miniquery:hover {
    box-shadow: 0px 0px 5px inset white;
}

#dxb_newdash {
    position: fixed;
    margin-left: 100px;
    margin-top: 200px;
}

#dxb_graphs {
    background-color: var(--coloroffwhite);
    border: 3px solid black;
    border-radius: 0px;
    overflow-y: scroll;
}

.dxb_btnstack {
    display: block;
    margin-right: 10px;
}

.dbmg_graph {
    display: block;
    grid-row: inherit;
    grid-template: inherit;
    height: 200px;
    color: white;
    border-radius: 15px;
    padding: 10px;
    overflow: hidden;
    resize: both;
    cursor: move;
    position: fixed;
    border: 2px solid black;
    box-shadow: 2px 2px 4px black;
}

.dm_rditm {
    border: 1px solid var(--colorsecondary);
    border-radius: 5px;
    width: 200px;
    height: 40px;
    padding: 5px;
    margin-top: 5px;
    cursor: pointer;
}

.dm_page {
    width: 120px;
    background-color: white;
    color: black;
    padding: 3px;
    border-radius: 0px 10px 10px 0px;
    height: 50px;
    margin-top: 15px;
    cursor: pointer;
}

.dxb_filteritem {
    border: 1px solid white;
    width: 200px;
    padding: 3px;
    margin-top: 3px;
    cursor: pointer;
}

.dxb_svitm {
    width: 150px;
    border: 1px solid black;
    border-radius: 12px;
    padding: 5px;
    padding-left: 15px;
    background-color: #42428b;
    color: rgb(243, 246, 206);
}

.dxb_sdate {
    border: 1px solid rgb(111, 111, 111);
    border-radius: 10px;
    margin: 3px;
    padding: 4px;
    font-size: 14px;
    cursor: pointer;
    background: black;
}

.dxb_rmvbtn {
    border: 1px solid white;
    border-radius: 5px;
    background-color: black;
    color: white;
    box-shadow: inset 0px 0px 15px rgb(138, 15, 15);
}