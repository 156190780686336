#basic-nav-dropdown1,
#basic-nav-dropdown2,
#basic-nav-dropdown3 {
	color: #c2c2c2;
}



#basic-nav-dropdown1:hover,
#basic-nav-dropdown2:hover,
#basic-nav-dropdown3:hover {
	color: yellow;
}

.navbar-expand-sm {
	z-index: 1
}

.guestlink {
	display: flex;
}

#guestlink1:hover,
#guestlink2:hover {
	background-color: red;
}

li {
	background-color: #111111;
}

.homeicon {
	width: 80px;
	font-size: 18px;
}

.nav-link:hover {
	transition: .2s;
}

#navgrid {
	display: grid;
	width: 100%;
	grid-template-columns: 75% 25%;
	height: 50px
}


#navschema {
	min-width: 300px;
	display: flex;
	margin: 2px;

}

#navschema_curr {
	font-weight: bold;
	margin-right: 15px;
	vertical-align: bottom;
	margin-top: 5px;
	min-width: 170px;
	overflow-x: hidden;
	overflow-y: hidden;
	white-space: nowrap;
	height: 35px
}

#schemalinknav:hover {
	color: #1df9c2;
	transition: 1s ease-in;
}

#nav_tutorialdiv {
	cursor: pointer;
	color: white;
}