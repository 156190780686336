.qmv3d_ditm {
    display: flex;
    border: 1px solid grey;
    margin: 3px;
    padding: 2px;
    padding-left: 5px;
    background-color: rgb(148, 148, 148);
}

.qmv3d_dsubitm {
    min-width: 100px;
    width: 20%;
}

.qmv3d_ditm:hover {
    background-color: rgb(31, 52, 121);
    box-shadow: 0px 0px 5px yellow;
}