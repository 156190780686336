.dnp_note {
    display: flex;
}

#dnp_notedata {
    border: 1px solid white;
    width: 90%;
    height: 600px;
    display: block;
    padding: 2px;
    overflow-y: scroll;
}

.dnp_noterow {
    width: 100%;
    height: 100px;
}

.dnp_noteitm {
    background-color: aliceblue;
    color: black;
    padding: 5px;
    border-radius: 10px;
    border: 1px solid black;
    width: 45%;
}

.dnp_otheruser {
    float: left;
    text-align: left;
}

.dnp_curruser {
    float: right;
    text-align: right;
    cursor: pointer;
}

.dnp_curruser:hover {
    transition: .3s ease-out;
    background-color: rgb(177, 213, 248);
    box-shadow: 0px 0px 4px rgb(255, 255, 200);
}