#empboxMain {
    height: 560px;
}

#cardGrid {
    display: grid;
    grid-template-columns: 30% 60%;
    grid-template-rows: 300px 150px;
}

#empboxtitle {
    grid-column: 1/3;
    grid-row: 1/1;
    color: white;
    font-family: verdana;
    font-size: 19px;
    letter-spacing: .08em;
    word-spacing: .1em;
    margin-left: 10px;
}

#cardmain {
    grid-column-start: 2/3;
    grid-row: 1/1;
    background: linear-gradient(45deg, #20041d, #260955 90%);
    color: white;
    font-size: 14px;
    margin: 15px;
    margin-top: 50px;
    margin-bottom: 20px;
    border-radius: 4px 20px 4px 20px;
    padding: 12px;
    box-shadow:
        5px 3px 10px white,
        -5px -3px 10px white;
}

#cardheader {
    font-family: monospace;
    letter-spacing: .2em;
    background-color: rgba(203, 216, 14, 0.103);
    width: 40%;
    padding-left: 15px;
    margin-bottom: 5px;
}

.arrow {
    margin: 20px;
    border: solid red;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    width: 20px;
    height: 20px;
}

.down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
}


.ddownCard {
    grid-column: 1/1;
    grid-row: 1/2;
    margin-top: 20px;
    margin-left: 20px;
    color: white;
    font-size: 14px;
    width: 160px;
    height: 360px;
}

.datepoint {
    cursor: pointer;
    margin-top: 6px;
    margin-bottom: 3px;
    padding-left: 6px;
}

.ddownMain {
    background: linear-gradient(-45deg, #20041d, #260955 90%);
    border-radius: 00px 0px 10px 10px;
    border: 1px solid black;
    box-shadow:
        inset -1px -1px 10px #1a0d55;
}

#ddownHeader {
    font-size: 17px;
    background: linear-gradient(-45deg, #20041d, #260955 90%);
    border-radius: 0px 50px 0px 0px;
    margin: 0px;
    padding-left: 9px;
    border: 1px solid black;
    letter-spacing: .05em;
    box-shadow:
        inset -1px -1px 3px #333455;
}

.datepoint:hover {
    background-color: rgb(22, 31, 155);
}

.datepoint:click {
    background-color: rgb(155, 22, 22);
    transition: ease-out .3s;
    transition-delay: 1s;
}

#cardsecondary {
    grid-column: 2/2;
    grid-row: 2/2;
    background: linear-gradient(-145deg, #20041d, #260955 90%);
    color: white;
    width: 400px;
    margin-left: 100px;
    border-radius: 10px 10px 10px 10px;
    padding: 5px;
    box-shadow:
        2px 1px 4px white,
        -2px -1px 4px white;
}