.cmai_quesitem {
    border: 1px solid grey;
    border-radius: 5px;
    background: linear-gradient(40deg, black 30%, var(--colorsecondary) 100%);
    padding: 3px;
    margin: 4px;
    cursor: pointer;
}

.cmai_quesitem:hover {
    background: linear-gradient(40deg, rgb(84, 48, 183) 30%, rgb(198, 198, 198) 100%)
}

.cmai_ansitem {
    border: 1px solid grey;
    border-radius: 5px;
    background: black;
    padding: 3px;
    margin: 4px;
}