@import url(https://fonts.googleapis.com/css?family=Source+Code+Pro:200);
#mv_maingrid {
    display: grid;
    grid-template-columns: 40% 60%;
    grid-row: 550px;
    margin-top: 20px;
}

#emv_mdldown {
    font-size: 18px;
    width: 250px;
}

#emv_fldbox {
    display: block;
    overflow-y: scroll;
    height: 550px;
    width: 90%;
}

.emv_fldboxdetail {
    display: flex;
    min-width: 700px;
    width: 80%;
}

.emv_fldboxdetail:hover {
    background-color: rgb(119, 110, 240);
}

.emv_itmName {
    width: 50%;
    min-width: 300px;
    overflow-x: hidden;
}

.emv_box {
    height: 700px;
}

.emv_mdldet {
    margin-left: 25px;
    margin-top: 3px;
}

.emv_mdlbtn {
    min-width: 170px;
    height: 30px;
    color: rgb(212, 211, 211);
    border: 1px solid rgb(152, 152, 152);
    border-radius: 7px;
    font-size: 15px;
    padding: 3px;
    padding-left: 5px;
    cursor: pointer;
    overflow: hidden;
    margin-left: 3px;
    margin-bottom: 2px;
}

.emv_itmdetail {
    border: 1px solid rgb(119, 110, 240);
    background-color: rgb(214, 214, 214);
    border-radius: 6px;
    margin: 3px;
    padding: 3px;
    color: black;

}

#emv_detailheaddata {
    width: 70%;
}

.emv_paginate {
    margin: auto;
    cursor: pointer;
}


#emv_detaillst {
    border: 1px solid rgb(210, 210, 210);
}

.emv_detailitm {
    display: flex;
    margin: 3px;
    padding: 2px;
    border: 1px solid rgb(125, 125, 125);
}

.emv_detailitm_a {
    width: 40%;
    min-width: 120px;
}

.emv_detailitm_b {
    width: 40%;
    min-width: 150px;
}


.emv_itmName,
.emv_itmVal {
    border: 1px solid grey;
    font-size: 16px;
    width: 50%;
    min-width: 150px;
    padding: 2px;
    padding-left: 5px;
    background-color: rgba(117, 19, 186, 0.071);
    cursor: pointer;
}
.ndt_stlmaininput {
    position: absolute;
    right: 0;
    top: 350px;
}


#stlcollapsible-divI {
    width: 0px;
    height: 450px;
    transition: width 0.3s ease;
    overflow: hidden;
}

#collapsible-mid:checked~#stlcollapsible-divI {
    width: 550px;
}

.ndt_sideYborder {
    background: grey;
    background-size: 400% 400%;
    width: 100%;
    height: 450px;
    padding: 1px;
}

.ndt_sideYinner {
    overflow: hidden;
    background: linear-gradient(90deg, var(--colorprimary) 0%, black 100%);
    padding-left: 10px;
    padding-top: 7px;
    padding-right: 5px;
    width: 100%;
    height: 448px;

    /* Manually adjusted to account for border width */
}

.stlLabelI {
    left: -20px;
    height: 450px;
    border-radius: 166px 0px 0px 166px;
    padding: 15px 10px;
    width: 20px;
}
.ndt_stlmainselect {
    position: absolute;
    right: 0;
    top: 150px;
}

.ndt_sideXborder {
    background: grey;
    background-size: 400% 400%;
    width: 100%;
    height: 50px;
    padding: 1px;
}

.ndt_sideXoffset {
    background: linear-gradient(-270deg, var(--coloraccent), var(--colorsecondary), var(--colorprimary), black);
    background-size: 400% 400%;
    width: 100%;
    padding: 2px;
    padding-left: 0px;
    animation: gradientAnimation 10s ease infinite;
}

.ndt_sideXinner {
    background: linear-gradient(90deg, var(--colorprimary) 0%, black 100%);
    padding-left: 10px;
    padding-top: 7px;
    width: 100%;
    height: 44px;
    /* Manually adjusted to account for border width */
}

#stlcollapsible-divS {
    width: 0px;
    height: 50px;
    transition: width 0.3s ease;
    overflow: hidden;
}

#collapsible-checkbox:checked~#stlcollapsible-divS {
    width: 450px;
}


.stlLabelS {
    left: -60px;
    height: 50px;
    width: 60px;
    border-radius: 16px 0px 0px 16px;
    padding: 15px 10px;
}

@keyframes selboxglowPlus {
    0% {
        background: black;
    }

    50% {
        background: rgb(4, 255, 41);
    }

    100% {
        background: black;
    }
}

@keyframes gradientAnimation {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}
.stlcollapseHighlight {
    animation: stlCycleFlash 3s linear infinite;
}

.stltoggleLabel {
    position: absolute;
    /* Position button outside the div */
    top: 50%;
    /* Center the button vertically */
    transform: translateY(-50%);
    background: rgb(35, 24, 120);
    color: white;
    border-radius: 16px 0px 0px 16px;
    padding: 15px 10px;
    cursor: pointer;
    font-family: Arial, sans-serif;
    font-size: 14px;
    text-align: center;
    line-height: 20px;

    border: 1px solid grey;

}

@keyframes stlCycleFlash {
    0% {
        background-color: rgb(255, 255, 255);
        box-shadow: 0px 0px 10px white;
    }

    50% {
        background-color: rgb(20, 20, 73);
    }

    100% {
        background-color: rgb(177, 177, 177);
        box-shadow: 0px 0px 10px white;
    }
}
#basic-nav-dropdown1,
#basic-nav-dropdown2,
#basic-nav-dropdown3 {
	color: #c2c2c2;
}



#basic-nav-dropdown1:hover,
#basic-nav-dropdown2:hover,
#basic-nav-dropdown3:hover {
	color: yellow;
}

.navbar-expand-sm {
	z-index: 1
}

.guestlink {
	display: flex;
}

#guestlink1:hover,
#guestlink2:hover {
	background-color: red;
}

li {
	background-color: #111111;
}

.homeicon {
	width: 80px;
	font-size: 18px;
}

.nav-link:hover {
	transition: .2s;
}

#navgrid {
	display: grid;
	width: 100%;
	grid-template-columns: 75% 25%;
	height: 50px
}


#navschema {
	min-width: 300px;
	display: flex;
	margin: 2px;

}

#navschema_curr {
	font-weight: bold;
	margin-right: 15px;
	vertical-align: bottom;
	margin-top: 5px;
	min-width: 170px;
	overflow-x: hidden;
	overflow-y: hidden;
	white-space: nowrap;
	height: 35px
}

#schemalinknav:hover {
	color: #1df9c2;
	transition: 1s ease-in;
}

#nav_tutorialdiv {
	cursor: pointer;
	color: white;
}
.wrapper {
	display: grid;
	grid-template-columns: 15% 90% 5%;
	grid-column-gap: 20px;
	grid-template-rows: 35% 300px 300px;
	grid-row-gap: 10px;
	font-family: Helvetica;
}

#box_home {
	grid-column: 2/3;
	grid-row: 2/2;
	min-width: 700px;
	width: 60%;
	height: 300px;
	background-color: var(--colorprimary);
	color: white;
	border-radius: 10px;
	box-shadow:
		0px 50px 70px rgba(0, 0, 0, .3),
		0px 10px 10px rgba(0, 0, 0, .1);
	overflow: hidden;
	padding: 5px;
	cursor: pointer;
}

#box_otherside {
	grid-column: 2/3;
	grid-row: 2/2;
	min-width: 700px;
	width: 60%;
	height: 300px;
	background-color: var(--coloraltprimary);
	color: white;
	border-radius: 10px;
	box-shadow:
		0px 50px 70px rgba(0, 0, 0, .3),
		0px 10px 10px rgba(0, 0, 0, .1);
	overflow: hidden;
	padding: 5px;
	cursor: pointer;
}


.box-buffer {
	margin: 10px;
}

.home-tile:hover {
	transform: scale(1.02);
	transition: .4s ease;
}

.reflection {
	grid-row: 3/3;
	grid-column: 2/3;
	min-width: 700px;
	width: 60%;
	background: linear-gradient(to bottom, var(--colorprimary), white 30%);
	height: 300px;
	border-radius: 10px;
	opacity: 70%;
	transform: skew(40deg);
	margin-left: 125px;
}
#titlepg_canvas {
    display: grid;
    grid-template-columns: 35% 500px 25%;
    padding-top: 200px;
    min-height: var(--fullscreenheight);
    height: 950px;
}

#titlepg_logo {
    margin: auto;
}

.titlepg_glowpoint {
    width: 50px;
    height: 50px;
    position: absolute;
    animation: boxglow 2s;
    animation-iteration-count: 5;
}

#titlepg_title {
    animation: titleglow 8s;
    cursor: pointer;
}

#titlepg_subtitle {
    cursor: pointer;
    animation: subtitleglow 8s;
    margin: auto;
    text-align: center;
}


@keyframes boxglow {
    0% {
        box-shadow: 0 0 5px rgba(0, 0, 255, 0.7);
    }

    50% {
        box-shadow: 0 0 50px rgba(188, 248, 10, 0.7);
    }

    100% {
        box-shadow: 0 0 5px rgba(0, 0, 255, 0.7);
    }
}

@keyframes titleglow {
    0% {
        color: rgb(0, 0, 0);
    }

    40% {
        color: rgb(0, 0, 0);
    }

    100% {
        color: rgb(10, 245, 10);
    }
}

@keyframes subtitleglow {
    0% {
        color: rgb(0, 0, 0);
    }

    95% {
        color: rgb(0, 0, 0);
    }

    100% {
        color: rgb(255, 255, 255);
    }
}
.loginContainer {
	display: grid;
	grid-template-columns: 20% 50% 20%;
	grid-column-gap: 20px;
	grid-template-rows: 800px;
	background: linear-gradient(to top, #000000, #270b48);
	font-family: Helvetica;
	min-height: var(--fullscreenheight)
}

#loginbox {
	grid-column: 2/3;
	grid-row: 1/1;
	margin-top: 200px;
	margin-bottom: 200px;
	min-width: 400px;
	background: linear-gradient(-25deg, #350d38 0%, #56367a 100%);
	color: white;
	overflow: hidden;
	border: 1px solid rgb(48, 12, 75);
	border-radius: 10px;
	border: 1px solid rgb(164, 164, 164);
	box-shadow: inset 0px 0px 15px rgb(195, 195, 195), 10px 20px 20px rgba(0, 0, 0, 0.25),
		0 6px 15px rgba(0, 0, 0, 0.22);
	text-align: center;
	z-index: 1;
}

#formbox {
	margin: 0 auto;
	margin-top: 50px;
	letter-spacing: 0.5px;
	line-height: 20px;
	min-width: 300px;
	border: 1px solid #DDDDDD;
	background: linear-gradient(-25deg, #110b1a 0%, #152946 100%);
	text-align: center;
	padding: 3px;
	width: 50%;
	justify-content: center;
	border-radius: 10px;
}

#logintitle {
	letter-spacing: .40rem;
	font-size: 34px;
	margin-top: 50px;
	font-family: system-ui;
}

.log-input {
	background-color: #eee;
	border: none;
	padding: 12px 15px;
	margin: 8px 0;
	width: 100%;
}

.login-button {
	margin: 3px;
	border-radius: 20px;
	border: none;
	background-color: #693dda;
	color: #FFFFFF;
	font-size: 12px;
	font-weight: bold;
	padding: 12px 45px;
	letter-spacing: 1px;
	text-transform: uppercase;
}

.login-button:hover {
	filter: brightness(1.5);
	transition: .6s
}

#signuplinkbox {
	font-size: 10px;
	text-align: left;
}

#login-guest {
	cursor: pointer;
	margin-top: 5px;
	border: 1px solid grey
}

#login-guest:hover {
	background-color: #000000;
	box-shadow: inset 0px 0px 2px rgb(158, 158, 158), 0px 0px 3px aqua;
}
.cascadecontainer {
	perspective: 150px;
	z-index: -1;
}

.frame {
	width: 70%;
	height: 900px;
	position: fixed;
	border-radius: 50px;
	border: 5px #6c7bed solid;
	box-shadow:
		0px 0px 15px #5bdada,
		inset 0px 0px 15px #5bdada,
		0px 0px 50px #5bdada,
		inset 0px 0px 50px #5bdada;
}

.frame:nth-child(1) {
	transform: rotate(10deg) translateY(0px) translateZ(0px);
	opacity: 1.00;
	animation-delay: .1s
}

.frame:nth-child(2) {
	transform: rotate(20deg) translateY(20px) translateZ(-5px);
	opacity: 1.00;
	animation-delay: .3s
}

.frame:nth-child(3) {
	transform: rotate(30deg) translateY(40px) translateZ(-10px);
	opacity: 1.00;
	animation-delay: .5s
}
.logoutContainer {
	display: grid;
	grid-template-columns: 20% 50% 20%;
	grid-column-gap: 20px;
	grid-template-rows: 800px;
	background: linear-gradient(to top, #262d64, #FFFFFF);
	font-family: Helvetica;
	height: var(--fullscreenheight)
}


.logout-button {
	margin: 3px;
	border-radius: 20px;
	border: none;
	background-color: #8770e9;
	color: #FFFFFF;
	font-size: 12px;
	font-weight: bold;
	padding: 12px 45px;
	letter-spacing: 1px;
	text-transform: uppercase;
}

#logoutbox {
	grid-column: 2/3;
	grid-row: 1/1;
	margin-top: 200px;
	margin-bottom: 200px;
	background: linear-gradient(-25deg, #110b1a 0%, #34409a 100%);
	color: white;
	overflow: hidden;
	border: 1px solid rgb(146, 146, 146);
	border-radius: 10px;
	box-shadow: inset 0px 0px 30px rgb(149, 144, 160), 10px 20px 20px rgba(0, 0, 0, 0.25),
		0 6px 15px rgba(0, 0, 0, 0.22);
	text-align: center;
	z-index: 1;
}

.lo_cascadecontainer {
	perspective: 5px;
	z-index: -1;
	height: 0px;
}

.lo_frame {
	top: 100px;
	right: 150px;
	width: 0px;
	height: 0px;
	position: fixed;
	border-radius: 80px;
	border-top: 200px solid transparent;
	border-left: 200px solid transparent;
	box-shadow:
		0px 0px 15px #ce3fcf,
		inset 0px 0px 15px #f4f212,
		0px 0px 50px #f4f212,
		inset 0px 0px 50px #f4f212;
}

.lo_frame:nth-child(1) {
	transform: rotate(20deg) translateY(0px) translateZ(0px);
	opacity: 1.00;
	animation-delay: .1s
}
.lo_frame:nth-child(2) {
	transform: rotate(40deg) translateY(20px) translateZ(-5px);
	opacity: 1.00;
	animation-delay: .3s
}
.lo_frame:nth-child(3) {
	transform: rotate(60deg) translateY(40px) translateZ(-10px);
	opacity: 1.00;
	animation-delay: .5s
}
.signupContainer {
	display: grid;
	grid-template-columns: 20% 50% 20%;
	grid-column-gap: 20px;
	grid-template-rows: 20% 700px;
	background: linear-gradient(to top, #25afaf, #d4dd56);
	font-family: Helvetica;
	height: var(--fullscreenheight)
}

#signupbox {
	grid-column: 2/3;
	grid-row: 2/2;
	margin-bottom: 200px;
	background: linear-gradient(110deg, #b9c1bf 0%, #b3c996 100%);
	overflow: hidden;
	border-radius: 10px;
	box-shadow: 0 10px 20px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
	text-align: center;
	z-index: 1;
}

#signformbox {
	margin: 0 auto;
	margin-top: 70px;
	letter-spacing: 0.5px;
	line-height: 20px;
	border: 1px solid #657e80;
	background: #494949;
	text-align: center;
	width: 50%;
	justify-content: center;
	border-radius: 10px;
}

#signuptitle {
	letter-spacing: .40rem;
	font-size: 38px;
	margin-top: 50px;
	font-family: tahoma;
}

.sign-input {
	background-color: rgb(255, 255, 255);
	border: none;
	padding: 12px 15px;
	margin: 8px 0;
	width: 100%;
}

.signup-button {
	margin: 3px;
	border-radius: 20px;
	border: black solid 1px;
	background-color: #3ddac5;
	color: #000000;
	font-size: 12px;
	font-weight: bold;
	padding: 12px 45px;
	letter-spacing: 1px;
	text-transform: uppercase;
}

.login-button:hover {
	filter: brightness(1.5);
	transition: .6s
}

#signuplinkbox {
	font-size: 10px;
	text-align: left;
}
#empdatacontainer1 {
    display: grid;
    grid-template-columns: 400px 70%;
    grid-template-rows: 700px 400px;
    padding-top: 20px;
    grid-column-gap: 30px;
    grid-row-gap: 10px;
    background: rgb(31, 8, 47);
    background: linear-gradient(27deg, rgba(31, 8, 47, 1) 0%, rgba(0, 0, 0, 1) 70%, rgba(166, 57, 97, 0.833) 100%);
}

#empimportbar {
    grid-column: 1/2;
    grid-row: 1/2;
    margin: 10px 0px 0px 10px;
    background: linear-gradient(-45deg, #20041d, #260955 90%);
    border-radius: 5px;
    overflow: hidden;
    height: 700px;
    box-shadow:
        7px 6px 10px #263c52;
}

#empimportbar_container {
    display: grid;
    grid-template-columns: 50px;
    grid-template-rows: 150px 100px 200px;
}

#empselector {
    background-color: rgb(41, 16, 40);
    grid-column: 2/2;
}

#fieldselector {
    grid-column: 2/2;
}

#empimportbar_header {
    font-size: 18px;
    color: white;
    margin-left: 60px;
}

.empradiobtn {
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.795);
    width: 20px;
    height: 20px;
    transition: .5s ease-in-out;
}

.borderplus {
    border-radius: 12px;
    background: linear-gradient(to right, rgb(214, 233, 47), rgb(204, 77, 221));
    width: 26px;
    height: 26px;
    padding-top: 3px;
    padding-left: 3px;
    margin: auto;
    cursor: pointer;
}

#radio_field {
    grid-row: 2/2;
    grid-column: 1/1;
}

#radio_field2 {
    grid-row: 3/3;
    grid-column: 1/1;
}

.empimportelem {
    margin: 10px 10px 10px 10px;
    border-radius: 5px;
    padding: 10px;
    box-shadow:
        inset 4px 4px 8px #4d3851,
        inset -1px -2px 12px #611d69;
    text-align: center;
    transition: .5s;
}


.empheader {
    font-family: Cambria;
    text-align: center;
    font-size: 20px;
    color: white
}


.empSpace {
    grid-column: 2/2;
    grid-row: 1/2;

    margin: 10px;
    height: 800px;

}

.empsel_ddown {
    width: 250px
}
#empboxMain {
    height: 560px;
}

#cardGrid {
    display: grid;
    grid-template-columns: 30% 60%;
    grid-template-rows: 300px 150px;
}

#empboxtitle {
    grid-column: 1/3;
    grid-row: 1/1;
    color: white;
    font-family: verdana;
    font-size: 19px;
    letter-spacing: .08em;
    word-spacing: .1em;
    margin-left: 10px;
}

#cardmain {
    grid-column-start: 2/3;
    grid-row: 1/1;
    background: linear-gradient(45deg, #20041d, #260955 90%);
    color: white;
    font-size: 14px;
    margin: 15px;
    margin-top: 50px;
    margin-bottom: 20px;
    border-radius: 4px 20px 4px 20px;
    padding: 12px;
    box-shadow:
        5px 3px 10px white,
        -5px -3px 10px white;
}

#cardheader {
    font-family: monospace;
    letter-spacing: .2em;
    background-color: rgba(203, 216, 14, 0.103);
    width: 40%;
    padding-left: 15px;
    margin-bottom: 5px;
}

.arrow {
    margin: 20px;
    border: solid red;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    width: 20px;
    height: 20px;
}

.down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
}


.ddownCard {
    grid-column: 1/1;
    grid-row: 1/2;
    margin-top: 20px;
    margin-left: 20px;
    color: white;
    font-size: 14px;
    width: 160px;
    height: 360px;
}

.datepoint {
    cursor: pointer;
    margin-top: 6px;
    margin-bottom: 3px;
    padding-left: 6px;
}

.ddownMain {
    background: linear-gradient(-45deg, #20041d, #260955 90%);
    border-radius: 00px 0px 10px 10px;
    border: 1px solid black;
    box-shadow:
        inset -1px -1px 10px #1a0d55;
}

#ddownHeader {
    font-size: 17px;
    background: linear-gradient(-45deg, #20041d, #260955 90%);
    border-radius: 0px 50px 0px 0px;
    margin: 0px;
    padding-left: 9px;
    border: 1px solid black;
    letter-spacing: .05em;
    box-shadow:
        inset -1px -1px 3px #333455;
}

.datepoint:hover {
    background-color: rgb(22, 31, 155);
}

.datepoint:click {
    background-color: rgb(155, 22, 22);
    transition: ease-out .3s;
    transition-delay: 1s;
}

#cardsecondary {
    grid-column: 2/2;
    grid-row: 2/2;
    background: linear-gradient(-145deg, #20041d, #260955 90%);
    color: white;
    width: 400px;
    margin-left: 100px;
    border-radius: 10px 10px 10px 10px;
    padding: 5px;
    box-shadow:
        2px 1px 4px white,
        -2px -1px 4px white;
}
#empfieldbox {
    background-color: rgb(61, 37, 82);
    color: white;
    padding: 8px;
    height: 200px;
    width: 720px;
    border-radius: 10px;
    margin: 10px;
    margin-left: 20px;
    box-shadow:
        inset -2px -2px 8px #1f3449,
        inset -2px -2px 4px #38155b;
    transition: 1s;
}

#empfield_subbox {
    display: grid;
    grid-template-rows: 30px 30px 30px 30px;
    grid-template-columns: 300px 300px;
    grid-auto-flow: column;
}

.fieldsub {
    display: grid;
    width: 350px;
    grid-template-columns: 200px 150px;
    background-color: rgb(79, 73, 85);
    color: white;
    margin: 2px;
    margin-left: 5px;
    padding-left: 3px;
    border: 1px solid rgb(121, 121, 121);
    border-radius: 4px;
}
#modal_RuleSel{
    height: 400px;
    width: 300px;
    margin: auto;
}

#explorelink{
    width: 300px;
    background-color: rgb(102, 93, 117);
    height: 50px;
    padding: 10px;
    text-align: center;
    margin-top: 5px;
    font-size: 18px;
    font-family:serif;
    border-radius: 10px;
    color: white;
    text-decoration: none;
}
#explorelink:hover{
    transition: .45s;
    background-color: rgb(66, 69, 255);
}

#modalrule_option1{
    background-color: rgb(231, 225, 230);
    padding: 5px;
    margin-top: 10px;
    width: 300px;
    text-align: center;
}

#selectedrulemodel_div{
    height: 200px;
    background-color: rgb(191, 194, 206);
    padding: 5px;
    border-radius: 4px;
    margin-top: 3px;
    font-family: monospace;
}
.dtrowclass {
    border: 1px solid black;
}

.dtcellclass {
    border: 1px solid black;
    min-width: 30px;
    padding: 1px;
    font-weight: bold;
}

.tbl_rwpgbtn {
    cursor: pointer;
    text-align: center;
}
#bargraph_elempopup {
    background-color: rgb(232, 230, 228);
    padding: 4px;
    border-radius: 4px;
    border: 1px solid black;
}


.tooltipelem_btnclose {
    border: 1px solid blue;
    width: 90%;
    background-color: darkblue;
    color: white;
    margin: 5%
}

.tooltipelem_btnoptn:hover {
    background-color: rgb(178, 137, 217);
}
#egraphContainer {
    display: grid;
    grid-template-columns: 300px 60% 20%;
    grid-template-rows: 40px 800px;
    grid-column-gap: 20px;
    column-gap: 20px;
}

#ge_graphsidebar {
    background-color: var(--colorprimary);
    width: 300px;
    grid-column: 1/1;
    grid-row: 1/1;
    height: 95vh
}

#ge_sidebarbody {
    background-color: var(--colorprimary);
    height: 300px;
}

#ge_properties {
    grid-column: 3/3;
    margin: 3px;
    border: 1px solid black;
    display: block;
}

.ge_prop {
    height: 30px;
    width: 100%;
    font-family: Arial, Helvetica, sans-serif;
    display: flex;
}

#ge_propHeader {
    font-size: 18px;
    font-weight: bold;
}

.ge_propKey {
    width: 150px;
}

.fieldelem {
    height: '40px';
    border: 1px solid black;
    display: flex;
    margin-bottom: 1px;
}

.fieldclick {
    width: 170px;
}

.fieldclick_xy {
    border: 1px solid rgb(146, 32, 32);
    padding: 3px;
    width: 180px;
    margin-left: 10px;
    border-radius: 3px;
    cursor: pointer;
    height: 40px;

}

.fieldclick_xy {
    transition: .3s ease-in;
}

#charttitle {
    margin: auto;
    font-family: Cambria;
    letter-spacing: 1px;
    font-size: 24px;
}

#graphsidebar {
    grid-row: 1/3;
    grid-column: 1/1;
    border: 1px solid black;
    border-radius: 0px 10px 10px 0px;
    margin-right: 10px;
    min-width: 150px;
}

.ge_sidenav {
    display: flex;
    /* Will be hidden on mobile */
    flex-direction: column;
    grid-area: sidenav;
    background-color: #09092c;
}

.ge_sidenav__list {
    padding: 0;
    margin-top: 85px;
    list-style-type: none;
}

.ge_sidenav__list-item {
    padding: 20px 20px 20px 40px;
    color: #ddd;
}

.ge_sidenav__list-item:hover {
    background-color: rgba(255, 255, 255, 0.2);
    cursor: pointer;
}

.header,
.footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    background-color: #000000;
}



#graphsidebarHeader {
    color: blanchedalmond;
    margin-left: 30px;
    margin-top: 10px;
    margin-bottom: 10px;
    letter-spacing: 2.4px;
    width: 200px;
    text-align: left;
    font-size: 18px;
    height: 60px;
    overflow: hidden;
}

.TopElement {
    color: white;
    font-size: 18px;
    padding: 5px;
    height: 50px;
    cursor: pointer;
    margin-top: 3px;
}

.TopElement:hover {
    transition: .4s;
    background-color: rgb(180, 164, 201);
}

#selectedTopElem {
    color: rgb(221, 216, 189);
    text-align: right;
    padding-right: 5px;
    letter-spacing: .1em;
}

#sidebtnCreate {
    background-color: #454e74;
}

.bar {
    border-radius: 3px;
}


#activemodelheader {
    font-size: 18px;
    font-size: bold;
    letter-spacing: 0px;
}

.fldheader {
    margin: auto;
}

.chartclick {
    background-color: rgb(240, 240, 240);
    margin: auto;
    margin-top: 5px;
    margin-bottom: 5px;
    width: 200px;
    padding: 7px;
    border: 1px groove black;
    border-radius: 20px 7px 20px 7px;
    cursor: pointer;
    text-align: center;
}

.textclass1 {
    width: 10px;
    color: red;
}

#mdl_report {
    height: 200px;
}

#mdl_datespread {
    height: 200px;
}

#mdl_rule {
    background-color: rgb(231, 225, 230);
    padding: 5px;
    margin: auto;
    margin-top: 10px;
    margin-bottom: 10px;
    width: 300px;
    text-align: center;
}

.modalemp_header {
    margin: 5px;
    font-family: helvetica;
    letter-spacing: .3em;
}

.modalemp_ddown {
    width: 250px;
    margin: 5px;
}

#mdl_visuals {
    height: 300px;
    width: 600px;
    display: grid;
    grid-template-columns: 25% 75%;
    padding: 5px;
}

.colorpicker {
    width: 120px;
    background-color: rgb(238, 238, 238);
    margin-top: 5px;
    margin-left: 2px;
    border: 1px solid black;
    border-radius: 5px;
    padding-left: 10px;
}

.colorpicker:hover {
    transition: 1.0s;
    box-shadow:
        -1px 1px 10px #48afb3,
        1px 1px 4px #48afb3,
        -1px -1px 4px #48afb3,
        1px 1px 4px #48afb3;
}

#ge_chartgroup {
    display: grid;
    grid-template-rows: 600px 100px;
}


#ge_rundatesel {
    grid-row: 2/2;
    margin: 3px;
    border: 1px solid black;
    display: block;
    width: 50%;
    height: 250px;
    overflow-y: scroll;
    padding: 5px;
    background-color: var(--coloroffwhite);
}

#ge_rundatesel_header {
    font-size: 18px;
    letter-spacing: .05em;
    padding-left: 10px;
}

.ge_rundate_elem {
    cursor: pointer;
    background-color: rgba(13, 49, 61, 0.25);
    margin: 2px;
    width: 80%;
    border: 1px solid black;
    border-radius: 2px 5px 2px 5px;
    padding-left: 2px;
}

.ge_rundate_elem:hover {
    background-color: rgba(13, 49, 61, 0.4);
}


#ge_samplegraph {
    margin-top: 40px;
    background-color: white;
    width: 700px;
    height: 500px;
    border: 2px solid black;
    grid-column: 2/2;
    box-shadow:
        -2px -2px 4px rgb(196, 181, 228),
        2px 2px 4px rgb(196, 181, 228),
        2px -2px 4px rgb(196, 181, 228),
        -2px 2px 4px rgb(196, 181, 228);
}
#rsm_existblock {
    display: grid;
    grid-template-columns: 50% 50%;
    min-width: 900px;
    overflow: hidden;
    height: 850px;
}

.rmn_valitem {
    width: 60%;
    border: 1px solid rgb(81, 81, 81);
    display: flex;
    padding: 2px;
    padding-left: 8px;
}

.rmn_subitm {
    width: 50%
}

#rmn_fieldbox {
    height: 420px;
    margin-top: 10px;
    overflow-y: scroll;
}
.custtable {
    background-color: rgb(222, 222, 222);
    color: black;
}


.paginatebtn {
    color: rgb(240, 78, 78);
    display: flex;
    margin-left: 10px;
    margin-right: 10px;
    cursor: pointer;
}

.paginationdiv {
    padding: 5px;
    color: rgb(242, 221, 254);
    display: flex;
    margin: auto;
    margin-top: 15px;
    height: 40px;
    width: 320px;
    margin-left: auto;

}

.custtbl_cell {
    border: 1px solid black;
    padding: 3px;
    font-size: 16px;
    overflow: hidden;
    min-width: 50px;
}

.custtbl_headercell {
    border: 1px solid black;
    padding: 5px;
}

.celledit {
    cursor: pointer;
    color: rgb(25, 0, 255)
}

#modal_enumName {
    font-size: 20px;
    padding-left: 30px;
}
#tblEnum1 {
    padding: 5px;
}

.rowform_group {
    display: flex;
    margin: 3px;
    background-color: var(--coloroffwhite);
    border: 1px solid black;
    padding-left: 4px;
}

.rowform_name {
    width: 200px;
}

.selRowFormElement {
    width: 275px;
}

.submodelsel {
    width: 200px;
}

.enum_typeddown {
    margin-left: 10px;
    min-width: 120px;
}

#enum_rmvbtn {
    width: 50%;
    margin-left: 20%;
    margin-top: 10px;
    background-color: rgb(242, 251, 225);
}

#enumchange_div {
    height: 30px;
}
.fv_mcddown {
    min-width: 250px;
    border-radius: 4px;
    padding: 2px;
}

#fv_newfldform {
    width: 80%;
    min-width: 1300px;
    display: grid;
    grid-template-columns: 20% 20% 15% 25% 20%;
}
.mt_gridcontainer {
	display: grid;
	grid-template-columns: 200px 1fr;
	grid-template-rows: 10px 1fr 110px;
	grid-template-areas:
		"sidenav header"
		"sidenav main"
		"sidenav footer";
	min-height: var(--fullscreenheight);
	min-width: 900px;
}



#mt_main {
	grid-area: main;
	width: 100%;
	padding: 20px;
	display: grid;
	grid-template-columns: 30% 30% 30%;
}

.mt_footer {
	grid-area: footer;
	background-color: #648ca6;
}

.mt_footer {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 16px;
	background-color: #000000;
}


.mt_sidenav {
	display: flex;
	/* Will be hidden on mobile */
	flex-direction: column;
	grid-area: sidenav;
	background-color: #09092c;
}

.mt_sidenav__list {
	padding: 0;
	list-style-type: none;
}

.mt_sidenav__list-item {
	padding: 20px 20px 20px 40px;
	color: #ddd;
}

.mt_sidenav__list-item:hover {
	background-color: rgba(255, 255, 255, 0.2);
	cursor: pointer;
}

#mt_sidenavheader {
	margin-top: 50px;
	height: 100px;
	color: white;
	margin-left: 20px;
	font-size: 20px;
}

.mt_mainheader {
	display: flex;
	justify-content: space-between;
	margin: 20px;
	padding: 20px;
	height: 150px;
	/* Force our height since we don't have actual content yet */
	background-color: #0a0e16;
	color: rgb(172, 172, 172);
}

.mt_main-overview {
	height: 500px;
	display: grid;
	grid-template-rows: 80px 80px 80px 80px 80px;
	grid-gap: 20px;
	margin: 20px;

}

.overviewCard {
	padding: 20px;
	width: 100%;
	height: 70px;
	display: flex;
}

.overviewsubCard {
	align-items: center;
	justify-content: space-between;
	padding: 20px;
	background: rgb(82, 23, 121);
	background: linear-gradient(27deg, rgba(82, 23, 121, 1) 0%, rgba(159, 85, 124, 1) 26%, rgba(252, 69, 135, 1) 100%);
	box-shadow:
		1px 1px 1px #461648,
		1px 1px 1px #461648,
		-2px -3px 4px #461648,
		7px 5px 14px #461648;
	color: white;
	border-radius: 10px;
	font-family: Helvetica;
	/*animation: opacityOn 1s normal forwards; */
	cursor: pointer;
	transition: .5s ease-out;
	margin-left: 20px;
	width: 80%;
}

.overviewcard__icon {
	font-size: 18px;
}

.overviewcard__info {
	color: rgb(225, 228, 179)
}

.overviewCard:hover {
	width: 110%;
	transition: .7s ease-in;
	transition-delay: 100ms;
}



@keyframes RowOn {
	0% {
		opacity: 0;
	}

	25% {
		opacity: .25;
	}

	50% {
		opacity: .5;
	}

	75% {
		opacity: .75;
	}

	100% {
		opacity: 1;
	}
}



/* fields for header modal */
.t_field {
	display: flex;
	background-color: rgb(165, 181, 227);
	border: 1px dashed rgb(133, 120, 120);
	padding: 2px;
	margin: 1px;
	border-radius: 5px 3px 3px 0px;
}

.t_fielditem {
	color: black;
}

.t_head {
	display: flex;
	background-color: rgb(23, 71, 47);
	border: 1px dashed rgb(133, 120, 120);
	padding: 2px;
	margin: 1px;
	border-radius: 5px 3px 3px 0px;
}

.t_headitem {
	color: white;
	width: 200px;
}

#tmodelreadyform {
	color: white;
	background-color: #10071b;
	font-family: Arial, Helvetica, sans-serif;
	font-size: 1em;
	height: 100px;
}

#tmodelreadyitms {
	margin-left: 45%;
	padding: 3px;
	height: 60px;
}

#tsubmitheader {
	margin-left: 15px;
	letter-spacing: .07em;
}

#tmodelsubmitbtn {
	width: 150px;
	height: 35px;
	background-color: rgb(110, 41, 64);
	color: white;
	border: 2px solid pink;
	border-radius: 5px
}

#tmodelsubmitbtn:hover {
	background-color: rgb(167, 91, 117);
	transition: ease-in .3s;

	box-shadow: 0px 0px 2px #ebe7af;
}

#t_uploadempdiv {
	color: white;
	margin-left: 45%;
}

#checkmodel_link {
	color: white;
}


.mt_modal {
	padding: 5px;
	color: white;
	background-color: black;
	box-shadow: 0px 0px 6px white;
}

.mt_header {
	font-size: 18px;
	letter-spacing: 1px;
	font-weight: bold;
	font-family: Georgia, 'Times New Roman', Times, serif;
	margin-bottom: 3px;
}

.mt_reporttypesel {
	width: 200px;
	height: 40px;
	border-radius: 3px;
	padding: 2px;
	margin: 3px;
}

.mtclosemodalbtn {
	width: 90%;
	margin-left: 5%;
	background-color: #10071b;
	color: white;
	border-radius: 5px;
	border: 3px solid rgba(197, 28, 28, 0.641);
}

.mtclosemodalbtn:hover {
	transition: .3s ease-in;
	background-color: rgba(28, 24, 55, 0.897)
}

.mt_empfilebtn {
	border-radius: 0px 8px 8px 0px;
	border: 1px solid black;
	margin-right: 5px;
	width: 350px;
}

.mt_empsubmitbtn {
	color: white;
	background-color: rgba(149, 149, 149, 0.35);
	border: 2px solid rgb(110, 41, 64);
	width: 110px;
}

.mt_assumebtn {
	margin-top: 3px;
	display: block;
}

.mt_demoquestion {
	padding: 10px;
}

.mt_ruleitem {
	height: "40px";
	font-size: '18px';
	letter-spacing: .02em;
}
#floatdept {
    margin: 1px;
    width: 100vw;
    height: var(--fullscreenheight);
    background: linear-gradient(90deg, rgba(2, 0, 36, .7) 0%, rgba(69, 69, 123, 0.292) 20%);
}

#deptviewheaderbox {
    padding-left: 3px;
    padding-right: 3px;
    padding-top: 2px;
    padding-bottom: 2px;
    background: linear-gradient(90deg, rgba(2, 0, 36, .7) 0%, rgba(69, 69, 123, 0.292) 20%);
    background-blend-mode: darken;
    border: 4px solid black;
}

#deptviewheader {
    color: white;
    height: 70px;
    font-size: 24px;
    letter-spacing: .15em;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    padding: 5px;
}

#dvheading {
    height: 30px;
    font-size: 16px;
    display: flex;
}



.link {
    fill: none;
    stroke: #ccc;
    stroke-width: 1px;
}

#dv_date {
    margin-left: 15px;
}
.empGrid {
    height: 700px;
    width: 100%;
    display: grid;
    grid-template-rows: repeat(5, 20%);
    grid-template-columns: repeat(7, 280px);
    box-shadow: inset;
    font-size: 18px;
    color: white;
}

#dept_empddown {
    padding: 5px;
    font-size: 16px;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

.deptempbox {
    background-color: var(--coloroffwhite);
    width: 250px;
    height: 120px;
    border-radius: 10px;
    border: 1px solid black;
    margin: auto;
    animation: opacityOn 1s normal forwards;
    padding: 6px;
    color: black;
    box-shadow: inset 0px 0px 4px white;
    font-size: 16px;

}

.deptempbox:hover {
    transition: ease-in .5s;
    color: var(--colorsecondary);
    box-shadow:
        -1px 1px 10px var(--colorsecondary),
        1px 1px 4px var(--colorsecondary),
        -1px -1px 4px var(--colorsecondary),
        1px 1px 4px var(--colorsecondary);
}

.deptempbox {
    transition: .5s;
}

@keyframes opacityOn {
    0% {
        opacity: 0;
    }

    25% {
        opacity: .4;
    }

    50% {
        opacity: .4;
    }

    75% {
        opacity: .75;
    }

    100% {
        opacity: 1;
    }
}
#dv_main {
    background: rgba(51, 51, 51, 0.3)
}
#edt_header {
    margin-bottom: 15px;
}

#empdata_div {
    margin-left: 40px;
}
#schemacanvas {
    background-color: black;
    min-height: var(--fullscreenheight);
    min-height: 900px;
    width: 100%;
    display: grid;
    grid-template-columns: 15% 35% 35% 15%;
    grid-column-gap: 10px;
    grid-template-rows: 50px 150px 175px 600px;
    z-index: -2;
    font-family: Arial, Helvetica, sans-serif;
    height: 1000px;
}

#schema_header {
    grid-column: 2/4;
    grid-row: 1/1;
    font-size: 24px;
    color: wheat;
    margin: auto;
}

#schema_newbox {
    display: flex;
    grid-column: 2/2;
    grid-row: 2/2;
}

#schema_inputtxt {
    height: 40px;
}

#schema_infocountbox {
    grid-column: 3/3;
    grid-row: 3/3;
}

#schema_selectbox {
    grid-column: 3/3;
    grid-row: 2/2;
}

#schema_ddown {
    height: 40px;
}

#schema_btnsel {
    height: 40px;
}

.schematool {
    border-radius: 7px 3px 7px 3px;
    padding: 2px;
    background-color: rgb(233, 223, 208)
}

.schemabutton {
    height: 40px;
    background-color: rgb(190, 156, 105);
    border-radius: 3px 7px 3px 7px;
    overflow: hidden;
}


#schema_modeldetailsheader {
    color: white;
    font-size: 20px;
}

#schema_modeldetails {
    grid-column: 3/4;
    grid-row: 4/4;
}

#schema_modeldetailbox {
    padding: 5px;
    border: 1px solid rgb(40, 33, 7);
    overflow-y: scroll;
    height: 260px;
}

.schema_modelentry {
    color: white;
    padding: 3px;
    border-radius: 3px;
    margin: 2px;
}

.schema_modelentry:hover {
    transition: .2s ease-out;
    transition-delay: .08s;
    border: 1px solid blue;
    scale: 1.01;
    box-shadow:
        1px 1px 3px rgb(117, 209, 206),
        -1px -1px 3px rgb(117, 209, 206),
        1px 1px 3px rgb(117, 209, 206),
        -1px -1px 3px rgb(117, 209, 206);
}

.schema_wsitem {
    width: 250px;
    border: 1px solid white;
    height: 35px;
    color: white;
    border-radius: 10px;
    padding: 4px;
    cursor: pointer;
}

.schema_wsitem:hover {
    background-color: rgb(2, 2, 128);
    border: 2px solid rgb(117, 209, 206);
}

.schmgr_btn {
    font-size: 16px;
    height: 70px;
    width: 130px;
    overflow: hidden;
}
.pbody {
    display: absolute;
    grid-row: 3/3;
    grid-column: 2/2;
    justify-content: center;
    align-items: center;
    min-height: 50vh;
}

.ppyramid {
    position: relative;
    width: 200px;
    height: 200px;
    transform-style: preserve-3d;
    transform: rotateY(326deg) rotateX(2deg) rotateZ(359deg);
    animation: rotate 10s linear infinite;
}

.pside {
    width: 0;
    height: 0;
    position: absolute;
    opacity: 0.7;
    border: 100px solid transparent;
    border-bottom: 200px solid rgba(230, 226, 226, 0.608);
    border-top: 0px;
    animation: colrchange 60s linear infinite;
}

.pbase {
    position: absolute;
    width: 100%;
    height: 100%;
    transform: translateY(73px) rotateX(90deg);
    border: 0;
    background: blue;

}

.pone {
    transform: rotateX(30deg);
    transform-origin: 0 0;
}

.ptwo {
    transform-origin: 100px 0;
    transform: rotateY(90deg) rotateX(-30deg);
    border-bottom-color: rgb(133, 133, 133);
}

.pthree {
    transform-origin: 100px 0;
    transform: rotateY(270deg) rotateX(-30deg);
    border-bottom-color: rgb(227, 222, 225);
}

.pfour {
    transform-origin: 100px 0;
    transform: rotateY(0) rotateX(-30deg);
    border-bottom-color: rgb(251, 251, 246);
}


@keyframes rotate {
    from {
        transform: rotateY(0) rotateX(0deg) rotateZ(10deg);
    }

    to {
        transform: rotateY(360deg) rotateX(00deg) rotateZ(10deg);
    }
}

@keyframes colrchange {
    0% {
        border: 100px solid transparent;
        border-bottom: 200px solid rgba(230, 226, 226, 0.608);
        border-top: 0px;
    }

    10% {
        border-bottom: 200px solid rgba(45, 204, 210, 0.61);
        border-top: 0px solid transparent;
    }

    20% {
        border-bottom: 200px solid rgba(230, 226, 226, 0.608);
        border-top: 0px solid transparent;
    }

    30% {
        border-bottom: 200px solid rgba(212, 20, 20, 0.649);
        border-top: 0px solid transparent;
    }

    40% {
        border-bottom: 200px solid rgba(230, 226, 226, 0.608);
        border-top: 0px solid transparent;
    }

    50% {
        border-bottom: 200px solid rgba(57, 215, 57, 0.363);
        border-top: 0px solid transparent;
    }

    60% {
        border-bottom: 200px solid rgba(230, 226, 226, 0.608);
        border-top: 0px solid transparent;
    }

    70% {
        border-bottom: 200px solid rgba(123, 186, 215, 0.363);
        border-top: 0px solid transparent;
    }

    80% {
        border: 100px solid transparent;
        border-bottom: 200px solid rgba(230, 226, 226, 0.608);
        border-top: 0px solid transparent;
    }

    90% {
        border: 100px solid transparent;
        border-bottom: 200px solid rgba(221, 207, 58, 0.595);
        border-top: 60px solid transparent;
    }

    100% {
        border: 100px solid transparent;
        border-bottom: 200px solid rgba(230, 226, 226, 0.608);
        border-top: 20px solid transparent;
    }
}
#fm_header {
    background: linear-gradient(90deg, rgb(0, 0, 0) 70%, rgb(42, 42, 42) 100%);
    border: 2px solid rgb(149, 149, 149);
    padding-left: 5px;
    box-shadow: 4px 4px 5px black;
}


#fm_body {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-column-gap: 20px;
    column-gap: 20px;
    margin-top: 20px;
    height: "800px"
}

#filtexisting_canvas {
    height: 750px;
}


#flduniquelist {
    height: 600px;
    overflow-y: scroll;
    overflow-x: hidden;
}

.fm_fldunique_itm {
    border: 1px solid rgb(63, 63, 63);
    width: 90%;
    font-size: 15px;
    padding: 1px;
    padding-left: 4px;
    margin: 2px;
    overflow-x: hidden;
    cursor: pointer;
}

#fm_selmdl {
    min-height: 100px;
    min-width: 300px;
}

#fm_selmdlheader {
    background-color: rgb(31, 31, 31);
    padding: 4px;
    font-size: 18px;
}

.fltmgitm {
    font-size: 15px;
}

#fm_filtddown1 {
    margin-top: 2px;
    margin-right: 5px;
}
#dxb_canvas1 {
    background-color: var(--coloroffwhite);
    height: var(--fullscreenheight);
    color: black;
}

#dxb_header {
    height: 100%;
    border: 1px solid black;
    display: flex;
    background-color: var(--colorprimary);
}


#dxb_mainheader {
    font-size: 32px;
    color: white;
}

#dxb_resize {
    width: 300px;
    color: white;
    display: flex;
    margin-left: 20px;
}

.dxb_resize_form {
    display: flex;
    margin-left: 20px;
}

.dxb_res_tag {
    margin-left: 10px;
    margin-right: 10px;
    letter-spacing: .1em;
}


#dxb_circle {
    display: absolute;
    background: linear-gradient(55deg, rgb(66, 31, 92) 0%, rgb(20, 11, 36) 100%);
    border-radius: 50%;
    margin-left: 30%;
    margin-top: 30px;
    height: 500px;
    width: 500px;
    top: 100px;
    z-index: -1;
    box-shadow: 3px 3px 10px rgb(38, 12, 40);
    animation: boxglow 5s;
    animation-iteration-count: 15;
}

@keyframes boxglow {
    0% {
        box-shadow: 3px 3px 10px rgb(38, 12, 40);
    }

    10% {
        box-shadow: 3px 3px 10px, -3px -3px 20px rgb(158, 39, 174);
    }

    40% {
        box-shadow: 3px 3px 10px, -3px -3px 10px rgb(80, 22, 88);
    }

    75% {
        box-shadow: 3px 3px 30px, -3px -3px 10px rgb(138, 63, 148),
            -3px 1px 10px, -3px 1px 10px rgb(102, 53, 108);
    }

    85% {
        box-shadow: 3px 3px 30px, 1px -3px 10px rgb(92, 35, 100);
    }

}


#dxb_graphsparent {
    background: linear-gradient(145deg, var(--colorprimary) 50%, var(--colorfade1) 100%);
    display: grid;
    overflow: hidden;
    grid-template-columns: 250px 1200px 200px;
    grid-row-gap: 20px;
    row-gap: 20px;
    color: white
}

.dxb_sidepane {
    background: linear-gradient(-55deg, #110b1a 0%, #152946 100%);
    width: 350px;
    padding: 10px;
    border: 2px solid rgb(180, 180, 180);
    border-radius: 5px;
}


#dxb_managebtndiv {
    background-color: var(--colorprimary);
    color: var(--coloroffwhite);
    padding: 5px;
    width: 220px;
    margin-left: 150px;
}

#dxb_savediv {
    margin-right: 10%;
    height: 40px;
    color: white;
}

.dxb_controlbtn {
    border: 1px solid aqua;
    border-radius: 10px;
    padding: 3px;
    background-color: var(--colorprimarybright);
    color: aqua;
    width: 100px;
}

.dxb_savebtn {
    border-radius: 10px;
    height: 35px;
    padding: 3px;
    background-color: var(--colorprimarybright);
    color: aqua;
    border: 1px solid rgb(0, 255, 179);
    width: 200px;
    font-size: 18px;
}

.dxb_savebtn:hover {
    transition-delay: .1s;
    transition: .3s ease-out;
    box-shadow: 0px 0px 15px rgb(0, 255, 179);
}

#dxb_inc {
    width: 50px;
    margin: auto;
    margin-left: 20px;
}

/* move buttons */
#dxb_movebtndiv {
    display: block;
    width: 200px;
}

.dxb_movebtnsub {
    margin: auto;
    display: flex;
}

.dxb_movebtn {
    height: 30px;
    width: 110px;
    margin: auto;
}

#dxb_rowsXcols {
    display: flex;
    padding: 3px;
}

/* mini queries */
#dxb_miniquery_container {
    display: block;
    margin-top: 5px;
    margin-left: 5px;
    height: 700px;
    overflow-y: scroll;
}

.dxb_miniquery {
    color: white;
    width: 120px;
    height: 100px;
    border: 1px solid white;
    padding: 5px;
    cursor: pointer;
    overflow: hidden;
}

.miniquery:hover {
    box-shadow: 0px 0px 5px inset white;
}

#dxb_newdash {
    position: fixed;
    margin-left: 100px;
    margin-top: 200px;
}

#dxb_graphs {
    background-color: var(--coloroffwhite);
    border: 3px solid black;
    border-radius: 0px;
    overflow-y: scroll;
}

.dxb_btnstack {
    display: block;
    margin-right: 10px;
}

.dbmg_graph {
    display: block;
    grid-row: inherit;
    grid-template: inherit;
    height: 200px;
    color: white;
    border-radius: 15px;
    padding: 10px;
    overflow: hidden;
    resize: both;
    cursor: move;
    position: fixed;
    border: 2px solid black;
    box-shadow: 2px 2px 4px black;
}

.dm_rditm {
    border: 1px solid var(--colorsecondary);
    border-radius: 5px;
    width: 200px;
    height: 40px;
    padding: 5px;
    margin-top: 5px;
    cursor: pointer;
}

.dm_page {
    width: 120px;
    background-color: white;
    color: black;
    padding: 3px;
    border-radius: 0px 10px 10px 0px;
    height: 50px;
    margin-top: 15px;
    cursor: pointer;
}

.dxb_filteritem {
    border: 1px solid white;
    width: 200px;
    padding: 3px;
    margin-top: 3px;
    cursor: pointer;
}

.dxb_svitm {
    width: 150px;
    border: 1px solid black;
    border-radius: 12px;
    padding: 5px;
    padding-left: 15px;
    background-color: #42428b;
    color: rgb(243, 246, 206);
}

.dxb_sdate {
    border: 1px solid rgb(111, 111, 111);
    border-radius: 10px;
    margin: 3px;
    padding: 4px;
    font-size: 14px;
    cursor: pointer;
    background: black;
}

.dxb_rmvbtn {
    border: 1px solid white;
    border-radius: 5px;
    background-color: black;
    color: white;
    box-shadow: inset 0px 0px 15px rgb(138, 15, 15);
}
#livedashboard {
    display: flex;
    width: 100%;
    height: 100%;
    background: linear-gradient(140deg, #fcfcfc 0%, #f3f3f3 100%);
}

#dashheader {
    display: flex;
    width: 100%;
    height: 80px;
    background-color: #111111;
    color: white;
}

#dv_asofdate_ddown {
    margin-left: 15px;
    margin-top: 5px;
}

.livegraph_itm {
    display: block;
    position: absolute;
    padding: 5px;
}



.dashddownflt {
    width: 200px;
}

#dashsidebar {
    height: 100%;
    width: 10px;
    background-color: black;
    color: white;
    transition: .5s;
    overflow: hidden;
    display: flex;
}

.dash_sdbr_kpiitem {
    border: 1px solid white;
    border-radius: 5px;
    margin: 4px;
    padding: 3px;
    padding-left: 5px;
    cursor: pointer;
}

.dash_sdbr_kpiitem:hover {
    background-color: #646464;
}

.dsh_pg {
    height: 35px;
    width: 120px;
    overflow-x: hidden;
    color: white;
    background-color: grey;
    margin: 3px;
    border-radius: 5px;
}

.dv_modalwide {
    min-width: 800px;
}

.dv_sidebtn {
    border-radius: 5px;
    border: 1px solid grey;
    min-width: 100px;
}

#dvdivtbl>tr>td {
    padding: 2px;
}
.dnp_note {
    display: flex;
}

#dnp_notedata {
    border: 1px solid white;
    width: 90%;
    height: 600px;
    display: block;
    padding: 2px;
    overflow-y: scroll;
}

.dnp_noterow {
    width: 100%;
    height: 100px;
}

.dnp_noteitm {
    background-color: aliceblue;
    color: black;
    padding: 5px;
    border-radius: 10px;
    border: 1px solid black;
    width: 45%;
}

.dnp_otheruser {
    float: left;
    text-align: left;
}

.dnp_curruser {
    float: right;
    text-align: right;
    cursor: pointer;
}

.dnp_curruser:hover {
    transition: .3s ease-out;
    background-color: rgb(177, 213, 248);
    box-shadow: 0px 0px 4px rgb(255, 255, 200);
}
#dashsel_main {
    display: grid;
    grid-template-columns: 60% 40%;
    min-width: 850px;
}

.dashsel_dbobj {
    height: 100px;
    width: 400px;
    margin: 5px;
    border: 2px solid rgb(192, 192, 192);
    border-radius: 5px;

    letter-spacing: .1em;
    padding: 5px;
    color: black;
    cursor: pointer;
}

.dashsel_dbobj:hover {
    transform: translate(3px, 3px);
    box-shadow: -3px -3px 5px black;
}
#graphcreate_canvas {
    display: grid;
    grid-template-columns: 50% 48%;
    grid-template-rows: 200px 750px;
    grid-row-gap: 15px;
    row-gap: 15px;
    grid-column-gap: 20px;
    column-gap: 20px;
    height: var(--fullscreenheight);
    min-height: 850px;
}

#gcc_header {
    grid-row: 1/1;
    grid-column: 1/3;
}

#graphcreate_topline {
    width: 100%;
    height: 60px;
    display: flex;
    margin-top: 10px;

}

#gc_save {
    background-color: black;
    border: 1px solid red;
}

.gc_save:hover {
    box-shadow: -1px 1px 1px red,
        1px 1px 1px red,
        -1px -1px 1px red,
        1px 1px 1px red;
}

.gc_minibutton {
    background: rgb(72, 158, 244);
    background: linear-gradient(228deg, rgba(72, 158, 244, 0.667) 0%, rgba(50, 61, 198, 0.612) 100%);
    padding-top: 8px;
    height: 45px;
    padding-bottom: 5px;
    padding-left: 20px;
    margin-left: 20px;
    color: white;
    font-size: 17px;
    width: 170px;
    border-radius: 20px;
    border: 1px solid yellow;
    cursor: pointer;
}


.gc_minibutton:hover {
    transition: .2s ease-out;
    transform: translate(-2px, -2px);
    box-shadow: -2px 2px 3px var(--colorsecondary),
        2px 2px 3px var(--colorsecondary),
        -2px -2px 3px var(--colorsecondary),
        2px 2px 3px var(--colorsecondary);
}

#graphcreate_workspace {
    height: 800px;
    display: grid;
    grid-template-columns: 40% 60%;
}

#graphcreate_buckets {
    height: 500px;
}

.gc_bucket {
    margin: 10px;
    margin-top: 20px;
    overflow: hidden;
    border-radius: 15px;
    padding: 10px;
    font-size: 18px;
    box-shadow: -1px 1px 4px #9592bf,
        1px 1px 4px #65655f,
        -1px -1px 4px #65655f,
        1px 1px 4px #65655f;
    cursor: pointer;
}

.gcbucket1 {
    height: 180px;
    width: 250px;
}

.gcdate1 {
    height: 150px;
    width: 300px;
}

.gc_bucket:hover {
    transition: .2s ease-out;
    transform: translate(-2px, -2px);
}

.graphmodal {
    height: 500px;
}

#gc_bucketelem5 {
    height: 100px;
}

#gclabel {
    font-size: 20px;
    margin: 5px;
}

#gclabel_chg {
    border: 1px solid blue;
    border-radius: 2px;
    padding: 2px;
}

.gc_mtblmap {
    display: flex;
    padding: 2px;
    margin: 2px;
    border: 1px solid lightgray;
}

.gc_mtblddown {
    width: 120px;
}

.gc_mtblhead {
    width: 100px;
}

.gc_mtblname {
    padding: 2px;
    color: #65655f
}

.gc_gen_ddown {
    width: 200px;
    height: 30px;
    background-color: black;
    color: white;
    padding: 2px;
    border: 1px solid rgb(246, 247, 201);
}

.gc_modalbackground {
    background-color: black;
    color: white;
    width: 100%;
}

#gc_colorpickmdl {
    height: 300px;
    width: 100%;
    display: grid;
    grid-template-columns: 25% 75%;
    padding: 5px;
}

#gc_coloroptnseldiv {
    width: 300px;
}

#gc_datepickprops {
    color: black;
    background-color: white;
    width: 155px;
}

#gc_colorselbody {
    overflow-y: scroll;
    overflow-x: hidden;
    width: 200px;
}

#gc_swatches {
    margin-left: 60px;
}

.gc_colorsel {
    width: 30px;
}

#gc_samplegraph {
    margin-top: 30px;
    background-color: white;
    width: 500px;
    height: 500px;
    border: 2px solid black;
    box-shadow:
        -2px -2px 4px rgb(196, 181, 228),
        2px 2px 4px rgb(196, 181, 228),
        2px -2px 4px rgb(196, 181, 228),
        -2px 2px 4px rgb(196, 181, 228);
    grid-column: 2/2;
    grid-row: 2/2;
    margin-left: 7%;
}

.gc_df_itm {
    display: flex;
}

#gc_bucket1canvas {
    display: grid;
    grid-template-columns: 50% 50%;
}

#gc_savebtn {
    margin: 10px;
    margin-left: 50px;
    color: white;
    border: 1px solid rgb(46, 24, 117);
    border-radius: 5px;
    box-shadow: inset 0px 0px 8px white, 0px 0px 10px rgb(104, 12, 147);
    width: 150px;
    height: 35px;
    background: linear-gradient(45deg, rgb(20, 9, 150) 40%, rgb(39, 11, 132) 100%);
}

#gc_savebtn:hover {
    transition: .4s;
    background: linear-gradient(45deg, rgb(14, 8, 79) 20%, rgb(67, 8, 138) 80%);
}

.gc_smfltitm {
    border: 1px solid white;
    margin: 3px;
    padding: 3px;
    border-radius: 5px;
    max-width: 250px;
}

.gc_smfltitm:hover {
    background-color: rgb(10, 10, 77);
}

.gc_modelinfobox {
    border: 1px solid rgb(190, 190, 190);
    border-radius: 5px;
    padding: 4px;
    margin: 3px;
    margin-bottom: 6px;
    background-color: #0c0720;
}
/* #pm_canvas {
    background: radial-gradient(18% 28% at 18% 71%, #FFFFFF59 6%, #073AFF00 100%),
        radial-gradient(70% 53% at 36% 76%, #73F2FFFF 0%, #073AFF00 100%),
        radial-gradient(42% 53% at 15% 94%, #FFFFFFFF 7%, #073AFF00 100%),
        radial-gradient(31% 43% at 7% 98%, #FFFFFFFF 24%, #073AFF00 100%),
        radial-gradient(21% 37% at 12% 21%, #CBDB9F9C 5%, #073AFF00 100%),
        radial-gradient(35% 56% at 91% 74%, #8A4FFFF5 9%, #073AFF00 100%),
        radial-gradient(74% 86% at 67% 38%, #AC264EF5 9%, #073AFF00 100%),
        linear-gradient(125deg, #4EB5FFFF 1%, #4C00FCFF 100%);
    height: var(--fullscreenheight)
} */

#pm_top {
    height: 200px;
    width: 50%;
    margin-left: 20px;
    border: 1px solid white;
    padding: 5px;
    margin-bottom: 30px;
    background: linear-gradient(170deg, rgb(82, 72, 72) 60%, rgb(51, 51, 51) 100%);
    ;
}

#pm_topboxheader {
    margin-bottom: 15px;
    margin-left: 15px;
}

#pm_topboxheader_sub {
    font-size: 22px;
    font-weight: bold;
    margin-left: 15px;
}

#pm_mdltypddown {
    height: 35px;
    width: 150px;
    border: 1px solid black;
    font-size: 20px;
    margin-left: 15px;
}

#pm_main {
    display: grid;
    width: 100%;
    grid-template-columns: 35% 65%;
    grid-template-rows: 800px;
    grid-column-gap: 20px;
    column-gap: 20px;
    background-size: 100% 100%;
    padding-right: 20px;


}


#pm_blockusr {
    display: block;
    padding: 15px;
    margin-left: 30px;
    margin-top: 5px;
    margin-bottom: 20px;
    background-size: 100% 100%;
    background-position: 0px 0px;
    border-radius: 5px;
    box-shadow:
        3px 3px 8px #c2c9d2,
        -3px -3px 8px #c2c9d2,
        3px 3px 8px #dee2e7,
        inset 0px 0px 12px rgb(16, 16, 16);

    background: linear-gradient(30deg, rgb(82, 72, 72) 60%, rgb(51, 51, 51) 100%);
    overflow: hidden;
}


.pm_userselect:hover {
    transition: .2s;
    background-color: rgb(246, 249, 186);
}


#pm_lstusers {
    grid-column: 1/1;
    padding: 8px;
    margin-top: 35px;
    border-radius: 5px;
    height: 80%;
    width: 90%;
}


#dpm_ddown {
    width: 310px;
    height: 40px;
    border-radius: 3px;
}

.pm_btn {
    width: 130px;
    height: 40px;

}

#pm_sel1 {
    width: 200px;
    height: 30px;
    border-radius: 5px;
    padding: 3px;
    margin-right: 5px;
    margin-left: 5px;
}

.pm_btn {
    background-color: rgb(251, 229, 173);
    border-radius: 15px;
}

.pm_btn:hover {
    transition: .3s ease-in;
    background-color: rgb(255, 211, 99);
}

#pm_newperm {
    display: block;
}

.pm_newpermitm {
    height: 30px;
    overflow: hidden;
    display: flex;
}


#pm_currentperms {
    grid-column: 1/1;
}

#pm_permbubble {
    grid-column: 2/2;
    display: flex;
}


#pm_blockmgperms {
    width: 60%;
    height: 400px;
    display: grid;
    grid-template-rows: 20% 80%;
    margin-left: 60px;
    margin-top: 10px;
    padding: 10px;
    padding-left: 30px;
    border-radius: 10px;
    box-shadow:
        3px 3px 8px #c2c9d2,
        -3px -3px 8px #c2c9d2,
        3px 3px 8px #dee2e7,
        inset 0px 0px 12px rgb(16, 16, 16);
    background: linear-gradient(-30deg, rgb(82, 72, 72) 60%, rgb(51, 51, 51) 100%);
    overflow: hidden;
}

#pm_setperm {
    width: 90%;
    height: 90%;
    display: block;
    grid-row: 2/2;
    border-radius: 10px 15px 10px 15px;
    padding: 10px;
    font-size: 19px;
}


.pm_userselect {
    width: 95%;
    height: 50px;
    border: 1px solid black;
    border-radius: 10px;
    margin-top: 4px;
    padding: 5px;
    overflow: hidden;
}


.pm_permexisting {
    height: 40px;
    width: 90%;
    overflow: hidden;
    border: 1px solid black;
    margin: 3px;
    padding: 2px;
    border-radius: 5px;
    color: white;
    cursor: pointer;
    font-size: 20px;
}

.pm_permexisting:hover {
    filter: brightness(130%)
}

.pm_existprmitm {
    height: 28px;
}
.settrk_avitem {
    width: 120px;
}
#gm_canvas {
    width: 100%;
    display: grid;
    grid-template-columns: 25% 70%;
    margin-left: 10px;
    opacity: .95;
    max-height: var(--fullscreenheight);
    height: 800px;
}

#listqueries_mdl {
    width: 100%;
    min-width: 180px;
    overflow-x: hidden;
}


#gm_subcanvas {
    display: grid;
    grid-template-rows: 100px 800px;
    grid-template-columns: 60% 40%;
    grid-row-gap: 50px;
    row-gap: 50px;
}

#gm_dashbox {
    grid-column: 2/2;
}

#querylistdiv {
    opacity: .9;
    overflow: hidden;
    padding: 3px;
}

#qrylst_header {
    padding: 3px;
}

#qrylst_graphheader {
    font-size: 18px;
}

#qrylst_subheader {
    font-size: 14px;
    display: flex;
}

#gm_qrylist_body {
    height: 450px;
    margin-top: 10px;
    margin-left: 5px;
    overflow-y: scroll;
    font-size: 16px;
}

.qm_itm {
    cursor: pointer;
    overflow: hidden;
    border-radius: 8px;
    margin: 10px;
    padding: 5px;
}

.qm_qrylist_itm {
    grid-column: 1/1;
    border: 1px solid var(--colorprimary);
    height: 60px;
    min-width: 200px;
    width: 80%;
    background-color: rgb(7, 6, 37);
}

#gm_gridoptbox {
    display: grid;
    grid-template-rows: 550px 200px;
}

#qrymgr_dashheader {
    font-size: 16px;
    font-weight: bold;
    padding: 5px;
    letter-spacing: .1em;
    margin-left: 20px;
}

.qm_dashlist_itm {
    grid-column: 2/2;
    border: 1px solid rgb(145, 24, 24);
    height: 60px;
    width: 200px;
}

#qrymgr_switchcanvas {
    margin-top: 60px;
}

.qrymgr_header1 {
    font-size: 26px;
    color: rgb(169, 169, 243)
}


.qrymgr_assign {
    background: rgb(215, 244, 222);
}

.qrymgr_unassign {
    background: rgb(245, 212, 248);
}

.qrymgr_switchitm {
    display: flex;
}

.qrymgr_switchheader {
    margin: auto;
    display: block;
    margin-top: 10%;
}

#qrymgr_switchitm {
    margin: auto;
}

#qrymgr_confirmbtn {
    width: 40%;
    height: 40px;
    border-radius: 5px;
    background: rgb(85, 72, 98);
    color: white;
    margin-left: 30%;
}

#qrymgr_confirmbtn:hover {
    transition: .3s ease-out;
    background: rgb(255, 250, 110);
    color: black;
    box-shadow: 2px 2px 3px yellow,
        -2px 2px 3px yellow,
        2px -2px 3px yellow,
        -2px -2px 3px yellow;
}


#qm_visdetail {
    padding: 10px;
    min-height: 10vh;
    height: 20%;
    margin-top: 20px;
    overflow: hidden;
    box-shadow: 0px 0px 5px rgb(157, 157, 157);
    border-radius: 5px;
}

#gm_visdetailinner {
    height: 250px;
    overflow-y: scroll;
}

.qm_qryoption {
    margin-left: 5px;
}

.qm_qryoptionbtn {
    height: 50px;
    width: 100%;
    background-color: black;
    color: white;
    border-radius: 10px;
    border: 1px solid darkblue;
    padding: 5px;
}

.qm_qryoptionbtn:hover {
    transition: .5s ease-out;
    background-color: rgb(34, 34, 110);
}

.qm_closebtn {
    margin-left: 5%;
    height: 30px;
    width: 90%;
    color: white;
    background-color: rgb(6, 6, 69);
}

.qm_enterbtn {
    margin: auto;
}


#gm_viewseldiv {
    margin-left: 25px;
    display: block;
    padding: 4px;
    min-height: 100px;
    grid-column: 1/3;
}

#gm_viewdivheader {
    padding: 10px;
    margin-left: 20px;
    margin-right: 10px;
    font-size: 18px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    width: 550px;
    height: 75px;
}

.qm_viewbtn {
    width: 100px;
    height: 35px;
    font-size: 16px;
    border-radius: 5px;
    margin-left: 2px;
    background-color: var(--colorsecondary);
    display: block;
}

.qm_viewbtn:hover {
    background-color: bisque;
    transition-delay: .2s;
    transition: .3s ease-out;
}


.gm_viewlink {
    width: 50%;
}

#gm_qryrunboxheader {
    font-size: 17px;
    letter-spacing: .2em;
}

#gm_qryrundates_div {
    height: 220px;
    overflow-y: scroll;
}

.gm_qryrd_sel {
    width: 80%;
    border-radius: 5px;
    padding: 3px;
    margin-top: 5px;
    margin-bottom: 5px;
    margin-left: 10px;
    cursor: pointer;
    opacity: .8
}

.gm_viewoption {
    opacity: .9;
    height: 200px;
    width: 300px;
    margin-left: 100px;
    padding: 20px;
    background-color: var(--coloraltprimary);
    box-shadow: 3px 4px 5px rgb(177, 237, 182),
        -3px -4px 5px rgb(90, 90, 202);
}

.gm_viewoption_body {
    height: 245px;
}

.gm_qryrd_sel:hover {
    transition: .3s ease-out;
    transition-delay: .1s;
    opacity: 1;
}


#gm_qryrunbtn_popup {
    min-width: 150px;
    width: 50%;
    margin-left: 25%;
    background-color: rgb(22, 22, 85);
    color: white;
}

#gmrundate_header {
    border: 2px solid rgb(90, 90, 202);
    border-radius: 3px;
    margin: 3px;
    padding: 3px;
}

.gm_reldateOpt {
    height: 80px;
    border: 1px solid black;
    border-radius: 5px;
    margin: 5px;
    margin-left: 40px;
    padding: 5px;
}


#gm_rdrundiv {
    padding: 5px;
    margin: auto;
}

#gm_rdrunbtn {
    background-color: rgb(155, 234, 234);
    width: 70px;
    margin-top: 5px;
    margin-bottom: 5px;
}

#gm_rdtextarea {
    width: 450px;
}

#gm_detfldbox {
    height: 180px;
    margin: 2px;
    margin-top: 7px;
    border: 1px solid black;
    font-size: 14px;
    overflow-y: scroll;
    box-shadow: 1px 1px 3px rgb(90, 90, 202),
        -1px -1px 3px rgb(90, 90, 202);
}

.gm_detflddiv {
    border: 1px solid rgb(245, 125, 125);
    border-radius: 4px 6px 6px 4px;
    margin: 3px;
    padding: 2px;
    padding-left: 5px;
    cursor: pointer;
    height: 25px;
}

#gm_rdnotes {
    height: 80px;
}

.gm_dashddown {
    width: 230px;
    overflow-x: hidden;
    margin-left: 15px;
    height: 35px;
    background-color: var(--coloroffwhite);
}

#gm_runcanvas {
    display: grid;
    grid-template-columns: 70% 30%;
}

#gm_rundateinfoBox {
    height: 260px;
    width: 250px;
    padding: 15px;
    border-radius: 5px;
}

.gm_qvisitem {
    height: 40px;
    border: 1px solid white;
    border-radius: 10px;
    padding: 5px;
    cursor: pointer;
    background-color: rgb(41, 12, 57);
}

.datatbl_header{
    border:2px solid black;
}


#datatbl{
    border:2px solid black;  
}


.datatbl_td {
    border: 1px solid black;
}


#datatbl_pagination{
    display:flex;
    padding: 2px;
}

.datatbl_paginatebtn{
    margin: 5px;
}

#datatbl_paginatePg{
    margin: 5px;
}
#qm_modelviewcanvas {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 500px 70%;
    grid-column-gap: 10px;
    column-gap: 10px;
}

#qmvr_qryrdbox {
    height: 80%;
    min-height: 400px;
    width: 95%;
    overflow: hidden;
}

.qmvr_qryrunsel {
    margin: 5px;
    padding: 2px;
    padding-left: 5px;
    border-radius: 6px;
    border: 1px solid rgb(37, 47, 125);
    width: 90%;
    height: 30px;
    background-color: rgb(7, 3, 31);
    cursor: pointer;
}

.qmvr_qryrunsel:hover {
    background-color: rgb(20, 16, 67);
    box-shadow: 0px 0px 5px rgb(25, 199, 225);
}

#qmvr_leftbox {
    grid-column: 1/1;
}


#qmvr_qrydetailbox {
    height: 200px;
    width: 95%;
    overflow-x: hidden;
    overflow-y: scroll;
}

.qmvr_visitem {
    height: 30px;
    width: 40%;
    min-width: 120px;
    max-width: 400px;
    margin: 4px;
    padding: 4px;
    border-radius: 5px;
    border: 1px solid rgb(92, 88, 98);
    cursor: pointer;
    background-color: rgb(39, 14, 52);
}

.qmvr_visitem:hover {
    background-color: rgb(57, 19, 86);
    box-shadow: 0px 0px 7px rgb(138, 84, 223);
}

#qmvr_multibtn {
    font-size: 15px;
    height: 30px;
    margin-left: 10px;
}
#cc_popup {
    position: absolute;

}
#qmvv_main {
    display: grid;
    grid-template-columns: 40% 50%;
    grid-template-rows: 250px 500px;
    grid-row-gap: 20px;
    row-gap: 20px;
    grid-column-gap: 40px;
    column-gap: 40px;
    min-height: 850px;
}


#qmvv_mainleft {
    grid-row: 1/3;
}

#qmvv_header {
    display: grid;
    grid-template-columns: 70% 30%;
}

.qmvv_qryitem {
    width: 300px;
    border: 1px solid rgb(111, 111, 111);
    cursor: pointer;
    margin-top: 8px;
    padding: 3px;
    background-color: var(--colorprimary);
    margin-left: 5px;

}

.qmvv_mainitem {
    font-size: 20px;
    border: 1px solid rgb(61, 22, 138);
    margin: 5px;
    padding: 3px;
    box-shadow: 0px 0px 3px white;
    cursor: pointer;

}

.qmvv_mainitem:hover {
    box-shadow: 0px 0px 7px white;
}

.qmvv_list {
    height: 350px;
    padding: 10px;
}

.qmvv_subheader {
    font-size: "22px";
    letter-spacing: ".1em";
}

.qmvv_chartddown {
    font-size: 20px;
    width: 200px;
    height: 35px;
    border-radius: 3px;
    padding: 2px;
}

#qmvv_chartbox {
    margin: auto;
    border: 5px solid black;
    border-radius: 10px;
    background-color: white;
    grid-column: 2/2;
    grid-row: 2/2;
}

.qmvv_coloritm {
    display: flex;
    color: white;
}

#qmvv_colorbox {
    height: 180px;
    overflow-y: scroll;
}

.qmvv_item {
    border: 1px solid grey;
    padding: 3px;
    width: 50%;
    min-width: 120px;
    margin: 5px;
    background-color: rgb(30, 30, 30);
}
#qmvi_canvas {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: 550px 200px;
}

#qmvi_header {
    grid-column: 1/3;
    grid-row: 1/1;
}
#qmvs_canvas {
    display: grid;
    grid-template-columns: 50% 50%;
}

#qmvs_scheddatesbox {
    width: 80%;
    height: 500px;
    overflow-y: scroll;
}

.qmvs_scheditm {
    margin-top: 3px;
    border: 1px solid white;
    border-radius: 10px;
    padding: 5px;
    box-shadow: inset 0px 0px 15px rgb(0, 0, 0);
    background: linear-gradient(70deg, var(--coloraltprimary) 30%, rgb(57, 8, 8) 100%);
}

.qmvs_scheddate {
    display: flex;
    cursor: pointer;
    min-width: 400px;
    width: 90%;
}
#qmv_header {
    height: 120px;
    padding-left: 30px;
    padding-top: 10px;
}

.qmv_btnopt {
    margin-left: 5px;
    height: 40px;
    width: 140px;
}
.qmv3d_ditm {
    display: flex;
    border: 1px solid grey;
    margin: 3px;
    padding: 2px;
    padding-left: 5px;
    background-color: rgb(148, 148, 148);
}

.qmv3d_dsubitm {
    min-width: 100px;
    width: 20%;
}

.qmv3d_ditm:hover {
    background-color: rgb(31, 52, 121);
    box-shadow: 0px 0px 5px yellow;
}
#snagraph1 {
    margin-left: 10px;
    box-shadow: 0px 0px 15px blueviolet;
    border: 2px solid blue;
    border-radius: 5px;
}

#npg_mainbox {
    display: grid;
    grid-template-columns: 1250px 500px;
}
#ndm_mdl_main {
    display: grid;
    grid-template-columns: 50% 50%;
    height: 400px;
}

.ndm_mdlbox {
    overflow-y: scroll;
    font-size: 17px;
}

.ndm_attrflditm {
    border: 1px solid white;
    border-radius: 6px;
    padding: 3px;
    padding-left: 5px;
    margin: 3px;
    min-width: 200px;
    width: 30%;
    cursor: pointer;

}
#cse_header {
    font-size: 20px;
}

#cse_itmlist {
    font-size: 16px;
    color: var(--colorprimary);
    height: 600px;
    overflow-y: scroll;

}

#cse_flduniquelist {
    margin-left: 20px;
    margin-top: 20px;
    min-width: 200px;
    width: 60%;
}

.cse_fltitm {
    display: flex;
    cursor: pointer;
}

.cse_flditmrow:hover {
    background-color: var(--colorhighlight);
}


.cse_fltitmval {
    width: 60%;
    color: white;
    overflow-x: hidden;
}


.cse_flditm_color {
    min-width: 150px;
    background-color: white;
    width: 20%;
    margin-right: 10px;
}

#cse_fldddown {
    width: 250px;
}
#cmm_body {
    display: grid;
    grid-template-columns: 30% 30% 40%;
    grid-column-gap: 20px;
    column-gap: 20px;
    grid-row-gap: 20px;
    row-gap: 20px;
}

#cmm_upper {
    font-size: 16px;
    margin-bottom: 20px;
    height: 160px;
}

#cmm_header {
    font-size: 24px;
    letter-spacing: .1em;
    height: 50px;
}

#cmm_viewbox {
    display: flex;
    width: 550px;
    margin-left: 30px;

}


#mcs_csddown {
    height: 30px;
    width: 250px;
}

#cmm_modelbox {
    grid-column: 3/3;
}

#cmm_newmodeldiv {
    background-color: var(--coloraltprimary);
    color: white;
    width: 45%;
    min-width: 700px;
    display: flex;
    margin-left: 30px;
    padding: 20px;
    border-radius: 20px;
    box-shadow: 2px 2px 5px var(--colorprimarybright),
        1px 0px 5px var(--colorsecondary);
}

#cmm_existmodeldiv {
    height: 50%;
    min-height: 400px;
    width: 70%;
    background-color: var(--colorprimary);
    color: white;
    margin: 60px;
    padding: 20px;
    border-radius: 20px;
    box-shadow: 2px 2px 12px var(--colorprimarybright),
        5px 5px 12px var(--colorsecondary);
}

#cmm_varbox {
    grid-column: 2/2;
    grid-row: 1/1;
    padding: 10px;
    margin-left: 40px;
    margin-top: 100px;
}

.cmm_optn {
    height: 200px;
    width: 300px;
    padding: 30px;
    border: 2px solid black;
    border-radius: 30px;
    background-color: var(--coloraltprimary);
    box-shadow: 2px 2px 5px var(--colorprimarybright),
        1px 0px 5px var(--colorsecondary);
}

#cmm_assignbox {
    grid-row: 1/1;
    grid-column: 1/1;
    height: 600px;
    width: 400px;
}

#cmm_modelselbox {
    height: 70%;
    padding-left: 10px;
    padding-top: 4px;
    margin-top: 4px;
    overflow-y: scroll;
}

.cmm_setdiv {
    background-color: var(--coloraltprimary);
    width: 220px;
    height: 30px;
    color: white;
    margin-top: 5px;
    border: white;
    border-radius: 5px;
    padding: 3px;
    cursor: pointer;
}

.cmm_setdiv:hover {
    transition: .3s ease-out;
    transition-delay: .1s;
    box-shadow: 1px 2px 2px yellow,
        -1px 2px 2px yellow,
        1px -2px 2px yellow,
        -1px -2px 2px yellow;
}

#cmm_csddown {
    height: 40px;
}
:root {
    --fullscreenheight: 95vh;

    --colorprimary: rgb(19, 9, 36);
    --colorsecondary: rgb(100, 215, 225);
    --coloraltprimary: rgb(36, 28, 47);
    --coloraltprimarysub: rgb(22, 13, 29);
    --colorhighlight: rgb(252, 252, 166);
    --coloroffwhite: rgb(236, 249, 254);
    --colorprimarybright: rgb(87, 42, 170);
    --colorfade1: rgba(2, 0, 36, .7);
    --colorfade2: rgba(69, 69, 123, 0.292);
    --coloraccent: rgba(4, 255, 41, 1)
}



.react-date-picker__calendar--open {
    position: absolute;
}

.react-date-picker__calendar--closed {
    position: absolute;
}
.upg_box {
    background-color: var(--coloroffwhite);

}

#upg_userstat {
    height: 400px;
    width: 400px;
    margin: 10px;
    border-radius: 10px;
    padding: 10px;
    background-color: rgba(240, 248, 255, 0.844);
    border: 5px solid rgb(112, 13, 13);
    box-shadow:
        1px 1px 3px black;
}

.usrpg_itm {
    font-size: 18px;
    font-weight: 400;
}

.upg_userrep {
    cursor: pointer;
    border: 1px solid rgb(130, 130, 130);
    border-radius: 3px;
    padding: 2px;
}

#upg_body {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: 50% 50%;

}

#upg_canvas {
    height: var(--fullscreenheight);
    background-image: url('https://static.pexels.com/photos/414171/pexels-photo-414171.jpeg');
    background-size: cover;
    animation: slidein 100s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-direction: alternate;
}

@keyframes slidein {
    from {
        background-position: top;
        background-size: 3000px;
    }

    to {
        background-position: -100px 0px;
        background-size: 2750px;
    }

}
#tp_header {
    display: block;
    height: 150px;
    padding: 10px;
}

#tp_headeroptns {
    height: 35px;
    display: flex;
}

#tp_ddown1 {
    margin-left: 10px;
    margin-right: 10px;
}

.tp_datarow {
    display: flex;
}


.tp_tblcell {
    border: 1px solid grey;
    width: 200px;
    overflow: hidden;
    height: 40px;
    padding: 1px;
}

.tp_headercell {
    border: 3px solid black;
    width: 200px;
    overflow: hidden;
    height: 40px;
    padding: 5px;
    font-weight: bold;
}
.ndt_canvas {
    background-image: linear-gradient(140deg, var(--colorprimary) 50%, rgb(0, 0, 0) 100%);
    min-height: var(--fullscreenheight);
    width: 100%;
    color: white;
    padding: 10px;
    font-family: Cambria;
    font-size: 16px;
    overflow-x: hidden;
}

.ndt_grid {
    grid-row-gap: 15px;
    row-gap: 15px;
    grid-column-gap: 20px;
    column-gap: 20px;
}

#ncanvas {
    display: grid;
    grid-template-columns: 30% 70%;
    grid-template-rows: 400px 400px;

}

.ndt_header {
    background-image: linear-gradient(-25deg, #110b1a 0%, #152946 100%);
}

.ndt_gridbox {
    background-color: rgba(101, 101, 101, 0.145);
    box-shadow: inset -2px 2px 15px black,
        inset 2px 2px 25px black,
        inset -1px -1px 4px var(--colorsecondary),
        inset 1px 1px 4px var(--colorsecondary),
        2px 2px 3px rgb(30, 30, 30);
    padding: 10px;
    padding-left: 15px;
    overflow: hidden;
}

.ndt_innerbox {
    margin: 10px;
    padding: 5px;
    box-shadow: -2px 2px 3px black,
        0px 0px 20px black,
        inset 0px 0px 3px rgb(141, 141, 141),
        2px 2px 3px rgb(30, 30, 30);
    background-color: var(--coloraltprimary);
    overflow-x: hidden;
}

.ndt_subinner {
    margin: 10px;
    padding: 5px;
    box-shadow: -2px 2px 3px black,
        0px 0px 10px black,
        0px 0px 3px rgb(141, 141, 141),
        inset 2px 2px 3px rgb(30, 30, 30);
    background-color: var(--coloraltprimarysub);
    overflow-x: hidden;
}

.ndt_title1 {
    font-size: 48px;
    letter-spacing: .1em;
}

.ndt_title2 {
    font-size: 32px;
    letter-spacing: .1em;
}

.ndt_title3 {
    font-size: 26px;
    letter-spacing: .08em;
}

.ndt_title4 {
    font-size: 22px;
    letter-spacing: .06em;
}


.ndt_item {
    box-shadow: 0px 0px 3px var(--colorsecondary);
    color: white;
    border-radius: 10px;
    padding: 3px;
    padding-left: 5px;
    cursor: pointer;
    margin: 3px;
    margin-top: 5px;
    background-color: rgb(15, 15, 15);
    overflow: hidden;
}

.ndt_item:hover {
    background: linear-gradient(40deg, var(--colorsecondary) 70%, rgb(0, 0, 0) 100%);
    color: black;
    transition: .2s ease-in;
}

.ndt_dropdown {
    height: 32px;
    padding: 2px;
    border: 1px solid black;
    border-radius: 5px;
    font-size: 17px;
}

.ndt_btnmain {
    border: 1px solid rgb(142, 142, 142);
    color: rgb(118, 216, 227);
    background-color: rgb(18, 8, 70);
    border-radius: 8px;
    padding: 2px;
    padding-left: 6px;
    padding-right: 6px;
    height: 35px;
    overflow: hidden;
}

.ndt_btnmain:hover {
    transition: .3s;
    transition-delay: .05s;
    background-color: rgb(54, 29, 78);
    box-shadow: 0px 0px 6px var(--colorsecondary);
}

.ndt_btn1 {
    border: 1px solid rgb(90, 88, 88);
    color: var(--colorsecondary);
    background-color: black;
    border-radius: 8px;
    padding: 2px;
    padding-left: 6px;
    padding-right: 6px;
    height: 32px;
    overflow: hidden;
    font-size: 16px;
}

.ndt_btn1:hover {
    transition: .2s;
    transition-delay: .05s;
    background-color: rgb(29, 29, 29);
    box-shadow: 0px 0px 3px var(--colorsecondary);
}

.ndt_btn2 {
    border: 1px solid rgb(174, 42, 42);
    color: var(--colorsecondary);
    background-color: rgb(62, 18, 66);
    border-radius: 8px;
    padding: 2px;
    padding-left: 6px;
    padding-right: 6px;
    height: 32px;
    overflow: hidden;
    font-size: 16px;
}

.ndt_btn2:hover {
    transition: .2s;
    transition-delay: .05s;
    background-color: rgb(108, 24, 24);
    box-shadow: 0px 0px 3px var(--colorsecondary);
}

.ndt_btn3 {
    border: 1px solid rgb(119, 216, 255);
    color: white;
    background-color: rgb(60, 12, 94);
    border-radius: 8px;
    padding: 2px;
    padding-left: 6px;
    padding-right: 6px;
    height: 35px;
    overflow: hidden;
}

.ndt_btn3:hover {
    transition: .2s;
    transition-delay: .05s;
    background-color: rgb(39, 24, 108);
    box-shadow: inset 0px 0px 3px whitesmoke;
}

.ndt_modal {
    background: rgb(39, 36, 52);
    color: white;
    border: 3px solid var(--colorsecondary);
    box-shadow: 0px 0px 10px white;
    padding: 10px;
    border-radius: 3px;
}


.closemodalbtn {
    width: 90%;
    margin-left: 5%;
    margin-top: 10px;
    background-color: #394263;
    color: white;
}

.closemodalbtn:hover {
    transition: .1s;
    background-color: #293563;
}

.datepick_class {
    background-color: white;
    color: black;
}

.ndt_chart {
    overflow-x: scroll;
    padding: 2px;
}

.modalwide {
    min-width: 600px;
}

.modalwide2 {
    min-width: 800px;
}

.ndt_modaltitle {
    font-size: 20px;
    letter-spacing: .03em;
}

.ndt_tab1 {
    background-color: black;
    border: 1px solid rgb(79, 79, 97);
    border-radius: 5px 5px 0px 0px;
    color: white;
    margin-right: 1px;
}


/* scrollbars */
/* width */
::-webkit-scrollbar {
    width: 10px;

}

/* Track */
::-webkit-scrollbar-track {
    background: #160f27;
    border: 2px solid rgb(17, 16, 89);
    opacity: .3;
    box-shadow: inset 0px 0px 3px white;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: rgb(9, 2, 27);
    border: 1px solid rgb(13, 132, 49);
    border-radius: 4px;
    opacity: .5;
    box-shadow: inset 0px 0px 6px white;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: rgb(30, 6, 61);


}

.ndt_btn1:active::before {
    animation: clockwise-border 1s linear forwards;
}

/* Keyframes for clockwise border animation */
#mgf_grpbox {
    width: 40%;
}

#mgf_fldbox {
    height: 500px;
    width: 40%;
}

.mgf_grpitm {
    font-size: 16px;
    border: 1px solid black;
    overflow: hidden;
    height: 80px;
    background-color: rgb(25, 1, 39);
    padding: 5px;
    border: 1px solid grey;
    border-radius: 5px;
    margin-top: 4px;
}

.mgf_flditm {
    border: 1px dotted rgb(56, 56, 56);
    margin: 3px;
    padding-left: 4px;
    display: flex;
    cursor: pointer;
}

.gsm_numinput {
    width: 100px;
}

#gsnm_rulebox {
    overflow-y: scroll;
    height: 400px;
}

#gsm_fvalbox {
    height: 400px;
    overflow-y: scroll;
}
#metr_header {
    height: 150px;
    width: 90%;
    background-image: linear-gradient(-25deg, #110b1a 0%, #152946 100%);
    padding: 10px;
    padding-left: 30px;
    font-size: 18px;
    overflow: hidden;
}

#metr_subheader {
    display: grid;
    grid-template-columns: 35% 35% 30%;
}


#metr_metddown {
    width: 300px;
}

.metr_ddown {
    width: 270px;
    font-size: 16px;
    border-radius: 5px;
    padding: 3px;
}

.metr_box {
    padding: 30px;
    width: 30%;
    min-width: 400px;
    overflow: hidden;
    margin: 10px;
    height: 210px;
}

.metr_box_wide {
    padding: 30px;
    min-width: 700px;
    overflow: hidden;
}

#metr_savebox {
    background-image: linear-gradient(-25deg, #110b1a 0%, #152946 100%);
}


#mm_measurebox {
    display: grid;
    grid-template-columns: 50% 50%;
    min-width: 500px;
    padding: 15px;
}

#mm_mdlsbox {
    overflow-y: scroll;
}
#tmgr_canvas {
    display: grid;
    grid-template-columns: 300px 80%;
    grid-template-rows: 270px 650px;
    grid-row-gap: 20px;
    row-gap: 20px;
    grid-column-gap: 20px;
    column-gap: 20px;
}

#tmgr_sidebar {
    grid-row: 1/3;
    grid-column: 1/1;
}


#tmgr_tableddown {
    font-size: 20px;
    width: 250px;
    border: 1px solid blue;
    border-radius: 4px;
    padding: 2px;
}

.dt_head {
    min-width: 160px;
}

.dt_head {
    min-width: 950px;
}

.dt_field {
    min-width: 950px;
}

.dt_fielditem {
    min-width: 160px;
}

#tmgr_hdrbtn {
    width: 200px;
    margin-top: 5px;
}

.tmgr_uplitm {
    width: 200px;
    border: 1px solid #959595;
    padding: 4px;
    border-radius: 2px;
    cursor: pointer;
}

.tmgr_sidebtn {
    height: 60px;
    color: rgb(254, 254, 192);
    margin-top: 15px;
    cursor: pointer;
    margin-left: 10px;
}

#tmgr_across {
    grid-column: 2/2;
}

#tmgr_uplhistgrid {
    display: grid;
    grid-template-columns: 30% 60%;
}

.tmgr_keyflditem {
    display: flex;
    width: 80%;
    min-width: 250px;
    border: 1px solid rgb(126, 126, 126);
    margin: 3px;
    padding: 3px;
    background-color: rgb(19, 4, 34);
}
#conn_tblmain {
    width: 1500px;
    display: grid;
    grid-template-columns: 500px 500px 500px;
    height: 500px;
}

.conn_fldlist {
    overflow-y: scroll;
    width: 450px;
    padding: 5px;
}

.conn_existconnitem {
    width: 300px;
    border: 1px solid white;
    border-radius: 5px;
    padding: 3px;

}

.conn_existconnitemValid {
    cursor: pointer;
}

.conn_existconnitemValid:hover {
    background: rgb(42, 31, 100);
    box-shadow: 0px 0px 5px yellow;
}

.cmgr_missrow {
    display: flex;
    border: 1px solid black;
    width: 450px;
}

.cmgr_scroll {
    overflow-y: scroll;
}

.cmgr_missitm {
    width: 200px;
}

.cmgr_itmrow {
    display: flex;
    background-color: rgb(56, 43, 68);
    border: .5px solid rgb(173, 172, 172);
    margin-top: 3px;
}

#cmgr_checkbox {
    overflow-x: hidden;
}

.cm_tbltbn {
    margin-left: '5px'
}
.sch_block {
    height: 150px;
    margin-left: 5px;
}

#sch_main {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: 500px 100px;
}

.sch_name {
    width: 150px;
}
#eav_canvas {
    height: var(--fullscreenheight)
}

#eav_auditbtn {
    width: 180px;
    height: 40px;
    background: linear-gradient(40deg, rgb(103, 8, 6) 40%, rgb(91, 34, 114) 80%);
    color: white;
    border: 2px solid blue;
    border-radius: 15px;
    font-size: 19px;
    letter-spacing: .1em;
}

#eav_main {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-column-gap: 20px;
    column-gap: 20px;
    grid-template-rows: 800px;
}

.eav_auditrow {
    display: flex;
}

.eav_audititm {
    overflow: hidden;
}
#dmgrtable_canvas {
    display: grid;
    grid-template-columns: 20% 80%;
    grid-template-rows: 250px 750px;
    grid-row-gap: 20px;
    row-gap: 20px;
    grid-column-gap: 20px;
    column-gap: 20px;
}


#dmgrtbl_sidebar {
    grid-row: 1/3;
    grid-column: 1/1;
}

.dmgrtbl_sidebtn {
    height: 60px;
    color: rgb(254, 254, 192);
    margin-top: 10px;
    cursor: pointer;
}

#dmgrtbl_across {
    grid-column: 2/2;
}

.dt_uploadline {
    display: flex;
    margin-top: 10px;
}

#dmgrsubmitbtn {
    margin-top: 20px;
}

#dptupload_form {
    width: 900px;
    display: grid;
    grid-column: 600px 300px;
}

#div_dmgrreturn {
    height: 30px;
}

.dt_field {
    display: flex;
    width: 600px;
    padding: 5px;
    border: 1px solid rgb(141, 141, 141);
}

.dt_head {
    display: flex;
    width: 600px;
    padding: 5px;
    border: 1px solid rgb(141, 141, 141);
}

.dt_fielditem {
    width: 165px;

}

.dt_headitem {
    width: 165px;
}

#dmgr_botbtn {
    color: white;
}

#dmgr_uplhistmain {
    display: grid;
    grid-template-columns: 30% 70%;
}

.dmgr_newvalitm {
    border: 1px solid #777777;
    padding: 3px;
    width: 50%;
    min-width: 300px;
    border-radius: 3px;
    margin: 2px;
    background-color: #200333;
}
#empsim_main {
    display: grid;
    grid-template-columns: 50% 50%;
    min-width: 800px;
}

.empsim_parambox {
    width: 400px;
    display: flex;
}

.empsim_paramtitle {
    width: 200px;
}

.empsim_fldbtn {
    border: 1px solid grey;
    border-radius: 5px;
    margin: 3px;
    width: 170px;
    overflow: hidden;
    padding: 2px;
    padding-left: 5px;
}

.empsim_fldbtn:hover {
    background-color: var(--colorprimarybright);
}

.empsim_date {
    background-color: black;
    color: white;
    padding: 5px;
    border-radius: 4px;
    width: 200px;
    height: 70px;
}

.sim_datarow {
    display: flex;
}

.sim_dataitm {
    width: 200px;
}
.bmm_stepbox {
    height: 700px;
    background-color: rgb(11, 6, 15);
    padding: 25px;
    min-width: 500px;
}

.bmm_uniqueitm {
    width: 20%;
    min-width: 200px;
}

.bmm_valueitm:hover {
    background-color: rgb(18, 23, 81);
    box-shadow: 0px 0px 5px white;
}


.bmm_flditm {
    display: flex;
}

.bmm_flditm:hover {
    background-color: rgb(35, 43, 127);
    box-shadow: 0px 0px 2px white;

}

#bmmm_checkcolsbox {
    display: grid;
    grid-template-columns: 50% 50%;
}

.bmm_fldsubitm {
    width: 50%;
    border: 1px solid grey;
    padding: 2px;
}

.bmm_bfldrow {
    display: flex;
    border: 1px solid grey;
    padding: 3px;
}

.bmm_bfldsubrw {
    width: 40%;
    min-width: 120px;
}

.bmm_metritm {
    border: 1px solid grey;
    padding: 3px;
    width: 200px;
}

.bmm_existsetitm {
    cursor: pointer;
    background-color: black;
    margin: 3px;
    padding: 2px;
    border: 1px solid rgb(56, 56, 56);
}

.bmm_existsetitm:hover {
    background-color: rgb(42, 35, 73);
}

.bmm_fldbtn {
    border: 1px solid grey;
    padding: 3px;
    border-radius: 5px;
    width: 210px;
    background-color: rgb(12, 17, 42);
}

.bmm_opt {
    cursor: pointer;
}

.bmm_rdobj {
    border: 1px solid grey;
    margin: 3px;
    padding: 3px;
    cursor: pointer;

}

.bmm_topbtn {
    width: 100px;
}

.bmm_kpiitm {
    display: flex;
    border: 1px solid grey;
    border-radius: 5px;
    margin: 3px;
    padding: 3px;
    width: 400px;
    cursor: pointer;
    background-color: rgb(10, 9, 37);
}

.bmm_kpiitm:hover {
    background-color: rgb(24, 21, 123);
    box-shadow: 0px 0px 6px yellow;
}

.bmm_kpiitm_dt {
    width: 120px;
}

.bmm_kpiitm_val {
    width: 100px;
}
#kpimgr_main {
    display: grid;
    grid-template-columns: 400px 50%;
    min-width: 800px;
}

.kpim_showval {
    border: 1px solid grey;
    margin: 3px;
    padding: 2px;
    background-color: black;
}

.kpim_inputtag {
    width: 150px;
}

.kpim_inputval {
    width: 70px;
}

.kpim_valitm {
    display: grid;
    grid-template-columns: 90px 80px 80px 80px;
}
#vtx_grid {
    display: grid;
    grid-template-columns: 50% 50%;
}
.lvm_fltitm {
    border: 1px solid grey;
}

.lvm_kpiitm {
    min-width: 120px;
}
.ldvw_box {
    background-color: rgb(245, 243, 240);
    padding: 5px;
    border: 1px solid rgb(239, 231, 212);
    margin: 5px;
    border-radius: 4px;
    min-width: 150px;
}

.ldvw_valitm {
    border: 1px solid black;
    padding: 3px;
    margin: 4px;
    border-radius: 8px;
    min-width: 120px;
    width: 90%;
    background-color: white;
}

.ldvw_valitm:hover {
    background-color: rgb(171, 221, 221);
}

#ldvw_grid {
    display: grid;
    grid-template-columns: 40% 60%;
    min-width: 800px;
}

.ldvw_valsubitm {
    margin-left: 5px;
}

.ldvw_right {
    float: right;
    text-align: right;
}

.ldvw_optbtn {
    width: 120px;
    border: 1px solid black;
    border-radius: 8px;
    height: 35px;
    margin: 5px;
    margin-top: 15px;
}

#lv_divchart {
    background: white;
}

#ldvw_chartwrap {
    background: linear-gradient(to right, rgb(243, 247, 205), rgb(255, 181, 165));
    padding: 3px;
    border-radius: 8px;
}
.jdmitm {
    display: grid;
    border: 1px solid rgb(151, 151, 151);
    border-radius: 5px;
    margin: 3px;
    background-color: rgb(24, 4, 35);
    grid-template-columns: 20% 60% 20%;
}

.jdmitmhead {
    display: grid;
    border: 1px solid rgb(151, 151, 151);
    border-radius: 5px;
    margin: 3px;
    background-color: rgb(60, 50, 66);
    grid-template-columns: 20% 60% 20%;
}

.jdmsubitm {
    margin-left: 10px;
    border: 1px solid rgb(48, 48, 48);
}

#jdm_termsbox {
    background-color: rgb(8, 8, 60);
    padding: 3px;
    padding-right: 6px;
    border: 1px solid grey;
    margin: 3px;
    margin-left: 10px;
}
#jdng_main {
    display: grid;
    grid-template-columns: 900px 50%;
}

.jdng_lkitm {
    display: grid;
    grid-template-columns: 25% 15% 60%;
    border: 1px solid black;
    border-radius: 4px;
    margin: 4px;
    padding: 3px;
    background-color: rgb(216, 236, 234);
}

.jdng_lksubitm {
    padding-left: 10px;
    border-left: 1px solid grey;
    min-width: 120px;
}

.jdng_lkitm_cat {
    margin-left: 5px;
}

.jdng_lkitm:hover {
    background-color: aqua;
}

#jdng_legend {
    display: grid;
    grid-template-columns: 30% 30% 30%;
    grid-column-gap: 15px;
    grid-template-rows: repeat(30px);
    margin-top: 5px;
}

.jdng_skillitm {
    border: 1px solid grey;
    border-radius: 5px;
    padding: 3px;
    margin: 2px;
    display: flex;
}


.jdng_pathsubitm {
    margin-left: 5px;
}


.jdng_mdlcat {
    background-color: #152946;
    border: 1px solid grey;
    padding: 3px;
}
#jsp_main {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-column-gap: 10px;
}

.jsp_block {
    min-width: 450px;
}

.jsp_skl {
    margin: 3px;
    height: 55px;
    overflow: hidden;
    border: 1px solid grey;
    display: flex;
}

.jsp_sklcat {
    width: 130px;
    border: 1px solid grey;
    padding: 3px;
    background-color: rgb(39, 27, 12);
}

.jsp_skldesc {
    padding: 3px;
}
.empdet_histitm {
    background-color: rgb(54, 30, 62);
    margin-top: 5px;
    padding: 2px;
    border-radius: 5px;
    border: 1px solid rgb(79, 79, 79);
}

.empdet_histitm:hover {
    background-color: rgb(104, 41, 125);
    box-shadow: 0px 0px 5px blue;
}

.empdet_currcatitm,
.empdet_simjobitm {
    padding: 1px;
}

.empdet_currcatitm:hover {
    background-color: rgb(104, 41, 125);
}

.empdet_simjobitm:hover {
    background-color: rgb(104, 41, 125);
}

.empdet_skillitm {
    border: 1px solid grey;
    margin: 2px;
    padding: 5px;
    height: 60px;
    overflow: hidden;
    background-color: rgb(29, 7, 49);
    font-size: 14px;
}

.empdet_card {
    background-color: rgb(31, 26, 55);
    padding: 6px;
}
#ndttutorial_div {
    position: absolute;
    height: 310px;
    width: 320px;
    background: rgb(214, 212, 224);
    color: black;
    padding: 4px;
    left: 75%;
    top: 60px;
    border: 2px solid rgb(3, 37, 17);
    box-shadow: 5px 5px 10px rgb(130, 129, 129), -2px -2px 5px rgb(130, 129, 129), inset 0px 0px 3px black;

}


@keyframes cycle-bg-1 {
    0% {
        background-color: rgb(255, 255, 255);
        box-shadow: 0px 0px 20px white;
    }

    50% {
        background-color: rgb(20, 20, 73);
    }

    100% {
        background-color: rgb(177, 177, 177);
        box-shadow: 0px 0px 10px white;
    }
}

.seldivtutorial {
    animation: cycle-bg-1 3s linear infinite;
}
.jpv_requireitm {
    display: flex;
    width: 900px;
    border: 1px solid grey;
    height: 60px;
    border-radius: 5px;
    padding: 3px;
    padding-left: 6px;
    background-color: rgb(26, 5, 5);
    margin: 3px;
    cursor: pointer;
}

.jpv_requireitm:hover {
    background-color: rgb(55, 32, 78);
    box-shadow: inset 0px 0px 10px white,
        0px 0px 9px rgb(59, 176, 185);
}

.jpv_aplitm {
    display: flex;
    border: 1px solid grey;
    height: 30px;
    border-radius: 5px;
    padding: 3px;
    padding-left: 6px;
    background-color: rgb(6, 5, 26);
    margin-top: 2px;
    cursor: pointer;
}

.jpv_aplskl {
    border: 1px solid rgb(201, 209, 165);
    height: 60px;
    border-radius: 5px;
    padding: 3px;
    padding-left: 6px;
    background-color: rgb(12, 17, 2);
}

#jpv_postcard {
    margin-top: 5px;
    background-color: rgb(27, 21, 53);
    border: 1px solid white;
    border-radius: 5px;
    padding: 4px;
    box-shadow: 0px 0px 13px rgb(61, 40, 224), inset 0px 0px 5px white;
}

#jpv_importgrid {
    display: grid;
    grid-template-columns: 45% 45%;
}

#jpv_viewgrid {
    display: grid;
    grid-template-columns: 65% 35%;
}

#jpv_skillsmain {
    background-color: rgb(11, 11, 52);
    border: 1px solid grey;
    box-shadow: inset 0px 1px 5px white;
    padding: 10px;
    height: 480px;
}

#jpv_skillsbox {
    display: grid;
    grid-template-columns: 40% 50%;
}

#jpv_skillssub {
    display: grid;
    grid-template-columns: 30% 40% 30%;
}

.jpvoptbtn {
    width: 140px;
}
.cmai_quesitem {
    border: 1px solid grey;
    border-radius: 5px;
    background: linear-gradient(40deg, black 30%, var(--colorsecondary) 100%);
    padding: 3px;
    margin: 4px;
    cursor: pointer;
}

.cmai_quesitem:hover {
    background: linear-gradient(40deg, rgb(84, 48, 183) 30%, rgb(198, 198, 198) 100%)
}

.cmai_ansitem {
    border: 1px solid grey;
    border-radius: 5px;
    background: black;
    padding: 3px;
    margin: 4px;
}
#aim_selblockBorder {
    padding: 2px;
    background: linear-gradient(140deg, rgb(4, 255, 41) 60%, rgb(8, 165, 170) 90%);
    border-radius: 100px;
    animation: selboxglowPlusX 8s;
    animation-iteration-count: 15;
}

#aim_selblock {
    background: linear-gradient(140deg, rgb(42, 25, 52) 60%, rgb(71, 7, 65) 90%);
    height: 300px;
    width: 450px;
    padding: 50px;
    border-radius: 100px;
    animation: selboxglow 8s;
    animation-iteration-count: 15;
}

.aim_classasst {
    background-color: black;
    border: 1px solid grey;
    border-radius: 5px;
}

.aim_classuser {
    background-color: rgb(34, 8, 8);
    border: 1px solid grey;
    border-radius: 5px;
}

#aim_modelddown {
    width: 200px;
}

#aim_chatddown {
    margin: 4px;
}

#aim_modelheader {
    width: 900px;
    background: linear-gradient(40deg, rgb(20, 7, 31) 70%, rgb(0, 0, 0) 100%);
    border: 2px solid rgb(59, 59, 59);
    border-radius: 2px 5px 5px 2px;
    padding: 3px;
}

.aim_branchitm {
    width: 140px;
    height: 35px;
    border: 1px solid white;
    border-radius: 5px;
    padding-top: 4px;
    background-color: rgb(20, 12, 28);
    margin-left: 5px;
    cursor: pointer;
}


.aim_chat {
    border: 1px solid grey;
    border-radius: 5px;
    padding: 4px;

}

.aim_textuser {
    text-align: right;
    margin-top: 15px;
    background: linear-gradient(110deg, rgb(72, 72, 136, .9) 20%, rgb(12, 12, 36) 100%);
}

.aim_textresp {
    margin-top: 5px;
    background: linear-gradient(110deg, rgb(13, 6, 24) 50%, rgb(27, 17, 38) 100%);
}

@keyframes selboxglow {
    0% {
        border-radius: 100px;
        box-shadow: 0 0 5px rgba(76, 20, 97, 1);
    }

    50% {
        border-radius: 30px;
        box-shadow: 0 0 50px rgb(92, 12, 84);
    }

    75% {
        border-radius: 100px;
        box-shadow: 0 0 5px rgba(76, 20, 97, 1);
    }

    100% {
        border-radius: 100px;
        box-shadow: 0 0 5px rgba(76, 20, 97, 1);
    }
}

@keyframes selboxglowPlusX {
    0% {
        border-radius: 100px;
        background: linear-gradient(140deg, rgb(4, 255, 41) 60%, rgb(8, 165, 170) 90%);
    }

    50% {
        border-radius: 30px;
        background: linear-gradient(140deg, rgb(4, 255, 41) 60%, rgb(8, 165, 170) 90%);
    }

    75% {
        border-radius: 100px;
        background: linear-gradient(140deg, rgb(4, 255, 41) 60%, rgb(8, 165, 170) 90%);
    }

    100% {
        border-radius: 100px;
        background: linear-gradient(140deg, rgb(4, 255, 41) 60%, rgb(8, 165, 170) 90%);
    }
}
.sts_itm {
    display: flex;
    width: 250px;
    border: 1px solid grey;
    border-radius: 5px;
    height: 36px;
    padding: 6px;
    margin: 4px;
    cursor: pointer;
}

.sts_itm:hover {
    transform: translate(3px, 1px);
}

.stschk_mdlitm {
    font-size: 14px;
    margin: 2px;
    padding: 2px;
    border: 1px solid grey;
    border-radius: 5px;
    background-color: rgb(30, 18, 18);
}

.sts_statusbar {
    width: 100%;
    background: linear-gradient(90deg, rgb(139, 160, 185) 10%, rgb(156, 217, 221) 100%);
    border: 1px solid white;
    border-radius: 25px;
    overflow: hidden;
    height: 30px;
    margin-top: 5px;
}

#sts_progressbar {
    height: 100%;
    background: rgb(40, 40, 195);
    border-radius: 25px;
    transition: width 1.3s ease;
}

.sts_activeitm {
    animation: stsflash 2s infinite;
}

@keyframes stsflash {
    0% {
        background-color: rgb(30, 18, 18);
        box-shadow: 0px 0px 5px white;
    }

    50% {
        background-color: rgb(94, 94, 94);
    }

    100% {
        background-color: rgb(30, 18, 18);
        box-shadow: 0px 0px 5px white;
    }
}
.wlk_stepbox {
    font-size: 18px;
    height: 700px;
}

.wlk_samplefld {
    margin: 3px;
    padding: 3px;
    border: 1px solid grey;
    border-radius: 5px;
    display: flex;
    background-color: rgb(23, 8, 8);
    max-width: 600px;
}

.wlk_fldddown {
    width: 250px;
}

.wlk_keyitm {
    margin: 4px;
    border: 1px solid grey;
    border-radius: 5px;
    width: 120px;
    padding: 3px;
    padding-left: 10px;
    cursor: pointer;
}

.wlk_dateitm {
    border: 1px solid grey;
    border-radius: 5px;
    padding: 4px;
    cursor: pointer;
    background-color: rgb(20, 8, 31);
}

.wlk_dateitm:hover {
    background-color: rgb(60, 11, 69);
    box-shadow: 0px 0px 7px rgb(14, 35, 65);
}

#wlk_kpigraph {
    background-color: rgb(183, 184, 173);
}

.wlk_stepbtn {
    display: flex;
}

.walk_kpivalitm {
    border: 1px solid grey;
    border-radius: 5px;
    padding: 3px;
    margin: 2px;
    background-color: rgb(13, 13, 61);
    display: flex;
    font-size: 17px;
}
#scb_ddownmain {
    width: 300px;
}

.scb_txtoptitm {
    border: 1px solid grey;
    border-radius: 5px;
    padding: 4px;
    height: 30px;
    cursor: pointer;
    background-color: rgb(24, 7, 31);
    font-size: 15px;
}

.scb_txtoptitm:hover {
    background-color: var(--colorsecondary);
}

.scb_pathbtn {
    width: 100px;
    margin: 2px;
}

.scb_emphdr {
    display: flex;
    border: 1px solid grey;
    border-radius: 5px;
    background-color: rgb(8, 4, 52);
    height: 40px;
    align-items: flex-end;
    padding: 2px;
}

.scb_empitm {
    display: flex;
    border: 1px solid grey;
    border-radius: 5px;
    background-color: rgb(8, 4, 52);
    height: 40px;

}

.scb_empsubitm {
    margin-left: 2px;
    font-size: 13px;
    width: 120px;
    overflow: hidden;
}

.scb_empitm:hover {
    background: var(--colorsecondary);
}

.scb_uniqueval {
    font-size: 14px;
    border: 1px solid grey;
    border-radius: 5px;
    background-color: rgb(27, 21, 15);
    padding: 2px;
    width: 50px;
    min-width: 200px;
    overflow: hidden;
}
.help_tabmain {
    margin: 10px;
    border: 1px solid grey;
    padding: 3px;
    padding-left: 6px;
    border-radius: 4px;
    background-color: black;
}

.help_tabheader {
    font-size: 18px;
    letter-spacing: .05em;
}
