#ndttutorial_div {
    position: absolute;
    height: 310px;
    width: 320px;
    background: rgb(214, 212, 224);
    color: black;
    padding: 4px;
    left: 75%;
    top: 60px;
    border: 2px solid rgb(3, 37, 17);
    box-shadow: 5px 5px 10px rgb(130, 129, 129), -2px -2px 5px rgb(130, 129, 129), inset 0px 0px 3px black;

}


@keyframes cycle-bg-1 {
    0% {
        background-color: rgb(255, 255, 255);
        box-shadow: 0px 0px 20px white;
    }

    50% {
        background-color: rgb(20, 20, 73);
    }

    100% {
        background-color: rgb(177, 177, 177);
        box-shadow: 0px 0px 10px white;
    }
}

.seldivtutorial {
    animation: cycle-bg-1 3s linear infinite;
}