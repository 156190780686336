.sch_block {
    height: 150px;
    margin-left: 5px;
}

#sch_main {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: 500px 100px;
}

.sch_name {
    width: 150px;
}