#metr_header {
    height: 150px;
    width: 90%;
    background-image: linear-gradient(-25deg, #110b1a 0%, #152946 100%);
    padding: 10px;
    padding-left: 30px;
    font-size: 18px;
    overflow: hidden;
}

#metr_subheader {
    display: grid;
    grid-template-columns: 35% 35% 30%;
}


#metr_metddown {
    width: 300px;
}

.metr_ddown {
    width: 270px;
    font-size: 16px;
    border-radius: 5px;
    padding: 3px;
}

.metr_box {
    padding: 30px;
    width: 30%;
    min-width: 400px;
    overflow: hidden;
    margin: 10px;
    height: 210px;
}

.metr_box_wide {
    padding: 30px;
    min-width: 700px;
    overflow: hidden;
}

#metr_savebox {
    background-image: linear-gradient(-25deg, #110b1a 0%, #152946 100%);
}


#mm_measurebox {
    display: grid;
    grid-template-columns: 50% 50%;
    min-width: 500px;
    padding: 15px;
}

#mm_mdlsbox {
    overflow-y: scroll;
}