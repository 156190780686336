#jsp_main {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-column-gap: 10px;
}

.jsp_block {
    min-width: 450px;
}

.jsp_skl {
    margin: 3px;
    height: 55px;
    overflow: hidden;
    border: 1px solid grey;
    display: flex;
}

.jsp_sklcat {
    width: 130px;
    border: 1px solid grey;
    padding: 3px;
    background-color: rgb(39, 27, 12);
}

.jsp_skldesc {
    padding: 3px;
}