#kpimgr_main {
    display: grid;
    grid-template-columns: 400px 50%;
    min-width: 800px;
}

.kpim_showval {
    border: 1px solid grey;
    margin: 3px;
    padding: 2px;
    background-color: black;
}

.kpim_inputtag {
    width: 150px;
}

.kpim_inputval {
    width: 70px;
}

.kpim_valitm {
    display: grid;
    grid-template-columns: 90px 80px 80px 80px;
}