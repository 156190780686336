#mv_maingrid {
    display: grid;
    grid-template-columns: 40% 60%;
    grid-row: 550px;
    margin-top: 20px;
}

#emv_mdldown {
    font-size: 18px;
    width: 250px;
}

#emv_fldbox {
    display: block;
    overflow-y: scroll;
    height: 550px;
    width: 90%;
}

.emv_fldboxdetail {
    display: flex;
    min-width: 700px;
    width: 80%;
}

.emv_fldboxdetail:hover {
    background-color: rgb(119, 110, 240);
}

.emv_itmName {
    width: 50%;
    min-width: 300px;
    overflow-x: hidden;
}

.emv_box {
    height: 700px;
}

.emv_mdldet {
    margin-left: 25px;
    margin-top: 3px;
}

.emv_mdlbtn {
    min-width: 170px;
    height: 30px;
    color: rgb(212, 211, 211);
    border: 1px solid rgb(152, 152, 152);
    border-radius: 7px;
    font-size: 15px;
    padding: 3px;
    padding-left: 5px;
    cursor: pointer;
    overflow: hidden;
    margin-left: 3px;
    margin-bottom: 2px;
}

.emv_itmdetail {
    border: 1px solid rgb(119, 110, 240);
    background-color: rgb(214, 214, 214);
    border-radius: 6px;
    margin: 3px;
    padding: 3px;
    color: black;

}

#emv_detailheaddata {
    width: 70%;
}

.emv_paginate {
    margin: auto;
    cursor: pointer;
}


#emv_detaillst {
    border: 1px solid rgb(210, 210, 210);
}

.emv_detailitm {
    display: flex;
    margin: 3px;
    padding: 2px;
    border: 1px solid rgb(125, 125, 125);
}

.emv_detailitm_a {
    width: 40%;
    min-width: 120px;
}

.emv_detailitm_b {
    width: 40%;
    min-width: 150px;
}


.emv_itmName,
.emv_itmVal {
    border: 1px solid grey;
    font-size: 16px;
    width: 50%;
    min-width: 150px;
    padding: 2px;
    padding-left: 5px;
    background-color: rgba(117, 19, 186, 0.071);
    cursor: pointer;
}