#snagraph1 {
    margin-left: 10px;
    box-shadow: 0px 0px 15px blueviolet;
    border: 2px solid blue;
    border-radius: 5px;
}

#npg_mainbox {
    display: grid;
    grid-template-columns: 1250px 500px;
}