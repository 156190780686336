#cmm_body {
    display: grid;
    grid-template-columns: 30% 30% 40%;
    column-gap: 20px;
    row-gap: 20px;
}

#cmm_upper {
    font-size: 16px;
    margin-bottom: 20px;
    height: 160px;
}

#cmm_header {
    font-size: 24px;
    letter-spacing: .1em;
    height: 50px;
}

#cmm_viewbox {
    display: flex;
    width: 550px;
    margin-left: 30px;

}


#mcs_csddown {
    height: 30px;
    width: 250px;
}

#cmm_modelbox {
    grid-column: 3/3;
}

#cmm_newmodeldiv {
    background-color: var(--coloraltprimary);
    color: white;
    width: 45%;
    min-width: 700px;
    display: flex;
    margin-left: 30px;
    padding: 20px;
    border-radius: 20px;
    box-shadow: 2px 2px 5px var(--colorprimarybright),
        1px 0px 5px var(--colorsecondary);
}

#cmm_existmodeldiv {
    height: 50%;
    min-height: 400px;
    width: 70%;
    background-color: var(--colorprimary);
    color: white;
    margin: 60px;
    padding: 20px;
    border-radius: 20px;
    box-shadow: 2px 2px 12px var(--colorprimarybright),
        5px 5px 12px var(--colorsecondary);
}

#cmm_varbox {
    grid-column: 2/2;
    grid-row: 1/1;
    padding: 10px;
    margin-left: 40px;
    margin-top: 100px;
}

.cmm_optn {
    height: 200px;
    width: 300px;
    padding: 30px;
    border: 2px solid black;
    border-radius: 30px;
    background-color: var(--coloraltprimary);
    box-shadow: 2px 2px 5px var(--colorprimarybright),
        1px 0px 5px var(--colorsecondary);
}

#cmm_assignbox {
    grid-row: 1/1;
    grid-column: 1/1;
    height: 600px;
    width: 400px;
}

#cmm_modelselbox {
    height: 70%;
    padding-left: 10px;
    padding-top: 4px;
    margin-top: 4px;
    overflow-y: scroll;
}

.cmm_setdiv {
    background-color: var(--coloraltprimary);
    width: 220px;
    height: 30px;
    color: white;
    margin-top: 5px;
    border: white;
    border-radius: 5px;
    padding: 3px;
    cursor: pointer;
}

.cmm_setdiv:hover {
    transition: .3s ease-out;
    transition-delay: .1s;
    box-shadow: 1px 2px 2px yellow,
        -1px 2px 2px yellow,
        1px -2px 2px yellow,
        -1px -2px 2px yellow;
}

#cmm_csddown {
    height: 40px;
}