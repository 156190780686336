#fm_header {
    background: linear-gradient(90deg, rgb(0, 0, 0) 70%, rgb(42, 42, 42) 100%);
    border: 2px solid rgb(149, 149, 149);
    padding-left: 5px;
    box-shadow: 4px 4px 5px black;
}


#fm_body {
    display: grid;
    grid-template-columns: 50% 50%;
    column-gap: 20px;
    margin-top: 20px;
    height: "800px"
}

#filtexisting_canvas {
    height: 750px;
}


#flduniquelist {
    height: 600px;
    overflow-y: scroll;
    overflow-x: hidden;
}

.fm_fldunique_itm {
    border: 1px solid rgb(63, 63, 63);
    width: 90%;
    font-size: 15px;
    padding: 1px;
    padding-left: 4px;
    margin: 2px;
    overflow-x: hidden;
    cursor: pointer;
}

#fm_selmdl {
    min-height: 100px;
    min-width: 300px;
}

#fm_selmdlheader {
    background-color: rgb(31, 31, 31);
    padding: 4px;
    font-size: 18px;
}

.fltmgitm {
    font-size: 15px;
}

#fm_filtddown1 {
    margin-top: 2px;
    margin-right: 5px;
}