.dtrowclass {
    border: 1px solid black;
}

.dtcellclass {
    border: 1px solid black;
    min-width: 30px;
    padding: 1px;
    font-weight: bold;
}

.tbl_rwpgbtn {
    cursor: pointer;
    text-align: center;
}