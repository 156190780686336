.ndt_stlmainselect {
    position: absolute;
    right: 0;
    top: 150px;
}

.ndt_sideXborder {
    background: grey;
    background-size: 400% 400%;
    width: 100%;
    height: 50px;
    padding: 1px;
}

.ndt_sideXoffset {
    background: linear-gradient(-270deg, var(--coloraccent), var(--colorsecondary), var(--colorprimary), black);
    background-size: 400% 400%;
    width: 100%;
    padding: 2px;
    padding-left: 0px;
    animation: gradientAnimation 10s ease infinite;
}

.ndt_sideXinner {
    background: linear-gradient(90deg, var(--colorprimary) 0%, black 100%);
    padding-left: 10px;
    padding-top: 7px;
    width: 100%;
    height: 44px;
    /* Manually adjusted to account for border width */
}

#stlcollapsible-divS {
    width: 0px;
    height: 50px;
    transition: width 0.3s ease;
    overflow: hidden;
}

#collapsible-checkbox:checked~#stlcollapsible-divS {
    width: 450px;
}


.stlLabelS {
    left: -60px;
    height: 50px;
    width: 60px;
    border-radius: 16px 0px 0px 16px;
    padding: 15px 10px;
}

@keyframes selboxglowPlus {
    0% {
        background: black;
    }

    50% {
        background: rgb(4, 255, 41);
    }

    100% {
        background: black;
    }
}

@keyframes gradientAnimation {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}