#scb_ddownmain {
    width: 300px;
}

.scb_txtoptitm {
    border: 1px solid grey;
    border-radius: 5px;
    padding: 4px;
    height: 30px;
    cursor: pointer;
    background-color: rgb(24, 7, 31);
    font-size: 15px;
}

.scb_txtoptitm:hover {
    background-color: var(--colorsecondary);
}

.scb_pathbtn {
    width: 100px;
    margin: 2px;
}

.scb_emphdr {
    display: flex;
    border: 1px solid grey;
    border-radius: 5px;
    background-color: rgb(8, 4, 52);
    height: 40px;
    align-items: flex-end;
    padding: 2px;
}

.scb_empitm {
    display: flex;
    border: 1px solid grey;
    border-radius: 5px;
    background-color: rgb(8, 4, 52);
    height: 40px;

}

.scb_empsubitm {
    margin-left: 2px;
    font-size: 13px;
    width: 120px;
    overflow: hidden;
}

.scb_empitm:hover {
    background: var(--colorsecondary);
}

.scb_uniqueval {
    font-size: 14px;
    border: 1px solid grey;
    border-radius: 5px;
    background-color: rgb(27, 21, 15);
    padding: 2px;
    width: 50px;
    min-width: 200px;
    overflow: hidden;
}