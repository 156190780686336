.bmm_stepbox {
    height: 700px;
    background-color: rgb(11, 6, 15);
    padding: 25px;
    min-width: 500px;
}

.bmm_uniqueitm {
    width: 20%;
    min-width: 200px;
}

.bmm_valueitm:hover {
    background-color: rgb(18, 23, 81);
    box-shadow: 0px 0px 5px white;
}


.bmm_flditm {
    display: flex;
}

.bmm_flditm:hover {
    background-color: rgb(35, 43, 127);
    box-shadow: 0px 0px 2px white;

}

#bmmm_checkcolsbox {
    display: grid;
    grid-template-columns: 50% 50%;
}

.bmm_fldsubitm {
    width: 50%;
    border: 1px solid grey;
    padding: 2px;
}

.bmm_bfldrow {
    display: flex;
    border: 1px solid grey;
    padding: 3px;
}

.bmm_bfldsubrw {
    width: 40%;
    min-width: 120px;
}

.bmm_metritm {
    border: 1px solid grey;
    padding: 3px;
    width: 200px;
}

.bmm_existsetitm {
    cursor: pointer;
    background-color: black;
    margin: 3px;
    padding: 2px;
    border: 1px solid rgb(56, 56, 56);
}

.bmm_existsetitm:hover {
    background-color: rgb(42, 35, 73);
}

.bmm_fldbtn {
    border: 1px solid grey;
    padding: 3px;
    border-radius: 5px;
    width: 210px;
    background-color: rgb(12, 17, 42);
}

.bmm_opt {
    cursor: pointer;
}

.bmm_rdobj {
    border: 1px solid grey;
    margin: 3px;
    padding: 3px;
    cursor: pointer;

}

.bmm_topbtn {
    width: 100px;
}

.bmm_kpiitm {
    display: flex;
    border: 1px solid grey;
    border-radius: 5px;
    margin: 3px;
    padding: 3px;
    width: 400px;
    cursor: pointer;
    background-color: rgb(10, 9, 37);
}

.bmm_kpiitm:hover {
    background-color: rgb(24, 21, 123);
    box-shadow: 0px 0px 6px yellow;
}

.bmm_kpiitm_dt {
    width: 120px;
}

.bmm_kpiitm_val {
    width: 100px;
}