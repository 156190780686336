#gm_canvas {
    width: 100%;
    display: grid;
    grid-template-columns: 25% 70%;
    margin-left: 10px;
    opacity: .95;
    max-height: var(--fullscreenheight);
    height: 800px;
}

#listqueries_mdl {
    width: 100%;
    min-width: 180px;
    overflow-x: hidden;
}


#gm_subcanvas {
    display: grid;
    grid-template-rows: 100px 800px;
    grid-template-columns: 60% 40%;
    row-gap: 50px;
}

#gm_dashbox {
    grid-column: 2/2;
}

#querylistdiv {
    opacity: .9;
    overflow: hidden;
    padding: 3px;
}

#qrylst_header {
    padding: 3px;
}

#qrylst_graphheader {
    font-size: 18px;
}

#qrylst_subheader {
    font-size: 14px;
    display: flex;
}

#gm_qrylist_body {
    height: 450px;
    margin-top: 10px;
    margin-left: 5px;
    overflow-y: scroll;
    font-size: 16px;
}

.qm_itm {
    cursor: pointer;
    overflow: hidden;
    border-radius: 8px;
    margin: 10px;
    padding: 5px;
}

.qm_qrylist_itm {
    grid-column: 1/1;
    border: 1px solid var(--colorprimary);
    height: 60px;
    min-width: 200px;
    width: 80%;
    background-color: rgb(7, 6, 37);
}

#gm_gridoptbox {
    display: grid;
    grid-template-rows: 550px 200px;
}

#qrymgr_dashheader {
    font-size: 16px;
    font-weight: bold;
    padding: 5px;
    letter-spacing: .1em;
    margin-left: 20px;
}

.qm_dashlist_itm {
    grid-column: 2/2;
    border: 1px solid rgb(145, 24, 24);
    height: 60px;
    width: 200px;
}

#qrymgr_switchcanvas {
    margin-top: 60px;
}

.qrymgr_header1 {
    font-size: 26px;
    color: rgb(169, 169, 243)
}


.qrymgr_assign {
    background: rgb(215, 244, 222);
}

.qrymgr_unassign {
    background: rgb(245, 212, 248);
}

.qrymgr_switchitm {
    display: flex;
}

.qrymgr_switchheader {
    margin: auto;
    display: block;
    margin-top: 10%;
}

#qrymgr_switchitm {
    margin: auto;
}

#qrymgr_confirmbtn {
    width: 40%;
    height: 40px;
    border-radius: 5px;
    background: rgb(85, 72, 98);
    color: white;
    margin-left: 30%;
}

#qrymgr_confirmbtn:hover {
    transition: .3s ease-out;
    background: rgb(255, 250, 110);
    color: black;
    box-shadow: 2px 2px 3px yellow,
        -2px 2px 3px yellow,
        2px -2px 3px yellow,
        -2px -2px 3px yellow;
}


#qm_visdetail {
    padding: 10px;
    min-height: 10vh;
    height: 20%;
    margin-top: 20px;
    overflow: hidden;
    box-shadow: 0px 0px 5px rgb(157, 157, 157);
    border-radius: 5px;
}

#gm_visdetailinner {
    height: 250px;
    overflow-y: scroll;
}

.qm_qryoption {
    margin-left: 5px;
}

.qm_qryoptionbtn {
    height: 50px;
    width: 100%;
    background-color: black;
    color: white;
    border-radius: 10px;
    border: 1px solid darkblue;
    padding: 5px;
}

.qm_qryoptionbtn:hover {
    transition: .5s ease-out;
    background-color: rgb(34, 34, 110);
}

.qm_closebtn {
    margin-left: 5%;
    height: 30px;
    width: 90%;
    color: white;
    background-color: rgb(6, 6, 69);
}

.qm_enterbtn {
    margin: auto;
}


#gm_viewseldiv {
    margin-left: 25px;
    display: block;
    padding: 4px;
    min-height: 100px;
    grid-column: 1/3;
}

#gm_viewdivheader {
    padding: 10px;
    margin-left: 20px;
    margin-right: 10px;
    font-size: 18px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    width: 550px;
    height: 75px;
}

.qm_viewbtn {
    width: 100px;
    height: 35px;
    font-size: 16px;
    border-radius: 5px;
    margin-left: 2px;
    background-color: var(--colorsecondary);
    display: block;
}

.qm_viewbtn:hover {
    background-color: bisque;
    transition-delay: .2s;
    transition: .3s ease-out;
}


.gm_viewlink {
    width: 50%;
}

#gm_qryrunboxheader {
    font-size: 17px;
    letter-spacing: .2em;
}

#gm_qryrundates_div {
    height: 220px;
    overflow-y: scroll;
}

.gm_qryrd_sel {
    width: 80%;
    border-radius: 5px;
    padding: 3px;
    margin-top: 5px;
    margin-bottom: 5px;
    margin-left: 10px;
    cursor: pointer;
    opacity: .8
}

.gm_viewoption {
    opacity: .9;
    height: 200px;
    width: 300px;
    margin-left: 100px;
    padding: 20px;
    background-color: var(--coloraltprimary);
    box-shadow: 3px 4px 5px rgb(177, 237, 182),
        -3px -4px 5px rgb(90, 90, 202);
}

.gm_viewoption_body {
    height: 245px;
}

.gm_qryrd_sel:hover {
    transition: .3s ease-out;
    transition-delay: .1s;
    opacity: 1;
}


#gm_qryrunbtn_popup {
    min-width: 150px;
    width: 50%;
    margin-left: 25%;
    background-color: rgb(22, 22, 85);
    color: white;
}

#gmrundate_header {
    border: 2px solid rgb(90, 90, 202);
    border-radius: 3px;
    margin: 3px;
    padding: 3px;
}

.gm_reldateOpt {
    height: 80px;
    border: 1px solid black;
    border-radius: 5px;
    margin: 5px;
    margin-left: 40px;
    padding: 5px;
}


#gm_rdrundiv {
    padding: 5px;
    margin: auto;
}

#gm_rdrunbtn {
    background-color: rgb(155, 234, 234);
    width: 70px;
    margin-top: 5px;
    margin-bottom: 5px;
}

#gm_rdtextarea {
    width: 450px;
}

#gm_detfldbox {
    height: 180px;
    margin: 2px;
    margin-top: 7px;
    border: 1px solid black;
    font-size: 14px;
    overflow-y: scroll;
    box-shadow: 1px 1px 3px rgb(90, 90, 202),
        -1px -1px 3px rgb(90, 90, 202);
}

.gm_detflddiv {
    border: 1px solid rgb(245, 125, 125);
    border-radius: 4px 6px 6px 4px;
    margin: 3px;
    padding: 2px;
    padding-left: 5px;
    cursor: pointer;
    height: 25px;
}

#gm_rdnotes {
    height: 80px;
}

.gm_dashddown {
    width: 230px;
    overflow-x: hidden;
    margin-left: 15px;
    height: 35px;
    background-color: var(--coloroffwhite);
}

#gm_runcanvas {
    display: grid;
    grid-template-columns: 70% 30%;
}

#gm_rundateinfoBox {
    height: 260px;
    width: 250px;
    padding: 15px;
    border-radius: 5px;
}

.gm_qvisitem {
    height: 40px;
    border: 1px solid white;
    border-radius: 10px;
    padding: 5px;
    cursor: pointer;
    background-color: rgb(41, 12, 57);
}