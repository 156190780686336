.ndt_canvas {
    background-image: linear-gradient(140deg, var(--colorprimary) 50%, rgb(0, 0, 0) 100%);
    min-height: var(--fullscreenheight);
    width: 100%;
    color: white;
    padding: 10px;
    font-family: Cambria;
    font-size: 16px;
    overflow-x: hidden;
}

.ndt_grid {
    row-gap: 15px;
    column-gap: 20px;
}

#ncanvas {
    display: grid;
    grid-template-columns: 30% 70%;
    grid-template-rows: 400px 400px;

}

.ndt_header {
    background-image: linear-gradient(-25deg, #110b1a 0%, #152946 100%);
}

.ndt_gridbox {
    background-color: rgba(101, 101, 101, 0.145);
    box-shadow: inset -2px 2px 15px black,
        inset 2px 2px 25px black,
        inset -1px -1px 4px var(--colorsecondary),
        inset 1px 1px 4px var(--colorsecondary),
        2px 2px 3px rgb(30, 30, 30);
    padding: 10px;
    padding-left: 15px;
    overflow: hidden;
}

.ndt_innerbox {
    margin: 10px;
    padding: 5px;
    box-shadow: -2px 2px 3px black,
        0px 0px 20px black,
        inset 0px 0px 3px rgb(141, 141, 141),
        2px 2px 3px rgb(30, 30, 30);
    background-color: var(--coloraltprimary);
    overflow-x: hidden;
}

.ndt_subinner {
    margin: 10px;
    padding: 5px;
    box-shadow: -2px 2px 3px black,
        0px 0px 10px black,
        0px 0px 3px rgb(141, 141, 141),
        inset 2px 2px 3px rgb(30, 30, 30);
    background-color: var(--coloraltprimarysub);
    overflow-x: hidden;
}

.ndt_title1 {
    font-size: 48px;
    letter-spacing: .1em;
}

.ndt_title2 {
    font-size: 32px;
    letter-spacing: .1em;
}

.ndt_title3 {
    font-size: 26px;
    letter-spacing: .08em;
}

.ndt_title4 {
    font-size: 22px;
    letter-spacing: .06em;
}


.ndt_item {
    box-shadow: 0px 0px 3px var(--colorsecondary);
    color: white;
    border-radius: 10px;
    padding: 3px;
    padding-left: 5px;
    cursor: pointer;
    margin: 3px;
    margin-top: 5px;
    background-color: rgb(15, 15, 15);
    overflow: hidden;
}

.ndt_item:hover {
    background: linear-gradient(40deg, var(--colorsecondary) 70%, rgb(0, 0, 0) 100%);
    color: black;
    transition: .2s ease-in;
}

.ndt_dropdown {
    height: 32px;
    padding: 2px;
    border: 1px solid black;
    border-radius: 5px;
    font-size: 17px;
}

.ndt_btnmain {
    border: 1px solid rgb(142, 142, 142);
    color: rgb(118, 216, 227);
    background-color: rgb(18, 8, 70);
    border-radius: 8px;
    padding: 2px;
    padding-left: 6px;
    padding-right: 6px;
    height: 35px;
    overflow: hidden;
}

.ndt_btnmain:hover {
    transition: .3s;
    transition-delay: .05s;
    background-color: rgb(54, 29, 78);
    box-shadow: 0px 0px 6px var(--colorsecondary);
}

.ndt_btn1 {
    border: 1px solid rgb(90, 88, 88);
    color: var(--colorsecondary);
    background-color: black;
    border-radius: 8px;
    padding: 2px;
    padding-left: 6px;
    padding-right: 6px;
    height: 32px;
    overflow: hidden;
    font-size: 16px;
}

.ndt_btn1:hover {
    transition: .2s;
    transition-delay: .05s;
    background-color: rgb(29, 29, 29);
    box-shadow: 0px 0px 3px var(--colorsecondary);
}

.ndt_btn2 {
    border: 1px solid rgb(174, 42, 42);
    color: var(--colorsecondary);
    background-color: rgb(62, 18, 66);
    border-radius: 8px;
    padding: 2px;
    padding-left: 6px;
    padding-right: 6px;
    height: 32px;
    overflow: hidden;
    font-size: 16px;
}

.ndt_btn2:hover {
    transition: .2s;
    transition-delay: .05s;
    background-color: rgb(108, 24, 24);
    box-shadow: 0px 0px 3px var(--colorsecondary);
}

.ndt_btn3 {
    border: 1px solid rgb(119, 216, 255);
    color: white;
    background-color: rgb(60, 12, 94);
    border-radius: 8px;
    padding: 2px;
    padding-left: 6px;
    padding-right: 6px;
    height: 35px;
    overflow: hidden;
}

.ndt_btn3:hover {
    transition: .2s;
    transition-delay: .05s;
    background-color: rgb(39, 24, 108);
    box-shadow: inset 0px 0px 3px whitesmoke;
}

.ndt_modal {
    background: rgb(39, 36, 52);
    color: white;
    border: 3px solid var(--colorsecondary);
    box-shadow: 0px 0px 10px white;
    padding: 10px;
    border-radius: 3px;
}


.closemodalbtn {
    width: 90%;
    margin-left: 5%;
    margin-top: 10px;
    background-color: #394263;
    color: white;
}

.closemodalbtn:hover {
    transition: .1s;
    background-color: #293563;
}

.datepick_class {
    background-color: white;
    color: black;
}

.ndt_chart {
    overflow-x: scroll;
    padding: 2px;
}

.modalwide {
    min-width: 600px;
}

.modalwide2 {
    min-width: 800px;
}

.ndt_modaltitle {
    font-size: 20px;
    letter-spacing: .03em;
}

.ndt_tab1 {
    background-color: black;
    border: 1px solid rgb(79, 79, 97);
    border-radius: 5px 5px 0px 0px;
    color: white;
    margin-right: 1px;
}


/* scrollbars */
/* width */
::-webkit-scrollbar {
    width: 10px;

}

/* Track */
::-webkit-scrollbar-track {
    background: #160f27;
    border: 2px solid rgb(17, 16, 89);
    opacity: .3;
    box-shadow: inset 0px 0px 3px white;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: rgb(9, 2, 27);
    border: 1px solid rgb(13, 132, 49);
    border-radius: 4px;
    opacity: .5;
    box-shadow: inset 0px 0px 6px white;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: rgb(30, 6, 61);


}

.ndt_btn1:active::before {
    animation: clockwise-border 1s linear forwards;
}

/* Keyframes for clockwise border animation */