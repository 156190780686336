#qmv_header {
    height: 120px;
    padding-left: 30px;
    padding-top: 10px;
}

.qmv_btnopt {
    margin-left: 5px;
    height: 40px;
    width: 140px;
}