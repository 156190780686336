.loginContainer {
	display: grid;
	grid-template-columns: 20% 50% 20%;
	grid-column-gap: 20px;
	grid-template-rows: 800px;
	background: linear-gradient(to top, #000000, #270b48);
	font-family: Helvetica;
	min-height: var(--fullscreenheight)
}

#loginbox {
	grid-column: 2/3;
	grid-row: 1/1;
	margin-top: 200px;
	margin-bottom: 200px;
	min-width: 400px;
	background: linear-gradient(-25deg, #350d38 0%, #56367a 100%);
	color: white;
	overflow: hidden;
	border: 1px solid rgb(48, 12, 75);
	border-radius: 10px;
	border: 1px solid rgb(164, 164, 164);
	box-shadow: inset 0px 0px 15px rgb(195, 195, 195), 10px 20px 20px rgba(0, 0, 0, 0.25),
		0 6px 15px rgba(0, 0, 0, 0.22);
	text-align: center;
	z-index: 1;
}

#formbox {
	margin: 0 auto;
	margin-top: 50px;
	letter-spacing: 0.5px;
	line-height: 20px;
	min-width: 300px;
	border: 1px solid #DDDDDD;
	background: linear-gradient(-25deg, #110b1a 0%, #152946 100%);
	text-align: center;
	padding: 3px;
	width: 50%;
	justify-content: center;
	border-radius: 10px;
}

#logintitle {
	letter-spacing: .40rem;
	font-size: 34px;
	margin-top: 50px;
	font-family: system-ui;
}

.log-input {
	background-color: #eee;
	border: none;
	padding: 12px 15px;
	margin: 8px 0;
	width: 100%;
}

.login-button {
	margin: 3px;
	border-radius: 20px;
	border: none;
	background-color: #693dda;
	color: #FFFFFF;
	font-size: 12px;
	font-weight: bold;
	padding: 12px 45px;
	letter-spacing: 1px;
	text-transform: uppercase;
}

.login-button:hover {
	filter: brightness(1.5);
	transition: .6s
}

#signuplinkbox {
	font-size: 10px;
	text-align: left;
}

#login-guest {
	cursor: pointer;
	margin-top: 5px;
	border: 1px solid grey
}

#login-guest:hover {
	background-color: #000000;
	box-shadow: inset 0px 0px 2px rgb(158, 158, 158), 0px 0px 3px aqua;
}