#cse_header {
    font-size: 20px;
}

#cse_itmlist {
    font-size: 16px;
    color: var(--colorprimary);
    height: 600px;
    overflow-y: scroll;

}

#cse_flduniquelist {
    margin-left: 20px;
    margin-top: 20px;
    min-width: 200px;
    width: 60%;
}

.cse_fltitm {
    display: flex;
    cursor: pointer;
}

.cse_flditmrow:hover {
    background-color: var(--colorhighlight);
}


.cse_fltitmval {
    width: 60%;
    color: white;
    overflow-x: hidden;
}


.cse_flditm_color {
    min-width: 150px;
    background-color: white;
    width: 20%;
    margin-right: 10px;
}

#cse_fldddown {
    width: 250px;
}