.mt_gridcontainer {
	display: grid;
	grid-template-columns: 200px 1fr;
	grid-template-rows: 10px 1fr 110px;
	grid-template-areas:
		"sidenav header"
		"sidenav main"
		"sidenav footer";
	min-height: var(--fullscreenheight);
	min-width: 900px;
}



#mt_main {
	grid-area: main;
	width: 100%;
	padding: 20px;
	display: grid;
	grid-template-columns: 30% 30% 30%;
}

.mt_footer {
	grid-area: footer;
	background-color: #648ca6;
}

.mt_footer {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 16px;
	background-color: #000000;
}


.mt_sidenav {
	display: flex;
	/* Will be hidden on mobile */
	flex-direction: column;
	grid-area: sidenav;
	background-color: #09092c;
}

.mt_sidenav__list {
	padding: 0;
	list-style-type: none;
}

.mt_sidenav__list-item {
	padding: 20px 20px 20px 40px;
	color: #ddd;
}

.mt_sidenav__list-item:hover {
	background-color: rgba(255, 255, 255, 0.2);
	cursor: pointer;
}

#mt_sidenavheader {
	margin-top: 50px;
	height: 100px;
	color: white;
	margin-left: 20px;
	font-size: 20px;
}

.mt_mainheader {
	display: flex;
	justify-content: space-between;
	margin: 20px;
	padding: 20px;
	height: 150px;
	/* Force our height since we don't have actual content yet */
	background-color: #0a0e16;
	color: rgb(172, 172, 172);
}

.mt_main-overview {
	height: 500px;
	display: grid;
	grid-template-rows: 80px 80px 80px 80px 80px;
	grid-gap: 20px;
	margin: 20px;

}

.overviewCard {
	padding: 20px;
	width: 100%;
	height: 70px;
	display: flex;
}

.overviewsubCard {
	align-items: center;
	justify-content: space-between;
	padding: 20px;
	background: rgb(82, 23, 121);
	background: linear-gradient(27deg, rgba(82, 23, 121, 1) 0%, rgba(159, 85, 124, 1) 26%, rgba(252, 69, 135, 1) 100%);
	box-shadow:
		1px 1px 1px #461648,
		1px 1px 1px #461648,
		-2px -3px 4px #461648,
		7px 5px 14px #461648;
	color: white;
	border-radius: 10px;
	font-family: Helvetica;
	/*animation: opacityOn 1s normal forwards; */
	cursor: pointer;
	transition: .5s ease-out;
	margin-left: 20px;
	width: 80%;
}

.overviewcard__icon {
	font-size: 18px;
}

.overviewcard__info {
	color: rgb(225, 228, 179)
}

.overviewCard:hover {
	width: 110%;
	transition: .7s ease-in;
	transition-delay: 100ms;
}



@keyframes RowOn {
	0% {
		opacity: 0;
	}

	25% {
		opacity: .25;
	}

	50% {
		opacity: .5;
	}

	75% {
		opacity: .75;
	}

	100% {
		opacity: 1;
	}
}



/* fields for header modal */
.t_field {
	display: flex;
	background-color: rgb(165, 181, 227);
	border: 1px dashed rgb(133, 120, 120);
	padding: 2px;
	margin: 1px;
	border-radius: 5px 3px 3px 0px;
}

.t_fielditem {
	color: black;
}

.t_head {
	display: flex;
	background-color: rgb(23, 71, 47);
	border: 1px dashed rgb(133, 120, 120);
	padding: 2px;
	margin: 1px;
	border-radius: 5px 3px 3px 0px;
}

.t_headitem {
	color: white;
	width: 200px;
}

#tmodelreadyform {
	color: white;
	background-color: #10071b;
	font-family: Arial, Helvetica, sans-serif;
	font-size: 1em;
	height: 100px;
}

#tmodelreadyitms {
	margin-left: 45%;
	padding: 3px;
	height: 60px;
}

#tsubmitheader {
	margin-left: 15px;
	letter-spacing: .07em;
}

#tmodelsubmitbtn {
	width: 150px;
	height: 35px;
	background-color: rgb(110, 41, 64);
	color: white;
	border: 2px solid pink;
	border-radius: 5px
}

#tmodelsubmitbtn:hover {
	background-color: rgb(167, 91, 117);
	transition: ease-in .3s;

	box-shadow: 0px 0px 2px #ebe7af;
}

#t_uploadempdiv {
	color: white;
	margin-left: 45%;
}

#checkmodel_link {
	color: white;
}


.mt_modal {
	padding: 5px;
	color: white;
	background-color: black;
	box-shadow: 0px 0px 6px white;
}

.mt_header {
	font-size: 18px;
	letter-spacing: 1px;
	font-weight: bold;
	font-family: Georgia, 'Times New Roman', Times, serif;
	margin-bottom: 3px;
}

.mt_reporttypesel {
	width: 200px;
	height: 40px;
	border-radius: 3px;
	padding: 2px;
	margin: 3px;
}

.mtclosemodalbtn {
	width: 90%;
	margin-left: 5%;
	background-color: #10071b;
	color: white;
	border-radius: 5px;
	border: 3px solid rgba(197, 28, 28, 0.641);
}

.mtclosemodalbtn:hover {
	transition: .3s ease-in;
	background-color: rgba(28, 24, 55, 0.897)
}

.mt_empfilebtn {
	border-radius: 0px 8px 8px 0px;
	border: 1px solid black;
	margin-right: 5px;
	width: 350px;
}

.mt_empsubmitbtn {
	color: white;
	background-color: rgba(149, 149, 149, 0.35);
	border: 2px solid rgb(110, 41, 64);
	width: 110px;
}

.mt_assumebtn {
	margin-top: 3px;
	display: block;
}

.mt_demoquestion {
	padding: 10px;
}

.mt_ruleitem {
	height: "40px";
	font-size: '18px';
	letter-spacing: .02em;
}