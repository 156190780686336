.empGrid {
    height: 700px;
    width: 100%;
    display: grid;
    grid-template-rows: repeat(5, 20%);
    grid-template-columns: repeat(7, 280px);
    box-shadow: inset;
    font-size: 18px;
    color: white;
}

#dept_empddown {
    padding: 5px;
    font-size: 16px;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

.deptempbox {
    background-color: var(--coloroffwhite);
    width: 250px;
    height: 120px;
    border-radius: 10px;
    border: 1px solid black;
    margin: auto;
    animation: opacityOn 1s normal forwards;
    padding: 6px;
    color: black;
    box-shadow: inset 0px 0px 4px white;
    font-size: 16px;

}

.deptempbox:hover {
    transition: ease-in .5s;
    color: var(--colorsecondary);
    box-shadow:
        -1px 1px 10px var(--colorsecondary),
        1px 1px 4px var(--colorsecondary),
        -1px -1px 4px var(--colorsecondary),
        1px 1px 4px var(--colorsecondary);
}

.deptempbox {
    transition: .5s;
}

@keyframes opacityOn {
    0% {
        opacity: 0;
    }

    25% {
        opacity: .4;
    }

    50% {
        opacity: .4;
    }

    75% {
        opacity: .75;
    }

    100% {
        opacity: 1;
    }
}