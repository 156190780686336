#egraphContainer {
    display: grid;
    grid-template-columns: 300px 60% 20%;
    grid-template-rows: 40px 800px;
    column-gap: 20px;
}

#ge_graphsidebar {
    background-color: var(--colorprimary);
    width: 300px;
    grid-column: 1/1;
    grid-row: 1/1;
    height: 95vh
}

#ge_sidebarbody {
    background-color: var(--colorprimary);
    height: 300px;
}

#ge_properties {
    grid-column: 3/3;
    margin: 3px;
    border: 1px solid black;
    display: block;
}

.ge_prop {
    height: 30px;
    width: 100%;
    font-family: Arial, Helvetica, sans-serif;
    display: flex;
}

#ge_propHeader {
    font-size: 18px;
    font-weight: bold;
}

.ge_propKey {
    width: 150px;
}

.fieldelem {
    height: '40px';
    border: 1px solid black;
    display: flex;
    margin-bottom: 1px;
}

.fieldclick {
    width: 170px;
}

.fieldclick_xy {
    border: 1px solid rgb(146, 32, 32);
    padding: 3px;
    width: 180px;
    margin-left: 10px;
    border-radius: 3px;
    cursor: pointer;
    height: 40px;

}

.fieldclick_xy {
    transition: .3s ease-in;
}

#charttitle {
    margin: auto;
    font-family: Cambria;
    letter-spacing: 1px;
    font-size: 24px;
}

#graphsidebar {
    grid-row: 1/3;
    grid-column: 1/1;
    border: 1px solid black;
    border-radius: 0px 10px 10px 0px;
    margin-right: 10px;
    min-width: 150px;
}

.ge_sidenav {
    display: flex;
    /* Will be hidden on mobile */
    flex-direction: column;
    grid-area: sidenav;
    background-color: #09092c;
}

.ge_sidenav__list {
    padding: 0;
    margin-top: 85px;
    list-style-type: none;
}

.ge_sidenav__list-item {
    padding: 20px 20px 20px 40px;
    color: #ddd;
}

.ge_sidenav__list-item:hover {
    background-color: rgba(255, 255, 255, 0.2);
    cursor: pointer;
}

.header,
.footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    background-color: #000000;
}



#graphsidebarHeader {
    color: blanchedalmond;
    margin-left: 30px;
    margin-top: 10px;
    margin-bottom: 10px;
    letter-spacing: 2.4px;
    width: 200px;
    text-align: left;
    font-size: 18px;
    height: 60px;
    overflow: hidden;
}

.TopElement {
    color: white;
    font-size: 18px;
    padding: 5px;
    height: 50px;
    cursor: pointer;
    margin-top: 3px;
}

.TopElement:hover {
    transition: .4s;
    background-color: rgb(180, 164, 201);
}

#selectedTopElem {
    color: rgb(221, 216, 189);
    text-align: right;
    padding-right: 5px;
    letter-spacing: .1em;
}

#sidebtnCreate {
    background-color: #454e74;
}

.bar {
    border-radius: 3px;
}


#activemodelheader {
    font-size: 18px;
    font-size: bold;
    letter-spacing: 0px;
}

.fldheader {
    margin: auto;
}

.chartclick {
    background-color: rgb(240, 240, 240);
    margin: auto;
    margin-top: 5px;
    margin-bottom: 5px;
    width: 200px;
    padding: 7px;
    border: 1px groove black;
    border-radius: 20px 7px 20px 7px;
    cursor: pointer;
    text-align: center;
}

.textclass1 {
    width: 10px;
    color: red;
}

#mdl_report {
    height: 200px;
}

#mdl_datespread {
    height: 200px;
}

#mdl_rule {
    background-color: rgb(231, 225, 230);
    padding: 5px;
    margin: auto;
    margin-top: 10px;
    margin-bottom: 10px;
    width: 300px;
    text-align: center;
}

.modalemp_header {
    margin: 5px;
    font-family: helvetica;
    letter-spacing: .3em;
}

.modalemp_ddown {
    width: 250px;
    margin: 5px;
}

#mdl_visuals {
    height: 300px;
    width: 600px;
    display: grid;
    grid-template-columns: 25% 75%;
    padding: 5px;
}

.colorpicker {
    width: 120px;
    background-color: rgb(238, 238, 238);
    margin-top: 5px;
    margin-left: 2px;
    border: 1px solid black;
    border-radius: 5px;
    padding-left: 10px;
}

.colorpicker:hover {
    transition: 1.0s;
    box-shadow:
        -1px 1px 10px #48afb3,
        1px 1px 4px #48afb3,
        -1px -1px 4px #48afb3,
        1px 1px 4px #48afb3;
}

#ge_chartgroup {
    display: grid;
    grid-template-rows: 600px 100px;
}


#ge_rundatesel {
    grid-row: 2/2;
    margin: 3px;
    border: 1px solid black;
    display: block;
    width: 50%;
    height: 250px;
    overflow-y: scroll;
    padding: 5px;
    background-color: var(--coloroffwhite);
}

#ge_rundatesel_header {
    font-size: 18px;
    letter-spacing: .05em;
    padding-left: 10px;
}

.ge_rundate_elem {
    cursor: pointer;
    background-color: rgba(13, 49, 61, 0.25);
    margin: 2px;
    width: 80%;
    border: 1px solid black;
    border-radius: 2px 5px 2px 5px;
    padding-left: 2px;
}

.ge_rundate_elem:hover {
    background-color: rgba(13, 49, 61, 0.4);
}


#ge_samplegraph {
    margin-top: 40px;
    background-color: white;
    width: 700px;
    height: 500px;
    border: 2px solid black;
    grid-column: 2/2;
    box-shadow:
        -2px -2px 4px rgb(196, 181, 228),
        2px 2px 4px rgb(196, 181, 228),
        2px -2px 4px rgb(196, 181, 228),
        -2px 2px 4px rgb(196, 181, 228);
}