.help_tabmain {
    margin: 10px;
    border: 1px solid grey;
    padding: 3px;
    padding-left: 6px;
    border-radius: 4px;
    background-color: black;
}

.help_tabheader {
    font-size: 18px;
    letter-spacing: .05em;
}