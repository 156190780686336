#conn_tblmain {
    width: 1500px;
    display: grid;
    grid-template-columns: 500px 500px 500px;
    height: 500px;
}

.conn_fldlist {
    overflow-y: scroll;
    width: 450px;
    padding: 5px;
}

.conn_existconnitem {
    width: 300px;
    border: 1px solid white;
    border-radius: 5px;
    padding: 3px;

}

.conn_existconnitemValid {
    cursor: pointer;
}

.conn_existconnitemValid:hover {
    background: rgb(42, 31, 100);
    box-shadow: 0px 0px 5px yellow;
}

.cmgr_missrow {
    display: flex;
    border: 1px solid black;
    width: 450px;
}

.cmgr_scroll {
    overflow-y: scroll;
}

.cmgr_missitm {
    width: 200px;
}

.cmgr_itmrow {
    display: flex;
    background-color: rgb(56, 43, 68);
    border: .5px solid rgb(173, 172, 172);
    margin-top: 3px;
}

#cmgr_checkbox {
    overflow-x: hidden;
}

.cm_tbltbn {
    margin-left: '5px'
}